import { useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-hot-toast';
import API from '../../../../Network/API';
export default function AddIntegration({ show, onHide, row_data }) {

    const [isLoading, setLoading] = useState(false);
    const modalRef1 = useRef()
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        var data = {
            'username': event.target.name.value,
            'password': event.target.password.value,
            'status': event.target.status.value,
        }

        const responsea = API.post('/v1/oauth/settings/gosi/intregration', data);
        toast.promise(responsea, {
            loading: 'Saving ...',
            success: (response) => {
                setLoading(false);
                modalRef1.current.click();
                if (response.status === 500) throw new Error('server error');
                return `${response.data.message}`;
            },
            error: (e) => `${e.response.data.errors.name ?? e.response.data.message}`,
            duration: 6000,
        })
        setLoading(false)
    }


    

    return (
        <>
            <Modal show={show} onHide={onHide}>
                <Modal.Header>
                    <h4 class="modal-title">GOSI Credentials</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" ref={modalRef1} onClick={onHide}></button>

                </Modal.Header>
                <Modal.Body>
                <form onSubmit={handleSubmit} id="add_country_form">

                    <div className="row">
                            <div className="col-sm-12 mb-2">
                                <label htmlFor="name" className="form-label c-label">Status *</label>
                                <select className='form-select' required name='status'>
                                    <option selected={row_data?.enable_gosi==1?true:false} value={1}>Enable</option>
                                    <option selected={row_data?.enable_gosi==0?true:false}>Disable</option>
                                </select>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 mb-2">
                                <label htmlFor="name" className="form-label c-label">Username *</label>
                                <input defaultValue={row_data?.gosi_username} name='name'  type="text" className="form-control rounded-pill" id="name" placeholder required />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 mb-3">
                                <label htmlFor="name" className="form-label c-label">Password *</label>
                                <input defaultValue={row_data?.gosi_password} name='password'  type="password" className="form-control rounded-pill" id="password" placeholder required />
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="submit" class="btn btn-success">Save {isLoading ? (
                                <span
                                    className="spinner-border spinner-border-sm ml-5"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            ) : (
                                <span></span>
                            )}</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import API from "../../../Network/API";
import { toast } from "react-hot-toast";
import BaseUrl from "../../../Network/BaseUrl";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';

export default function AddLeave() {
    const [isLoading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [loading, setLoad] = useState(false);
    const [user, setUser] = useState();
    const [duration, setDuration] = useState([]);
    const [shift, setShift] = useState([]);
    const [categories, setCategory] = useState([]);
    const dispatch = useDispatch();
    const formRef = useRef();
    const navigate = useNavigate()
    const [file, setFile] = useState(null);
    const [start_data, setSatrtDate] = useState(null);
    const [end_data, setEndDate] = useState(null);
    const [balance, setBalance] = useState(0);
    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
      ]);
    
      const handleSelect = (ranges) => {
        setDateRange([ranges.selection]);
      };
    
      const calculateDays = () => {
        if (dateRange[0].startDate && dateRange[0].endDate) {
          const start = dateRange[0].startDate;
          const end = dateRange[0].endDate;
        //   console.log(new Date(start).getFullYear()+'-'+(new Date(start).getMonth()+1)+'-'+new Date(start).getDate());
        //   console.log(start);
          const timeDifference = end.getTime() - start.getTime();
          const daysCount = Math.ceil(timeDifference / (1000 * 3600 * 24));
          return daysCount;
        }
        return 0;
      };

    const setSelectedFile = (e) => {
        setFile(e.target.files[0])
    };


    useEffect(() => {
      
        setLoad(true)

        API.get(`/v1/oauth/leave/create`).then((response) => {
            setCategory(response.data.data.categories)
            setLoad(false)
        }).catch((error) => {
            setLoad(false)
        });



    }, []);

    const check_vacation_balance=(e)=>{
        setLoading(true)
        API.get(`/v1/oauth/leaves/${e.target.value}/check_balance`).then((response) => {
            setBalance(response.data);
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setSaveLoading(true)
        // Access form data using the formRef

        const fd = new FormData();
        fd.append(`leave_category_id`, event.target.leave_category_id.value)
 
        fd.append(`start_date`,dateRange[0].startDate)
        fd.append(`end_date`, dateRange[0].endDate)
        
        if (file) {
            fd.append('attachment', file, file?.name)
        }
        fd.append(`message`, event.target.remark.value)

        fd.append(`start_time`, event.target.start_time.value)
        fd.append(`end_time`, event.target.end_time.value)

        BaseUrl.post('/v1/oauth/leave/save', fd)
            .then((response) => {
                setSaveLoading(false)
                toast.success('Leave request submit successfully');
                 navigate(-1)
            })
            .catch((err) => {
                setSaveLoading(false)
                toast.error(err.response.data.message);
            });


    }


    return (
        <>
            <div className="page-title-box">
                <div className="page-title-breadcrumb">
                    <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                        <li className="breadcrumb-item"><a href="#">My Requests</a></li>
                        <li className="breadcrumb-item active">Add Leave Request</li>
                    </ol>
                </div>
            </div>

            <div className="row">
                <div className="col-md-11 offset-md-1">
                    <div className="card border-0 main-content-card">
                        <div className="card-body">
                            <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3">
                                <h4 className="main-card-title mb-0 flex-grow-1">
                                    <Link to={`/my_leaves`} className="text-dark">
                                        <i className="fa-duotone fa-arrow-left me-1" />
                                        Add Leave Request
                                    </Link>
                                </h4>

                            </div>

                            <div className="c-form-area pb-3">
                                <form onSubmit={handleSubmit} ref={formRef}>

                                    <div className="row">
                                        <div className="col-sm-6 col-md-6 mb-3">
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i class="fa-light fa-list-check text-info"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="category" className="form-label fs-14 text-secondary mb-1 text-uppercase">Leave Types*</label>
                                                    <select onChange={(e)=>check_vacation_balance(e)} required className="form-select rounded-0" id="category" name="leave_category_id">
                                                        <option value={``} selected>Choose...</option>
                                                        {!loading && categories?.map((row, index) => (
                                                            <option value={row.id}>{row.title}</option>
                                                        ))}
                                                    </select>
                                                    {isLoading ? (
                                                <span
                                                    className="spinner-border spinner-border-sm ml-5"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            ) : (
                                                <span></span>
                                            )}
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className={`col-sm-6 col-md-6 mb-3`}>
                                        <p className="m-0 p-0">1.Please select the leave request type from dropdown</p>
                                            <p className="m-0 p-0">2.Click on calender to choose dates.3.click the submit button</p>
                                            
                                        </div>
                                        <div className="col-md-8">
                                            <DateRange
                                            ranges={dateRange}
                                            onChange={handleSelect}
                                            showSelectionPreview={true}
                                            moveRangeOnFirstSelection={false}
                                            months={2}
                                            direction="horizontal"
                                            />
                                        </div>
                                        <div className="col-md-4">

                                            <div>Available Days: {balance} </div>
                                            <div>Selected Days: {calculateDays()+1} </div>

                                            <div className="col-md-12 mt-2">
                                                    <label htmlFor="attachment" className="form-label fs-14 text-secondary mb-1 text-uppercase">Start Time</label>
                                                    <input type="time" className="form-control rounded-0" placeholder="" id="start_time" name="start_time" />
                                            </div>

                                            <div className="col-md-12 mt-2">
                                                    <label htmlFor="attachment" className="form-label fs-14 text-secondary mb-1 text-uppercase">End Time</label>
                                                    <input type="time" className="form-control rounded-0" placeholder="" id="end_time" name="end_time" />
                                            </div>

                                            <div className="col-md-12 mt-2">
                                                    <label htmlFor="attachment" className="form-label fs-14 text-secondary mb-1 text-uppercase">Attachment</label>
                                                    <input onChange={(e) => setSelectedFile(e)} type="file" className="form-control rounded-0" placeholder="" id="attachment" name="attachment" />
                                                </div>

                                        </div>
                                

                                    </div>

                                    
                                    <hr />


                                    <div className="row align-items-end mb-3">
                                        <div className="col-sm-12">
                                            <div className="d-flex align-items-start">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i className="fa-duotone fa-message-captions text-secondary" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <textarea name="remark" className="form-control rounded-0" id="exampleFormControlTextarea1" rows={3} placeholder="Write a message" defaultValue={""} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-end mb-4">
                                        <button type="submit" className="btn btn-success rounded-pill">
                                            Submit {saveLoading ? (
                                                <span
                                                    className="spinner-border spinner-border-sm ml-5"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            ) : (
                                                <span></span>
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>

                        </div>{/* end card-body */}
                    </div>
                </div>

            </div>
        </>
    )
}
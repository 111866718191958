import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import API from "../../../Network/API";
import { toast } from "react-hot-toast";
import BaseUrl from "../../../Network/BaseUrl";
import { format } from 'date-fns';
export default function EditOvertimeRequest() {
    const [isLoading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [loading, setLoad] = useState(false);
    const [leave, setLeave] = useState();

    const dispatch = useDispatch();
    const formRef = useRef();
    const navigate = useNavigate()

    const prams = useParams();
    const [types, setType] = useState([]);

    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [totalHour, setTotalHour] = useState('');

    function formatTime(time) {
        const formattedTime = format(new Date(`2000-01-01T${time}`), 'h:mm a');
        return formattedTime;
    }

    // Function to handle changes in start time input
    const handleStartTimeChange = (e) => {
        setStartTime(e.target.value);
    };

    // Function to handle changes in end time input
    const handleEndTimeChange = (e) => {
        setEndTime(e.target.value);

    };

    // Function to calculate the total hour
    const calculateTotalHour = () => {
        const startTimeInput = document.getElementById('start_time');
        // Get the end time input element
        const endTimeInput = document.getElementById('end_time');
        const startTime = startTimeInput.value;
        const endTime = endTimeInput.value;
        const startHour = parseInt(startTime.split(':')[0]);
        const startMinute = parseInt(startTime.split(':')[1]);
        const endHour = parseInt(endTime.split(':')[0]);
        const endMinute = parseInt(endTime.split(':')[1]);

        let totalHour = 0;
        if (endHour > startHour || (endHour === startHour && endMinute >= startMinute)) {
            totalHour = endHour - startHour;
            totalHour += (endMinute - startMinute) / 60;
        } else {
            totalHour = 24 - startHour + endHour;
            totalHour += (endMinute - startMinute) / 60;
        }

        setTotalHour(totalHour.toFixed(2)); // Round to 2 decimal places
    };


    useEffect(() => {
        all_data('')
    }, []);

    const all_data = (name) => {
        API.get('/v1/oauth/get_overtime_types?name=' + name).then((response) => {
            setType(response.data.data);
        }).catch((error) => {
        });
    }

    useEffect(() => {
        setLoading(true)
        setLoad(true)

        API.get(`/v1/oauth/my_overtime_request/${prams.id}/edit`).then((response) => {
            setLeave(response.data.data.requests)
            setLoad(false)
        }).catch((error) => {
            setLoad(false)
        });



    }, [prams.id]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setSaveLoading(true)
        // Access form data using the formRef

        const fd = new FormData();

        fd.append(`start_date`, event.target.start_date.value)
        fd.append(`total_hour`, totalHour)
        fd.append(`overtime_type_id`, event.target.overtime_type.value)
        fd.append(`start_time`, event.target.start_time.value)
        fd.append(`end_time`, event.target.end_time.value)
        fd.append(`message`, event.target.remark.value)
        fd.append('_method', 'PUT')

        BaseUrl.post(`/v1/oauth/my_overtime_request/${prams.id}/update`, fd)
            .then((response) => {
                setSaveLoading(false)
                navigate(`/overtime_request`)
                toast.success("Overtime request successfully submitted");
              
            })
            .catch((err) => {
                setSaveLoading(false)
                toast.error(err.response.data.message);
            });


    }
    return (
        <>
            <div className="page-title-box">
                <div className="page-title-breadcrumb">
                    <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                        <li className="breadcrumb-item"><a href="#">My Requests</a></li>
                        <li className="breadcrumb-item active">Edit Overtime</li>
                    </ol>
                </div>
            </div>

            <div className="row">
                <div className="col-md-10 offset-1">
                    <div className="card border-0 main-content-card">
                        <div className="card-body">
                            <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3">
                                <h4 className="main-card-title mb-0 flex-grow-1">
                                    <Link to={`/overtime_request`} className="text-dark">
                                        <i className="fa-duotone fa-arrow-left me-1" /> </Link>
                                    Edit Leave

                                </h4>

                            </div>

                            <div className="c-form-area pb-3">
                                <form onSubmit={handleSubmit} ref={formRef}>



                                    <div className="row align-items-end ">

                                        <div className="col-sm-12 col-md-12 mb-3">
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i class="fa-thin fa-list text-primary"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="date" className="form-label fs-14 text-secondary mb-1 text-uppercase">Overtime Type*</label>
                                                    <select className="form-select rounded-0" id="overtime_type" name="overtime_type" required>

                                                        {types?.map((row, index) => (
                                                            <option key={index} value={row.id} >{row.name}</option>
                                                        ))}

                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-12 mb-3">
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i class="fa-thin fa-calendar-days text-primary"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="date" className="form-label fs-14 text-secondary mb-1 text-uppercase">Date*</label>
                                                    <input defaultValue={leave?.start_date} required type="date" className="form-control rounded-0" placeholder="" id="date" name="start_date" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`col-sm-12 col-md-12 mb-3`}>
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i className="fa-thin fa-clock text-primary"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="start_time" className="form-label fs-14 text-secondary mb-1 text-uppercase">Start Time*</label>
                                                    <input type="time" className="form-control rounded-0" placeholder="" id="start_time" name="start_time" value={leave?.start_time} onChange={handleStartTimeChange} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`col-sm-12 col-md-12 mb-3`}>
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i className="fa-thin fa-clock text-primary"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="end_time" className="form-label fs-14 text-secondary mb-1 text-uppercase">End Time*</label>
                                                    <input type="time" className="form-control rounded-0" placeholder="" id="end_time" name="end_time" value={leave?.end_time} onChange={handleEndTimeChange} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`col-sm-12 col-md-12 mb-3`}>
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i className="fa-thin fa-clock text-primary"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="total_hour" className="form-label fs-14 text-secondary mb-1 text-uppercase">Total Hour*</label> <a href="#" className="clickable fs-12 text-success" onClick={calculateTotalHour}><i className="fa-solid fa-sync"></i> Update Hour</a>
                                                    <input type="text" className="form-control rounded-0" placeholder="" id="total_hour" name="total_hour" value={totalHour} readOnly />
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <hr />


                                    <div className="row align-items-end mb-3">
                                        <div className="col-sm-12">
                                            <div className="d-flex align-items-start">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i className="fa-duotone fa-message-captions text-secondary" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <textarea defaultValue={leave?.message} name="remark" className="form-control rounded-0" id="exampleFormControlTextarea1" rows={3} placeholder="Write a message" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-end mb-4">
                                        <button type="submit" className="btn btn-success rounded-pill">
                                            Submit {saveLoading ? (
                                                <span
                                                    className="spinner-border spinner-border-sm ml-5"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            ) : (
                                                <span></span>
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>

                        </div>{/* end card-body */}
                    </div>
                </div>

            </div>
        </>
    )
}
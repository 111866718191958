
import { createSlice } from '@reduxjs/toolkit';

export const nationalSlice = createSlice({
    name:'nationality',
    initialState:{
        loading: false,
        data: {

        },
        main_data:[],
        error: false,
    },
    reducers:{
        NATIONALITY_FETCH_START:(state)=>{
            state.loading=true
        },
        NATIONALITY_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
            state.main_data=action.payload.data
        },
        ADD_NATIONALITY_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.main_data = [action.payload,...state.main_data]
        },
        NATIONALITY_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const { NATIONALITY_FETCH_START,NATIONALITY_FETCH_SUCCESS,NATIONALITY_FETCH_ERROR,ADD_NATIONALITY_FETCH_SUCCESS }=nationalSlice.actions;
export default nationalSlice.reducer;


import { createSlice } from '@reduxjs/toolkit';

export const deductionSlice = createSlice({
    name:'deduction',
    initialState:{
        loading: false,
        data: {

        },
        main_data:[],
        error: false,
    },
    reducers:{
        DEDUCTION_FETCH_START:(state)=>{
            state.loading=true
        },
        DEDUCTION_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
            state.main_data=action.payload.data
        },
        ADD_DEDUCTION_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.main_data = [action.payload,...state.main_data]
        },
        EDIT_DEDUCTION_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            const newArray = state.main_data.filter(todo => todo.id !==action.payload.id)
             state.main_data = [...newArray,action.payload]
        },
        DELETE_DEDUCTION:(state,action)=>{
            const filterPosts = state.main_data.filter(
                (post) => post.id !== action.payload
              );
            state.loading = false
            state.main_data=filterPosts
        },
        DEDUCTION_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const {DELETE_DEDUCTION,EDIT_DEDUCTION_FETCH_SUCCESS, DEDUCTION_FETCH_START,DEDUCTION_FETCH_SUCCESS,DEDUCTION_FETCH_ERROR,ADD_DEDUCTION_FETCH_SUCCESS }=deductionSlice.actions;
export default deductionSlice.reducer;
import { useDispatch, useSelector } from "react-redux";
import Approval from "./Pages/User/Approval";
import UserHome from "./Pages/User/UserHome";
import LeftNav from "./_partials/left_nav";
import Nav from "./_partials/nav";
import { USER_LOGGED_IN } from "./Reducer/authSlice";
import { BrowserRouter, Routes, Route, useLocation, Outlet, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Login from "./Auth/Login";
import Setting from "./Pages/Admin/Setting";
import Nationality from "./Pages/Admin/settings/nationality/Nationality";
import Department from "./Pages/Admin/settings/department/Department";
import Designation from "./Pages/Admin/settings/designation/Designation";
import AllowanceIndex from "./Pages/Admin/settings/allowance/AllowanceIndex";
import AssetType from "./Pages/Admin/settings/asset_type/AssetType";
import Branch from "./Pages/Admin/settings/branch/branch";
import Degree from "./Pages/Admin/settings/degree/degree";
import Gender from "./Pages/Admin/settings/gender/Gender";
import Religion from "./Pages/Admin/settings/religion/Religion";
import IdType from "./Pages/Admin/settings/id_type/IdType";
import Probation from "./Pages/Admin/settings/probation/Probation";
import Relationship from "./Pages/Admin/settings/relationship/Relationship";
import Status from "./Pages/Admin/settings/status/Status";
import Shift from "./Pages/Admin/settings/shift/Shift";
import TaxSetup from "./Pages/Admin/settings/tax_setup/TaxSetup";
import FundRule from "./Pages/Admin/settings/providant_fund_rule/FundRule";
import DocumentType from "./Pages/Admin/settings/document_type/DocumentType";
import DisciplinaryRule from "./Pages/Admin/settings/disciplinary_rules/DisciplinaryRule";
import { Toaster } from "react-hot-toast";
import AdminHome from "./Pages/Admin/AdminHome";
import CompanyProfile from "./Pages/Admin/settings/company/CompanyProfile";
import AppSetting from "./Pages/Admin/settings/AppSetting";
import WebSetting from "./Pages/Admin/settings/WebSetting";
import FiscalYear from "./Pages/Admin/settings/fiscal_year/FiscalYear";
import Employee from "./Pages/Admin/employee/Employee";
import AddEmployee from "./Pages/Admin/employee/AddEmployee";
import Bank from "./Pages/Admin/settings/bank_info/Bank";
import EventApproval from "./Pages/Admin/settings/event_approvals/EventApproval";
import EventApprovalView from "./Pages/Admin/settings/event_approvals/EventApprovalView";
import EditEmployee from "./Pages/Admin/employee/EditEmployee";
import EmployeeProfile from "./Pages/Admin/employee/EmployeeProfile";
import Report from "./Pages/Admin/reports/Report";
import LeaveCategory from "./Pages/Admin/settings/leave_category/LeaveCategory";
import Document from "./Pages/Admin/documents/Document";
import PerformanceTypes from "./Pages/Admin/settings/performance_types/PerformanceTypes";
import PerformanceCriteria from "./Pages/Admin/settings/performance_criteria/PerformanceCriteria";
import AddBranch from "./Pages/Admin/settings/branch/AddBranch";
import EditBranch from "./Pages/Admin/settings/branch/EditBranch";
import MyTimesheet from "./Pages/User/attendance/MyTimesheet";
import MyApproval from "./Pages/User/approval/MyApproval";
import LateSetting from "./Pages/Admin/settings/late_setting/LateSetting";
import AddEmployeePeformance from "./Pages/Admin/performance/AddEmployeePeformance";
import AddPromotion from "./Pages/Admin/employee_promotion/AddPromotion";
import MyLeave from "./Pages/User/leave/MyLeave";
import AddLeave from "./Pages/User/leave/AddLeave";
import EditLeave from "./Pages/User/leave/EditLeave";
import OvertimeRequest from "./Pages/User/overtime_request/OvertimeRequest";
import AddOvertimeRequest from "./Pages/User/overtime_request/AddOvertimeRequest";
import EditOvertimeRequest from "./Pages/User/overtime_request/EditOvertimeRequest";
import LoanRequest from "./Pages/User/loan_request/LoanRequest";
import AddLoanRequest from "./Pages/User/loan_request/AddLoanRequest";
import EditLoanRequest from "./Pages/User/loan_request/EditLoanRequest";
import ApprovalView from "./Pages/User/approval/ApprovalView";
import LeaveView from "./Pages/User/leave/LeaveView";
import AllRequest from "./Pages/Admin/approval/AllRequest";
import RequestView from "./Pages/Admin/approval/RequestView";
import AllAttendance from "./Pages/Admin/attendance/AllAttendance";
import DeductionIndex from "./Pages/Admin/settings/deduction/DeductionIndex";
import BonusType from "./Pages/Admin/settings/bonus_type/BonusType";
import HolidayType from "./Pages/Admin/settings/holiday_types/HolidayType";
import WeeklyHoliday from "./Pages/Admin/weekly_holiday";
import PublicHoliday from "./Pages/Admin/public_holiday/PublicHoliday";
import AttendanceReport from "./Pages/Admin/reports/attendance_report/AttendanceReport";
import LeaveReport from "./Pages/Admin/reports/attendance_report/LeaveReport";

import ManageBonus from "./Pages/Admin/bonus/ManageBonus";
import AddManageBonus from "./Pages/Admin/bonus/AddManageBonus";
import ViewBonus from "./Pages/Admin/bonus/ViewBonus";
import ManageDeduction from "./Pages/Admin/deduction/ManageDeduction";
import AddManageDeduction from "./Pages/Admin/deduction/AddManageDeduction";
import AddSalarySheet from "./Pages/Admin/salary_sheet/AddSalarySheet";
import AddSalarySheetBulk from "./Pages/Admin/salary_sheet/AddSalarySheetBulk";
import SalarySheet from "./Pages/Admin/salary_sheet/SalarySheet";
import PayrollReport from "./Pages/Admin/reports/payroll_report/PayrollReport";
import EmployeeJob from "./Pages/Admin/employee/EmployeeJob";
import EmployeeLeave from "./Pages/Admin/employee/EmployeeLeave";
import EmployeeAttendance from "./Pages/Admin/employee/EmployeeAttendance";
import Role from "./Pages/Admin/settings/roles/Role";
import ChangePassowrd from "./Pages/Profile/ChangePassowrd/ChangePassowrd";
import NotFound from "./Pages/NotFound";
import IntegrationIndex from "./Pages/Admin/settings/integration/IntegrationIndex";
import GosiIndex from "./Pages/Admin/GOSI/GosiIndex";
import MyPayslip from "./Pages/User/Payslip/MyPayslip";
import FireBaseNotification from "./Notification/FireBaseNotification";
import VacationCategory from "./Pages/Admin/settings/vacation_category/VacationCategory";
import MyVacation from "./Pages/User/vacation_request/MyVacation";
import EditVacation from "./Pages/User/vacation_request/EditVacation";
import MyVacationView from "./Pages/User/vacation_request/MyVacationView";
import AddVacation from "./Pages/User/vacation_request/AddVacation";
import VacationApproval from "./Pages/User/approval/VacationApproval";
import BusinessTripeCategory from "./Pages/Admin/settings/business_tripe_category/BusinessTripeCategory";
import BusinessTripeMethod from "./Pages/Admin/settings/business_tripe_method/BusinessTripeMethod";
import BusinessTripeRoute from "./Pages/Admin/settings/business_tripe_route/BusinessTripeRoute";
import LoanCategory from "./Pages/Admin/settings/loan_category/LoanCategory";
import RecruitmentCategory from "./Pages/Admin/settings/recruitment_category/RecruitmentCategory";
import OvertimeCategory from "./Pages/Admin/settings/overtime_type/OvertimeCategory";
import ExpenseCategory from "./Pages/Admin/settings/expense_type/ExpenseCategory";
import ViewOvertimeRequest from "./Pages/User/overtime_request/ViewOvertimeRequest";
import MyBusinessTripe from "./Pages/User/business_tripe_request/MyBusinessTripe";
import EditBusinessTripe from "./Pages/User/business_tripe_request/EditBusinessTripe";
import BusinessTripeView from "./Pages/User/business_tripe_request/BusinessTripeView";
import AddBusinessTripe from "./Pages/User/business_tripe_request/AddBusinessTripe";
import LoanView from "./Pages/User/loan_request/LoanView";

function App() {
  const { user, accessToken } = useSelector((state) => state.authReducer);
  const location = useLocation();
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.userMenuReducer)
  const [menus, setMenus] = useState([]);
  const selector_profile = useSelector((state) => state.myProfileSlice)

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("alif_auth"));
    if (userInfo) {
      dispatch(USER_LOGGED_IN(userInfo));
    }



  }, [dispatch]);


  useEffect(() => {
    if (selector?.main_data) {
      const newMenus = selector.main_data.map((row) => row.path);
      setMenus(newMenus);
    }
  }, [selector?.main_data]);


  // !user ||
  if (!accessToken) {
    return (
      <>
        <Login />
      </>
    );
  }




  const checkMenu = (url) => {
    return selector_profile?.data?.permissions?.includes(url) ? 1 : 0;
  };


  return (
    <>
      <FireBaseNotification/>
      <div id="layout-wrapper">
        <Nav />
        <LeftNav />
        <div className="main-content">
          <div className="page-content">
            <div className="page-content-padding">


              <Routes>
                <Route path="/" element={user?.role_id == 1 ? <AdminHome /> : <UserHome />} />
                <Route path="/home" element={<UserHome />} />
                <Route path="/timesheet" element={<MyTimesheet />} />
                <Route path="/my_approvals" element={<MyApproval />}>
                  <Route path="/my_approvals/:id/view" element={<ApprovalView />} />
                  <Route path="/my_approvals/:id/view/vacation" element={<VacationApproval />} />
                </Route>

                <Route path="/change_password" element={<ChangePassowrd />} />
                <Route path="/dashboard" element={user?.role_id == 1 ? <AdminHome /> : <UserHome />} />

                <Route exact path='/approvals' element={<PrivateRoute url={`Approvals`}/>}>
                    <Route exact path='/approvals' element={<EventApproval/>}/>
                </Route>


                {/* <Route path="/approvals" element={checkMenu('Approvals') === 1 ?<EventApproval />:<NotFound/>} />
                 */}
                <Route path="/approvals/:id" element={<EventApprovalView />} />
                <Route path="/employee" element={<Employee />} />
                <Route path="/employee/:id/edit" element={<EditEmployee />} />
                <Route path="/employee/:id/view" element={<EmployeeProfile />} />
                <Route path="/employee/:id/job" element={<EmployeeJob />} />
                <Route path="/employee/:id/leave" element={<EmployeeLeave />} />
                <Route path="/employee/:id/timesheet" element={<EmployeeAttendance />} />
                <Route path="/employee/create" element={<AddEmployee />} />
                <Route path="/employee/:id/add_performance" element={<AddEmployeePeformance />} />
                <Route path="/employee/:id/promotion" element={<AddPromotion />} />
                <Route path="/my_leaves" element={<MyLeave />} />
                <Route path="/my_leaves/:id/edit" element={<EditLeave />} />
                <Route path="/my_leaves/:id/view" element={<LeaveView />} />
                <Route path="/add_leave" element={<AddLeave />} />
                <Route path="/overtime_request" element={<OvertimeRequest />} />
                <Route path="/overtime_request/create" element={<AddOvertimeRequest />} />
                <Route path="/overtime_request/:id/edit" element={<EditOvertimeRequest />} />
                <Route path="/overtime_request/:id/view" element={<ViewOvertimeRequest />} />

                <Route path="/my_vacations" element={<MyVacation />} />
                <Route path="/my_vacations/:id/edit" element={<EditVacation />} />
                <Route path="/my_vacations/:id/view" element={<MyVacationView />} />
                <Route path="/my_vacations/create" element={<AddVacation />} />


                <Route path="/my_business_tripe" element={<MyBusinessTripe />} />
                <Route path="/my_business_tripe/:id/edit" element={<EditBusinessTripe />} />
                <Route path="/my_business_tripe/:id/view" element={<BusinessTripeView />} />
                <Route path="/my_business_tripe/create" element={<AddBusinessTripe />} />

                <Route path="/loan_request" element={<LoanRequest />} />
                <Route path="/loan_request/create" element={<AddLoanRequest />} />
                <Route path="/loan_request/:id/edit" element={<EditLoanRequest />} />
                <Route path="/loan_request/:id/view" element={<LoanView />} />

                <Route path="/documents" element={<Document />} />
                <Route path="/attendances" element={<AllAttendance />} />
                <Route path="/public_holidays" element={<PublicHoliday />} />

                <Route path="/attendance_reports" element={<AttendanceReport />} />
                <Route path="/leave_reports" element={<LeaveReport />} />
                <Route path="/payroll_reports" element={<PayrollReport />} />
                <Route path="/manage_bonus" element={<ManageBonus />} />
                <Route path="/manage_bonus/create" element={<AddManageBonus />} />
                <Route path="/manage_bonus/:id/view" element={<ViewBonus />} />

                <Route path="/manage_deduction" element={<ManageDeduction />} />
                <Route path="/manage_deduction/create" element={<AddManageDeduction />} />

                <Route path="/salary_sheet" element={<SalarySheet />} />
                <Route path="/salary_sheet/bulk" element={<AddSalarySheetBulk />} />
                <Route path="/salary_sheet/create" element={<AddSalarySheet />} />


                <Route path="/payroll_report" element={<PayrollReport />} />

                <Route path="/all_requests" element={<AllRequest />}>
                  <Route path="/all_requests/:id/view" element={<RequestView />} />
                </Route>

                <Route path="integration/gosi" element={<GosiIndex />} />
                <Route path="my_payslip" element={<MyPayslip />} />

                <Route path="/reports" element={<Report />}> </Route>

                <Route path="/settings" element={<Setting />}>
                  <Route path="/settings/weekly_holidays" element={<WeeklyHoliday />} />
                  {/* <Route path="/settings/web" element={<CompanyProfile />} /> */}
                  <Route path="/settings/web" element={<WebSetting />} />
                  <Route path="/settings/nationalities" element={<Nationality />} />
                  <Route path="/settings/religions" element={<Religion />} />
                  <Route path="/settings/genders" element={<Gender />} />
                  <Route path="/settings/id_types" element={<IdType />} />
                  <Route path="/settings/departments" element={<Department />} />
                  <Route path="/settings/designations" element={<Designation />} />
                  <Route path="/settings/allowances" element={<AllowanceIndex />} />
                  <Route path="/settings/deductions" element={<DeductionIndex />} />
                  <Route path="/settings/bonus_types" element={<BonusType />} />
                  <Route path="/settings/holiday_types" element={<HolidayType />} />

                  <Route path="/settings/asset_types" element={<AssetType />} />
                  <Route path="/settings/branches" element={<Branch />} />
                  <Route path="/settings/branche/create" element={<AddBranch />} />
                  <Route path="/settings/branche/:id/edit" element={<EditBranch />} />
                  <Route path="/settings/degrees" element={<Degree />} />
                  <Route path="/settings/probation" element={<Probation />} />
                  <Route path="/settings/relationship" element={<Relationship />} />
                  <Route path="/settings/status" element={<Status />} />
                  <Route path="/settings/shift" element={<Shift />} />
                  <Route path="/settings/tax_setup" element={<TaxSetup />} />
                  <Route path="/settings/fund_rules" element={<FundRule />} />
                  <Route path="/settings/document_types" element={<DocumentType />} />
                  <Route path="/settings/disciplinary_rules" element={<DisciplinaryRule />} />
                  <Route path="/settings/company" element={<CompanyProfile />} />
                  <Route path="/settings/app_setting" element={<AppSetting />} />
                  <Route path="/settings/fiscal_year" element={<FiscalYear />} />
                  <Route path="/settings/bank" element={<Bank />} />
                  <Route path="/settings/leave_categories" element={<LeaveCategory />} />
                  <Route path="/settings/performance_types" element={<PerformanceTypes />} />
                  <Route path="/settings/performance_criteria" element={<PerformanceCriteria />} />
                  <Route path="/settings/late_configuration" element={<LateSetting />} />
                  <Route path="/settings/roles" element={<Role />} />
                  <Route path="/settings/integrations" element={<IntegrationIndex />} />
                  <Route path="/settings/vacation_categories" element={<VacationCategory />} />
                  <Route path="/settings/business_tripe_categories" element={<BusinessTripeCategory />} />
                  <Route path="/settings/business_tripe_methods" element={<BusinessTripeMethod />} />
                  <Route path="/settings/business_tripe_routes" element={<BusinessTripeRoute />} />
                  <Route path="/settings/loan_types" element={<LoanCategory />} />
                  <Route path="/settings/recruitment_categories" element={<RecruitmentCategory />} />
                  <Route path="/settings/overtime_categories" element={<OvertimeCategory />} />
                  <Route path="/settings/expense_categories" element={<ExpenseCategory />} />
                </Route>
              </Routes>
              <Toaster
                position="top-center"
                reverseOrder={false}
              />
            </div>
          </div>
          <footer className="footer ">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  2024 © TigerHR.
                </div>
                <div className="col-sm-6">
                  <div className="text-sm-end d-none d-sm-block">
                    Design &amp; Develop by ITAdventure
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}

const PrivateRoute = ({url}) => {
  
  const selector_permission = useSelector((state) => state.permissionReducer)
  const auth = selector_permission?.main_data?.includes(url) ? true : false; // determine if authorized, from context or however you're doing it

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return auth ? <Outlet /> : <Navigate to="/" />;
}
export default App;

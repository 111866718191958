import { createSlice } from '@reduxjs/toolkit';
export const designationSlice = createSlice({
    name: 'designation',
    initialState: {
        loading: false,
        data: {

        },
        main_data: [],
        error: false,
    },
    reducers: {
        DESIGNATION_FETCH_START: (state) => {
            state.loading = true
        },
        DESIGNATION_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.main_data = action.payload.data
        },
        ADD_DESIGNATION_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.main_data = [action.payload, ...state.main_data]
        },
        EDIT_DESIGNATION_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            const newArray = state.main_data.filter(todo => todo.id !==action.payload.id)
             state.main_data = [...newArray,action.payload]
        },
        DELETE_DESIGNATION:(state,action)=>{
            const filterPosts = state.main_data.filter(
                (post) => post.id !== action.payload
              );
            state.loading = false
            state.main_data=filterPosts
        },
        DESIGNATION_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const {EDIT_DESIGNATION_FETCH_SUCCESS,DELETE_DESIGNATION, DESIGNATION_FETCH_START, DESIGNATION_FETCH_SUCCESS, DESIGNATION_FETCH_ERROR, ADD_DESIGNATION_FETCH_SUCCESS } = designationSlice.actions;
export default designationSlice.reducer;
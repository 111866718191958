import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import API from "../../../../Network/API";
import { toast } from "react-hot-toast";
import BaseApi from "../../../../Network/BaseApi";
export default function PayrollReport() {
    const [branch_id, setBranch] = useState();

    const [isLoading, setLoading] = useState(false);
    const [eisLoading, setELoading] = useState(false);
    const year = (new Date()).getFullYear();
    const yearLast = 2022;
    const years = Array.from(new Array(20), (val, index) => index + yearLast);
    const navigator = useNavigate()
    const [types, setType] = useState([]);
    const [sheet_data, setSheetData] = useState();
    const [monthId, setMonth] = useState();
    const [yearId, setYear] = useState(year);

    const months = [
        {
            'id': '01',
            'name': 'January',
        },
        {
            'id': '02',
            'name': 'February',
        },
        {
            'id': '03',
            'name': 'March',
        },
        {
            'id': '04',
            'name': 'April',
        },
        {
            'id': '04',
            'name': 'May',
        },
        {
            'id': '06',
            'name': 'June',
        },
        {
            'id': '07',
            'name': 'July',
        },
        {
            'id': '08',
            'name': 'August',
        },
        {
            'id': '09',
            'name': 'September',
        },
        {
            'id': '10',
            'name': 'October',
        },
        {
            'id': '11',
            'name': 'November',
        },
        {
            'id': '12',
            'name': 'December',
        }

    ];


    useEffect(() => {
        API.get(`/v1/oauth/manage_salary/bulk`).then((response) => {
            setType(response.data.data.branch)
        }).catch((error) => {

        });
    }, []);


    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString('default', { month: 'long' });

    const searchSheet = () => {
        setLoading(true)
        if (monthId == undefined || yearId == undefined || branch_id == undefined) {
            toast.error('Please select option')
            setLoading(false)
            return;
        }
        API.get(`/v1/oauth/report/payroll/salary_sheet?branch_id=${branch_id}&month=${monthId}&year=${yearId}`).then((response) => {
            setSheetData(response.data.data)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
        });
    }

    const report_download = () => {
        setELoading(true)
        if (monthId == undefined || yearId == undefined || branch_id == undefined) {
            toast.error('Please select option')
            setLoading(false)
            return;
        }


        // });
        API.get(`/v1/oauth/report/payroll/export_salary_sheet?branch_id=${branch_id}&month=${monthId}&year=${yearId}`)
            .then((response) => {
                setELoading(false)
                
                window.open(BaseApi() + "/downloadFile/" + response.data.data.file_name, '_blank')
            }).catch((error) => {
                setELoading(false)
            });

        
    }

    return (
        <>
            <div className="container-fluid">
                <div className="page-title-box">
                    <div className="page-title-breadcrumb">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item active">Payroll Report</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">

                        <div className="card border-0 main-content-card">
                            <div className="card-body">
                                <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3">
                                    <h4 className="main-card-title mb-0 flex-grow-1">
                                        <Link to={`/`} className="text-dark">
                                            <i className="fa-duotone fa-arrow-left me-2" />
                                            Payroll Report
                                        </Link>
                                    </h4>
                                </div>

                                <div className="c-form-area">
                                    <div className="row align-items-end">
                                        <div className="col-sm-4 col-md-4">
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i class="fa-duotone fa-code-branch text-warning"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="branch" className="form-label fs-14 text-secondary mb-1 text-uppercase">Branch*</label>
                                                    <select required name="type_id" className="form-select" onChange={(e) => setBranch(e.target.value)}>
                                                        <option value={``}>Choose.......</option>
                                                        {types.map((type, index) => (
                                                            <option key={index} value={type.id}>{type.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-sm-4 col-md-4">
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i class="fa-regular fa-calendar-week text-primary"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="branch" className="form-label fs-14 text-secondary mb-1 text-uppercase">Year*</label>
                                                    <select name="year" required className="form-select" onChange={(e) => setYear(e.target.value)}>
                                                        {years?.map((value, index) => (
                                                            <option key={`year${index}`} value={value} selected={year == value ? true : false}>{value}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-4 col-md-4">
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i className="fa-duotone fa-calendar text-success" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="branch" className="form-label fs-14 text-secondary mb-1 text-uppercase">Month*</label>
                                                    <select required name="month_id" className="form-select" onChange={(e) => setMonth(e.target.value)}>
                                                        <option value={``}>Choose.......</option>
                                                        {months.map((month, index) => (
                                                            <option key={index} value={month.id} >{month.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer mb-2">
                                <button onClick={() => report_download()} type="button" class="btn btn-info me-2">Export {eisLoading ? (
                                    <span
                                        className="spinner-border spinner-border-sm ml-5"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                ) : (
                                    <span></span>
                                )}</button>

                                <button onClick={() => searchSheet()} type="button" class="btn btn-success me-2">Search {isLoading ? (
                                    <span
                                        className="spinner-border spinner-border-sm ml-5"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                ) : (
                                    <span></span>
                                )}</button>

                            </div>

                        </div>
                    </div>
                </div>

                {!isLoading && sheet_data?.sheet.length > 0 ?
                    <div className="row mt-2">

                        <div className="card border-0">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-bordered text-center table-sm  caption-top">
                                        <caption>Salary Sheet - Month of {sheet_data?.month}-{sheet_data?.year}</caption>
                                        <thead>
                                            <tr>
                                                <th scope="col" rowSpan={2} style={{ verticalAlign: 'middle' }}>Sl No</th>
                                                <th scope="col" rowSpan={2} style={{ verticalAlign: 'middle' }}>Name of Employee</th>
                                                <th scope="col" rowSpan={2} style={{ verticalAlign: 'middle' }}>Designation</th>
                                                <th scope="col" rowSpan={2} style={{ verticalAlign: 'middle' }}>Cheque/Cash Pay</th>
                                                <th scope="col" colSpan={7}>Working Day</th>
                                                <th scope="col" colSpan={7}>Salary &amp; Allowance</th>
                                                <th scope="col" colSpan={6}>Deduction</th>
                                                <th scope="col" rowSpan={2} style={{ verticalAlign: 'middle' }}>Net Payable</th>
                                             
                                            </tr>
                                            <tr>
                                                <th rowSpan={1} className="roted"><span className="roted">TTL Days</span></th>
                                                <th rowSpan={1}><span className="roted">Actual Working Days</span></th>
                                                <th rowSpan={1}><span className="roted">Present Days</span></th>
                                                <th rowSpan={1}><span className="roted">Weekends &amp; Holiday</span></th>
                                                <th rowSpan={1}><span className="roted">Leave</span></th>
                                                <th rowSpan={1}><span className="roted">Absent Days</span></th>
                                                <th rowSpan={1}><span className="roted">Overtime Hours</span></th>
                                                <th rowSpan={1} style={{ verticalAlign: 'middle' }}>Basic Salary</th>
                                                <th rowSpan={1} style={{ verticalAlign: 'middle' }}>Housing</th>
                                                <th rowSpan={1} style={{ verticalAlign: 'middle' }}>Transportation</th>
                                                <th rowSpan={1} style={{ verticalAlign: 'middle' }}>Medical</th>
                                                <th rowSpan={1} style={{ verticalAlign: 'middle' }}>Overtime Amount</th>
                                                <th rowSpan={1} style={{ verticalAlign: 'middle' }}>Others Allowance</th>
                                                <th rowSpan={1} style={{ verticalAlign: 'middle' }}>Gross Salary</th>
                                                <th rowSpan={1} style={{ verticalAlign: 'middle' }}>Loan/ Advance/ Other</th>
                                                <th rowSpan={1} style={{ verticalAlign: 'middle' }}>Lateness Deduct</th>
                                                <th rowSpan={1} style={{ verticalAlign: 'middle' }}>Absent Deduct</th>
                                                <th rowSpan={1} style={{ verticalAlign: 'middle' }}>GOSI</th>
                                                <th rowSpan={1} style={{ verticalAlign: 'middle' }}>Provident Fund</th>
                                                <th rowSpan={1} style={{ verticalAlign: 'middle' }}>Total Deduct</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!isLoading && sheet_data?.sheet?.length > 0 && sheet_data?.sheet?.map((row, index) => (


                                                <tr>
                                                    <td>{index}</td>
                                                    <td>{row.name}</td>
                                                    <td>{row.designation_name}</td>
                                                    <td>{row.payment_type} </td>
                                                    <td>{row.total_days}</td>
                                                    <td>{row.accual_days}</td>
                                                    <td>{row.present_days}</td>
                                                    <td>{Number(row.holiday_days) + Number(row.week_days)}</td>
                                                    <td>{row.leave_days}</td>
                                                    <td>{row.absent_days}</td>
                                                    <td>{row.ot_days}</td>

                                                    <td>{row.basic_salary}</td>
                                                    <td>{row.house}</td>
                                                    <td>{row.transportation}</td>
                                                    <td>{row.medical_allowance}</td>
                                                    <td>{row.total_ot_amount}</td>
                                                    <td>{row.other_allowance}</td>
                                                    <td>{row.gross_salary}</td>
                                                    <td>{row.total_deduct_loan}</td>
                                                    <td>{row.deduct_lateness}</td>
                                                    <td>{row.total_deduct_absent}</td>
                                                    <td>{row.total_deduct_tax}</td>
                                                    <td>{row.total_deduct_fund}</td>
                                                    <td>{row.total_deduction}</td>
                                                    <td>{row.net_payable}</td>
                                                   
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>

                    </div> : ''}
            </div>

        </>
    )
}
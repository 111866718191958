

import { createSlice } from '@reduxjs/toolkit';

export const religionSlice = createSlice({
    name:'religion',
    initialState:{
        loading: false,
        data: {

        },
        main_data:[],
        error: false,
    },
    reducers:{
        RELIGION_FETCH_START:(state)=>{
            state.loading=true
        },
        RELIGION_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
            state.main_data=action.payload.data
        },
        ADD_RELIGION_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.main_data = [action.payload,...state.main_data]
        },
        RELIGION_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const { RELIGION_FETCH_START,RELIGION_FETCH_SUCCESS,RELIGION_FETCH_ERROR,ADD_RELIGION_FETCH_SUCCESS }=religionSlice.actions;
export default religionSlice.reducer;
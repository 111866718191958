import * as XLSX from 'xlsx';

const exportTableToExcel = (tableId, fileName) => {
  const table = document.getElementById(tableId);
  const workbook = XLSX.utils.table_to_book(table);
  const worksheetName = 'Sheet1'; // Name for the worksheet in the Excel file

  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
  const url = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${fileName}.xlsx`;
  link.click();
};

export default exportTableToExcel;

import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import API from "../../../Network/API";
import { toast } from "react-hot-toast";
import BaseUrl from "../../../Network/BaseUrl";
export default function EditLoanRequest() {
    const [isLoading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [loading, setLoad] = useState(false);
    const [leave, setLeave] = useState();
    const [duration, setDuration] = useState([]);
    const [shift, setShift] = useState([]);
    const [categories, setCategory] = useState([]);
    const dispatch = useDispatch();
    const formRef = useRef();
    const navigate = useNavigate()
    const [file, setFile] = useState(null);
    const prams = useParams();
    const setSelectedFile = (e) => {
        setFile(e.target.files[0])
    };


    useEffect(() => {
        setLoading(true)
        setLoad(true)

        API.get(`/v1/oauth/my_overtime_request/${prams.id}/edit`).then((response) => {
            setLeave(response.data.data.requests)
            setLoad(false)
        }).catch((error) => {
            setLoad(false)
        });



    }, [prams.id]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setSaveLoading(true)
        // Access form data using the formRef

        const fd = new FormData();

        fd.append(`start_date`, event.target.start_date.value)
        fd.append(`end_date`, event.target.end_date.value)
        fd.append(`message`, event.target.remark.value)
        fd.append('_method', 'PUT')

        BaseUrl.post(`/v1/oauth/my_overtime_request/${prams.id}/update`, fd)
            .then((response) => {
                setSaveLoading(false)
                toast.success(response.data.data.message);
                navigate(-1)
            })
            .catch((err) => {
                setSaveLoading(false)
                toast.error(err.response.data.message);
            });


    }
    return (
        <>
            <div className="page-title-box">
                <div className="page-title-breadcrumb">
                    <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                        <li className="breadcrumb-item"><a href="#">My Requests</a></li>
                        <li className="breadcrumb-item active">Edit Leave</li>
                    </ol>
                </div>
            </div>

            <div className="row">
                <div className="col-md-10 offset-1">
                    <div className="card border-0 main-content-card">
                        <div className="card-body">
                            <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3">
                                <h4 className="main-card-title mb-0 flex-grow-1">
                                    <Link to={`/overtime_request`} className="text-dark">
                                        <i className="fa-duotone fa-arrow-left me-1" /> </Link>
                                    Edit Leave

                                </h4>

                            </div>

                            <div className="c-form-area pb-3">
                                <form onSubmit={handleSubmit} ref={formRef}>



                                    <div className="row align-items-end ">

                                        <div className="col-sm-12 col-md-12 mb-3">
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i class="fa-thin fa-calendar-days text-primary"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="date" className="form-label fs-14 text-secondary mb-1 text-uppercase">Date*</label>
                                                    <input defaultValue={leave?.start_date} required type="date" className="form-control rounded-0" placeholder="" id="date" name="start_date" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`col-sm-12 col-md-12 mb-3`}>
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i class="fa-thin fa-calendar-days text-primary"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="date" className="form-label fs-14 text-secondary mb-1 text-uppercase">End Date*</label>
                                                    <input defaultValue={leave?.end_date} type="date" className="form-control rounded-0" placeholder="" id="end_date" name="end_date" />
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <hr />


                                    <div className="row align-items-end mb-3">
                                        <div className="col-sm-12">
                                            <div className="d-flex align-items-start">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i className="fa-duotone fa-message-captions text-secondary" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <textarea defaultValue={leave?.message} name="remark" className="form-control rounded-0" id="exampleFormControlTextarea1" rows={3} placeholder="Write a message" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-end mb-4">
                                        <button type="submit" className="btn btn-success rounded-pill">
                                            Submit {saveLoading ? (
                                                <span
                                                    className="spinner-border spinner-border-sm ml-5"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            ) : (
                                                <span></span>
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>

                        </div>{/* end card-body */}
                    </div>
                </div>

            </div>
        </>
    )
}
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { EMPLOYEE_FETCH_ERROR, EMPLOYEE_FETCH_SUCCESS } from "../../../Reducer/EmployeeSlice";
import API from "../../../Network/API";
import Paginate from "../../../services/Paginate";

export default function Document() {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [nxtPage, setNextPage] = useState(null);
  const [search_value, setSearchValue] = useState(null);
  const [data_value, setDataValue] = useState(null);
  const [show, setShow] = useState(false);

  
  useEffect(() => {
    all_data('')
  }, []);

  const all_data = (name) => {
    API.get('/v1/oauth/users?name='+name).then((response) => {
      dispatch(EMPLOYEE_FETCH_SUCCESS(response.data.data))
      setLoading(false)
    }).catch((error) => {
      setLoading(true)
      EMPLOYEE_FETCH_ERROR();
    });
  }
  const paginate = (pageNumber) => {
    API.get('/v1/oauth/users?page='+pageNumber).then((response) => {
      dispatch(EMPLOYEE_FETCH_SUCCESS(response.data.data))
      setLoading(false)
    }).catch((error) => {
      setLoading(true)
      EMPLOYEE_FETCH_ERROR();
    });
  };

  const previousPage = (nxtPage) => {
    setNextPage(nxtPage)
  }

  const nextPage = (nxtPage) => {
    setNextPage(nxtPage)
  }

  const selector = useSelector((state) => state.employeeReducer)
 
 
  return (
    <>
    <div className="container-fluid">
      <div className="page-title-box">
        <div className="page-title-breadcrumb">
          <ol className="breadcrumb m-0">
            <li className="breadcrumb-item"><a href="#">Home</a></li>
  
            <li className="breadcrumb-item active">Office Documents</li>
          </ol>
        </div>
      </div>
      <div className="row align-items-center py-2">
        <div className="col-sm">
          <div className="d-flex align-items-center">
            <h5 className="card-title mb-0 me-3">Files & Documents</h5>
            
          </div>
        </div>
        <div className="col-sm-auto">
          <div className="d-flex gap-1 flex-wrap">
            <button type="button" className="btn bg-white shadow-sm">
              <i className="fa-light fa-download" />
            </button>
            <button type="button" className="btn bg-white shadow-sm">
              <i className="fa-light fa-print" />
            </button>
            <a className="btn add-top-btn ">
              <i className="fa-light fa-upload me-1" /> Upload</a>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card border-0 shadow-sm">
            <div className="table-responsive table-card">
              <table className="table table-hover table-centered align-middle table-nowrap mb-0">
                <thead className="text-muted table-main-bg">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Updated At</th>
                    <th scope="col">Size</th>

                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                {!selector?.loading && selector?.main_data?.map((row, index) => (
                  <tr>
                    <td>
                    <i className="fa-light fa-folder me-2"/> {row.name}
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                    
                        <div className="dropdown">
                          <button className="btn btn-light btn-sm dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="fa-light fa-ellipsis" />
                          </button>
                          <ul className="dropdown-menu dropdown-menu-end border-0 shadow-sm fs-14" style={{}}>
                            <li>
                              <Link to={`/employee/${row.employee_id}/view`} className="dropdown-item">
                                <i className="fa-light fa-eye me-2 text-muted" /> View
                              </Link>
                            </li>
                            <li>
                              <Link to={`/employee/${row.employee_id}/edit`} className="dropdown-item" >
                                <i className="fa-light fa-pencil me-2 text-muted" /> Edit
                              </Link>
                            </li>
                         
                            <li className="dropdown-divider" />
                            <li>
                              <a className="dropdown-item remove-list" href="#">
                                <i className="fa-light fa-edit me-2 text-muted" /> Status
                              </a>
                            </li>
                          </ul>
                        </div>
                
                    </td>
                  </tr>
                 ))}
                </tbody>
              </table>
            </div>
            <Paginate nextPage={nextPage} previousPage={previousPage} previousPageUrl={selector?.data.prv_page_url} nextPageUrl={selector?.data.next_page_url} postsPerPage={selector?.data.per_page} paginate={paginate} totalPosts={selector?.data.total_page} hasMorePage={selector?.data.has_more_page} onFirstPage={selector?.data.first_page} hasPage={selector?.data.has_page} currentPage={selector?.data.current_page} />

          </div>
        </div>
      </div>

      </div>
    </>
  )
}
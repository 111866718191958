import { combineReducers } from 'redux';
import homeReducer from './homeSlice';
import authReducers from './authSlice';
import NationalSlice from './NationalSlice';
import ReligionSlice from './ReligionSlice';
import GenderSlice from './GenderSlice';
import RelationshipSlice from './RelationshipSlice';
import DegreeSlice from './DegreeSlice';
import IDTypeSlice from './IDTypeSlice';
import DepartmentSlice from './DepartmentSlice';
import DesignationSlice from './DesignationSlice';
import StatusSlice from './StatusSlice';
import AllowanceSlice from './AllowanceSlice';
import AssetTypeSlice from './AssetTypeSlice';
import BranchSlice from './BranchSlice';
import CompanySlice from './CompanySlice';
import DisciplinaryRulesSlice from './DisciplinaryRulesSlice';
import DocumentTypeSlice from './DocumentTypeSlice';
import FundRulesSlice from './FundRulesSlice';
import ProbationSlice from './ProbationSlice';
import ShiftSlice from './ShiftSlice';
import TaxSetupSlice from './TaxSetupSlice';
import UserSlice from './UserSlice';
import EmployeeSlice from './EmployeeSlice';
import FiscalYearSlice from './FiscalYearSlice';
import BankSlice from './BankSlice';
import EventApprovalSlice from './EventApprovalSlice';
import EventApprovalUserSlice from './EventApprovalUserSlice';
import LeaveCategorySlice from './LeaveCategorySlice';
import PerformanceTypeSlice from './PerformanceTypeSlice';
import PerformanceCriteriaSlice from './PerformanceCriteriaSlice';
import MyAttendanceSlice from './MyAttendanceSlice';
import MyApprovalSlice from './MyApprovalSlice';
import MyLeaveSlice from './MyLeaveSlice';
import OverTimeRequestSlice from './OverTimeRequestSlice';
import LoanRequestSlice from './LoanRequestSlice';
import DeductionSlice from './DeductionSlice';
import BonusTypeSlice from './BonusTypeSlice';
import HolidayTypeSlice from './HolidayTypeSlice';
import WeeklyHolidaySlice from './WeeklyHolidaySlice';
import PubliceHolidaySlice from './PubliceHolidaySlice';
import MyProfileSlice from './MyProfileSlice';
import RoleSlice from './RoleSlice';
import NotifySlice from './NotifySlice';
import UserMenuSlice from './UserMenuSlice';
import PermissionSlice from './PermissionSlice';
import SalarySheetSlice from './SalarySheetSlice';
import MyPayslipSlice from './MyPayslipSlice';
import VacationCategorySlice from './VacationCategorySlice';
import MyVacationSlice from './MyVacationSlice';
import BusinessTripeCategorySlice from './BusinessTripeCategorySlice';
import BusinessTripeMethodSlice from './BusinessTripeMethodSlice';
import BusinessTripeRouteSlice from './BusinessTripeRouteSlice';
import LoanCategorySlice from './LoanCategorySlice';
import RecruitmentCategorySlice from './RecruitmentCategorySlice';
import ExpenseCategorySlice from './ExpenseCategorySlice';
import OvertimeCategorySlice from './OvertimeCategorySlice';
import BusinessTripeSlice from './BusinessTripeSlice';

export default combineReducers({
    homeReducer:homeReducer,
    authReducer:authReducers,
    nationalReducer:NationalSlice,
    religionReducer:ReligionSlice,
    genderReducer:GenderSlice,
    relationshipReducer:RelationshipSlice,
    degreeReducer:DegreeSlice,
    idTypeReducer:IDTypeSlice,
    departmentReducer:DepartmentSlice,
    designationReducer:DesignationSlice,
    statusReducer:StatusSlice,
    allowanceReducer:AllowanceSlice,
    assetTypeReducer:AssetTypeSlice,
    branchReducer:BranchSlice,
    companyReducer:CompanySlice,
    disciplinaryRulesReducer:DisciplinaryRulesSlice,
    documentTypeReducer:DocumentTypeSlice,
    fundRulesReducer:FundRulesSlice,
    probationReducer:ProbationSlice,
    shiftReducer:ShiftSlice,
    taxSetupReducer:TaxSetupSlice,
    employeeReducer:EmployeeSlice,
    userReducer:UserSlice,
    fiscalYearReducer:FiscalYearSlice,
    bankReducer:BankSlice,
    eventApprovalReducer:EventApprovalSlice,
    eventApprovalUserReducer:EventApprovalUserSlice,
    leaveCategoryReducer:LeaveCategorySlice,
    performanceTypeReducer:PerformanceTypeSlice,
    performanceCriteriaReducer:PerformanceCriteriaSlice,
    myAttendanceSlice:MyAttendanceSlice,
    myApprovalReducer:MyApprovalSlice,
    myLeaveReducer:MyLeaveSlice,
    overTimeRequestReducer:OverTimeRequestSlice,
    loanRequestReducer:LoanRequestSlice,
    deductionReducer:DeductionSlice,
    bonusTypeReducer:BonusTypeSlice,
    holidayTypeReducer:HolidayTypeSlice,
    weeklyHolidayReducer:WeeklyHolidaySlice,
    publiceHolidayReducer:PubliceHolidaySlice,
    myProfileSlice:MyProfileSlice,
    roleReducer:RoleSlice,
    notifyReducer:NotifySlice,
    userMenuReducer:UserMenuSlice,
    permissionReducer:PermissionSlice,
    salarySheetReducer:SalarySheetSlice,
    myPayslipReducer:MyPayslipSlice,
    vacationCategoryReducer:VacationCategorySlice,
    myVacationReducer:MyVacationSlice,
    businessTripeCategoryReducer:BusinessTripeCategorySlice,
    businessTripeCategoryReducer:BusinessTripeCategorySlice,
    businessTripeMethodReducer:BusinessTripeMethodSlice,
    businessTripeRouteReducer:BusinessTripeRouteSlice,
    loanCategoryReducer:LoanCategorySlice,
    recruitmentCategoryReducer:RecruitmentCategorySlice,
    expenseCategoryReducer:ExpenseCategorySlice,
    overtimeCategoryReducer:OvertimeCategorySlice,
    businessTripeReducer:BusinessTripeSlice,
});
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import API from "../../../Network/API";
import { default as Select } from "react-select";
import { Link, useNavigate } from "react-router-dom";

export default function WebSetting() {
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [data_value, setDataValue] = useState(null);
    const [data_sett, setDataSetting] = useState(null);
    const [weekdays, setWeekDay] = useState("");
    const [weekdays_select, setWeekDaySelect] = useState([]);
    const [profileInput, setProfileInput] = useState({});
    const [options, setOptions] = useState([]);
    const navigator=useNavigate()
    const handleChange1 = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setProfileInput({ ...profileInput, [name]: value });
    };

    const handleChange = (e) => {

        setWeekDay(e)

    };

    useEffect(() => {
        setLoading(true)
        API.get('/v1/oauth/company_profile').then((response) => {
            setLoading(false)
            setDataValue(response.data.data)
            setWeekDaySelect([response.data.data.weekday_label])
            setOptions(response.data.data.weekday_label)
        }).catch((error) => {
            setLoading(false)
        });

        API.get('/v1/oauth/setting').then((response) => {
            setLoading(false)
            setDataSetting(response.data.data)
        }).catch((error) => {
            setLoading(false)
        });

    }, []);


    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        var data = {
            'currency_code': event.target.currency_code.value,
            'weekdays': weekdays == "" ? data_value?.weekday_label : weekdays,
            'fiscal_year': event.target.fiscal_year.value,
            'timezone': event.target.timezone.value,
        }
        const responsea = API.post('/v1/oauth/edit_setting', data);
        toast.promise(responsea, {
            loading: 'Saving ...',
            success: (response) => {
                setLoading(false);
                if (response.status === 500) throw new Error('server error');
                return `${response.data.message}`;
            },
            error: (e) => `${e.response.data.errors.name ?? e.response.data.message}`,
            duration: 6000,
        })
        setLoading(false)
    }
    return (
        <>
            <div className="page-title-box">
                <div className="page-title-breadcrumb">
                    <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item"><Link to={`/`}>Home</Link></li>
                        <li className="breadcrumb-item active"><a href="#">Settings</a></li>
                    </ol>
                </div>
            </div>

            <div className="card border-0 main-content-card">
                <div className="card-body">
                    <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3 main-content-card-header">
                        <h4 className="main-card-title mb-0 flex-grow-1"> <a href="#" className="text-dark" onClick={() => navigator(- 1)}>
                            <i className="fa-duotone fa-arrow-left me-1" /></a> Settings</h4>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="c-form-area pb-3">
                            <div className="row align-items-end">
                                <div className="col-sm-12 col-md-12  mb-3">
                                    <div className="d-flex align-items-end">
                                        <div className="flex-shrink-0 form-icon-avatar">
                                            <span className="form-icon-title rounded-circle">
                                                <i className="fa-solid fa-dollar-sign text-info" />
                                            </span>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <label htmlFor="c_name" className="form-label fs-14 text-secondary mb-1 text-uppercase c-label">Currency Code</label>
                                            <input defaultValue={data_value?.currency_code} type="text" className="form-control rounded-0" placeholder="Ex: SAR" id="currency" name="currency_code" onChange={(e) => handleChange1(e)} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-12  mb-3">
                                    <div className="d-flex align-items-end">
                                        <div className="flex-shrink-0 form-icon-avatar">
                                            <span className="form-icon-title rounded-circle">
                                                <i className="fa-light fa-calendar text-success" />
                                            </span>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <label htmlFor="c_name" className="form-label fs-14 text-secondary mb-1 text-uppercase c-label">Financial/Fiscal Year (<Link to={`/settings/fiscal_year`}>Add New</Link>)</label>
                                            <select className="form-select rounded-pill" required name="fiscal_year" onChange={(e) => handleChange1(e)}>
                                                <option></option>
                                                {data_sett?.fiscalYear?.map((row, index) => (
                                                    <>
                                                        {
                                                            row.id == data_value?.fiscal_year ?
                                                                <option value={row.id} selected>{row.name}</option>
                                                                :
                                                                <option value={row.id}>{row.name}</option>
                                                        }
                                                    </>

                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                             
                                <div className="col-sm-12 col-md-12  mb-3">
                                    <div className="d-flex align-items-end">
                                        <div className="flex-shrink-0 form-icon-avatar">
                                            <span className="form-icon-title rounded-circle">
                                                <i className="fa-regular fa-timer text-warning" />
                                            </span>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <label htmlFor="c_name" className="form-label fs-14 text-secondary mb-1 text-uppercase c-label">Timezone</label>
                                            <select className="form-select rounded-pill" required onChange={(e) => handleChange1(e)} name="timezone">
                                                <option></option>
                                                {data_sett?.timezone_list?.map((row, index) => (

                                                    <>
                                                        {
                                                            row.name == data_value?.timezone ?
                                                                <option value={row.name} selected>{row.timezone}</option>
                                                                :
                                                                <option value={row.name}>{row.timezone}</option>
                                                        }
                                                    </>
                                                ))}

                                            </select>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="text-end mb-4">
                                <button type="submit" className="btn btn-success rounded-pill">
                                    <i className="fa-light fa-save me-1" />   Save
                                </button>
                            </div>
                        </div>
                    </form>

                </div>{/* end card-body */}
            </div>

        </>
    )
}
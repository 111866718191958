import { Link } from "react-router-dom";
import API from "../../../Network/API";
import { useEffect, useState } from "react";
import Paginate from "../../../services/Paginate";

export default function ManageDeduction() {
    const [isLoading, setLoading] = useState(false);
    const [nxtPage, setNextPage] = useState(null);
    const [search_value, setSearchValue] = useState(null);
    const [data_value, setDataValue] = useState(null);
    const [show, setShow] = useState(false);

    useEffect(() => {
        all_data('')
    }, []);

    const all_data = (name) => {
        API.get('/v1/oauth/manage_bonus?name=' + name).then((response) => {
            setDataValue(response.data.data)
            setLoading(false)
        }).catch((error) => {
            setLoading(true)

        });
    }
    const paginate = (pageNumber) => {
        API.get('/v1/oauth/manage_bonus?page=' + pageNumber).then((response) => {
            setDataValue(response.data.data)
            setLoading(false)
        }).catch((error) => {
            setLoading(true)

        });
    };

    const previousPage = (nxtPage) => {
        setNextPage(nxtPage)
    }

    const nextPage = (nxtPage) => {
        setNextPage(nxtPage)
    }
    return (
        <>
            <div className="container-fluid">
                <div className="page-title-box">
                    <div className="page-title-breadcrumb">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item"><a href="#">Payroll</a></li>
                            <li className="breadcrumb-item active">Manage Deduction</li>
                        </ol>
                    </div>
                </div>

                <div className="row align-items-center py-2">
                    <div className="col-sm">
                        <div className="d-flex align-items-center">
                            <h5 className="card-title mb-0 me-3">Manage Deduction</h5>

                        </div>
                    </div>
                    <div className="col-sm-auto">
                        <div className="d-flex gap-1 flex-wrap">
                            <button type="button" className="btn bg-white shadow-sm">
                                <i className="fa-light fa-download" />
                            </button>

                            <Link to={`/manage_deduction/create`} className="btn add-top-btn btn-success text-white">
                                <i className="fa-light fa-plus me-1" />  Add Deduction</Link>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12">
                        <div className="card border-0 shadow-sm">
                            <div className="table-responsive table-card">
                                <table className="table  table-hover table-centered align-middle table-nowrap mb-0">
                                    <thead className="text-muted table-main-bg">
                                        <tr>

                                            <th scope="col">#Ref No</th>
                                            <th scope="col">Bonus Type</th>
                                            <th scope="col">Duration</th>
                                            <th scope="col">Total Bonus(SAR)</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!isLoading && data_value?.data.map((row, index) => (
                                            <tr>

                                                <td scope="col">#{row.id}</td>
                                                <td scope="col">{row.type_name}</td>
                                                <td scope="col">{row.month_name}-{row.year}</td>
                                       
                                                <td scope="col">{row.total_amount}</td>
                                                <td scope="col">
                                                    <div className="dropdown">
                                                        <button className="btn btn-light btn-sm dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i className="fa-light fa-ellipsis" />
                                                        </button>
                                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow-sm fs-14" style={{}}>
                                                            <li>
                                                                <Link to={`/manage_bonus/${row.id}/view`} className="dropdown-item">
                                                                    <i className="fa-light fa-eye me-2 text-muted" /> View
                                                                </Link>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                <Paginate nextPage={nextPage} previousPage={previousPage} previousPageUrl={data_value?.prv_page_url} nextPageUrl={data_value?.next_page_url} postsPerPage={data_value?.per_page} paginate={paginate} totalPosts={data_value?.total_page} hasMorePage={data_value?.has_more_page} onFirstPage={data_value?.first_page} hasPage={data_value?.has_page} currentPage={data_value?.current_page} />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
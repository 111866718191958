import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import ReactEcharts from "echarts-for-react";
import CheckInCheckoutReport from './CheckInCheckoutReport';
import AttendanceReportPayroll from './AttendanceReportPayroll';
import EmployeePresentStatus from './EmployeePresentStatus';
import API from '../../../../Network/API';
import exportTableToPdf from '../../../../CustomHooks/exportTableToPdf';
import exportTableToExcel from '../../../../CustomHooks/exportTableToExcel';

export default function AttendanceReport() {
    const [isLoading, setLoading] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const echarts_react_1 = useRef();
    const [step, setStep1] = useState(0);
    const [todate, setToDate] = useState(new Date());
    const [currentDate, setCurrentDate] = useState(moment());
    const [present_data, setPresentData] = useState();
    const [daily_attendance_status, setDailyAttendanceStatus] = useState();
    const today = new Date().toLocaleDateString();
    const today1 = new Date(today);
    const formattedDate = today1.toLocaleDateString('en-US', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    });

    const setStep = (stp) => {
        setStep1(stp)
        if (stp == 0) {
            dailyAttendanceStatus(todate.toLocaleDateString())
        }
        else if (stp == 5) {
            dailyAttendanceChckIn(todate.toLocaleDateString())
        }
        else if (stp == 2) {
            searchAttendancePayroll(nextMonthID,nextYear)
        }
        else if (stp == 1) {
            searchPresntStatus(nextMonthID,nextYear)
        }
    }

    const handleNextMonth = () => {
        setCurrentDate(moment(currentDate).add(1, 'month'));
        const nextMonth = new Date(currentMonth);
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        setCurrentMonth(nextMonth);
        if (step == 2) {
            const nextMonthDat = moment(nextMonth);
            const monthID = nextMonthDat.format('MM');
            const cYear = nextMonthDat.format('YYYY');
            searchAttendancePayroll(monthID,cYear)
        }
        else if (step == 1) {
            const nextMonthDat = moment(nextMonth);
            const monthID = nextMonthDat.format('MM');
            const cYear = nextMonthDat.format('YYYY');
            searchPresntStatus(monthID,cYear)
        }
    };

    const handlePreviousMonth = () => {
        setCurrentDate(moment(currentDate).subtract(1, 'month'));
        const previousMonth = new Date(currentMonth);
        previousMonth.setMonth(previousMonth.getMonth() - 1);
        setCurrentMonth(previousMonth);
        if (step == 2) {
            const nextMonthDat = moment(previousMonth);
            const monthID = nextMonthDat.format('MM');
            const cYear = nextMonthDat.format('YYYY');
            searchAttendancePayroll(monthID,cYear)
        }
        else if (step == 1) {
            const nextMonthDat = moment(previousMonth);
            const monthID = nextMonthDat.format('MM');
            const cYear = nextMonthDat.format('YYYY');
            searchPresntStatus(monthID,cYear)
        }
    };

    const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
    const endOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);

    const dates = [];
    const currentDate1 = new Date(startOfMonth);

    while (currentDate1 <= endOfMonth) {
        dates.push(new Date(currentDate1));
        currentDate1.setDate(currentDate1.getDate() + 1);
    }

    const nextMonthDate = moment(currentDate);
    const nextMonthName = nextMonthDate.format('MMMM');
    const nextMonthID = nextMonthDate.format('MM');
    const nextYear = nextMonthDate.format('YYYY');

    const previousMonthDate = moment(currentDate).subtract(1, 'month');
    const previousMonthName = previousMonthDate.format('MMMM');
    const previousYear = previousMonthDate.format('YYYY');
    const [daily_in_out, setDailyInOut] = useState()
    const [attendance_payroll, setAttendancePayroll] = useState()


    const handleNextDate = () => {
        const nextDate = new Date(todate);
        nextDate.setDate(nextDate.getDate() + 1);
        setToDate(nextDate);
        if (step == 0) {
            dailyAttendanceStatus(nextDate.toLocaleDateString())
        } else if (step == 5) {
            dailyAttendanceChckIn(nextDate.toLocaleDateString())
        }
    };

    const handlePreviousDate = () => {
        const previousDate = new Date(todate);
        previousDate.setDate(previousDate.getDate() - 1);
        setToDate(previousDate);
        if (step == 0) {
            dailyAttendanceStatus(previousDate.toLocaleDateString())
        }else if (step == 5) {
            dailyAttendanceChckIn(previousDate.toLocaleDateString())
        }
    };

    const option1 = {
        title: {
            text: 'Daily Attendance Status',
            subtext: 'Employee attendance overview',
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 'left'
        },
        series: [
            {
                name: 'Access',
                type: 'pie',
                radius: '80%',
                label: {
                    show: false,
                    formatter: '{c}',
                    position: 'inside'
                },
                top: "20px",
                right: '35%',
                data: [
                    { value: daily_attendance_status?.total_users ?? 0, name: 'Total Employee' },
                    { value: daily_attendance_status?.present ?? 0, name: 'Present' },
                    { value: daily_attendance_status?.absent ?? 0, name: 'Absent' },
                    { value: daily_attendance_status?.late ?? 0, name: 'Late Attendance' },
                    { value: daily_attendance_status?.leave ?? 0, name: 'Leave' }
                ],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };

    useEffect(() => {
        dailyAttendanceStatus(todate.toLocaleDateString())
    }, []);

    const searchPresntStatus = (montID,netyear) => {
        setLoading(true)
        API.get(`/v1/oauth/report/attendance/employee_prsent_absent_status?month=${montID}&year=${netyear}`).then((response) => {
            setLoading(true)
            setPresentData(response.data.data)
        }).catch((error) => {
            setLoading(false)
        });
    }

    const searchAttendancePayroll = (montId,pyear) => {
        setLoading(true)
        API.get(`/v1/oauth/report/attendance/monthly_summery_attendance?month=${montId}&year=${pyear}`).then((response) => {
            setLoading(true)
            setAttendancePayroll(response.data.data)
        }).catch((error) => {
            setLoading(false)
        });
    }

    const dailyAttendanceStatus = (tday) => {
        setLoading(true)
        API.get(`/v1/oauth/report/attendance/employee_daily_attendance_status?date=${tday}`).then((response) => {
            setLoading(true)
            setDailyAttendanceStatus(response.data.data)
        }).catch((error) => {
            setLoading(false)
        });
    }

    const dailyAttendanceChckIn = (tday) => {
        setLoading(true)
        API.get(`/v1/oauth/report/attendance/daily_attendance?date=${tday}`).then((response) => {
            setLoading(true)
            setDailyInOut(response.data.data)
        }).catch((error) => {
            setLoading(false)
        });
    }

    const handleExportToExcel = () => {
        if(step==1){
        exportTableToExcel('month_presnt_status', 'Employee_present_absent_status');
        }else if(step==2){
            exportTableToExcel('attendance_report_payroll', 'Attendance_data_for_payroll');
        }
      };

    const handleExportToPdf = () => {
        if(step==1){
            exportTableToPdf('month_presnt_status', 'Employee_present_absent_status.pdf');
        }

        else if(step==2){
            exportTableToPdf('attendance_report_payroll', 'Attendance_data_for_payroll.pdf');
        }
       
      };

    return (
        <>
            <div className="container-fluid">
                <div className="page-title-box">
                    <div className="page-title-breadcrumb">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item active">Attendance Report</li>
                        </ol>
                    </div>
                </div>

                <div className="card border-0 main-content-card">
                    <div className="card-body">
                        <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3 main-content-card-header">
                            <h4 className="main-card-title mb-0 flex-grow-1"><a href="#" onClick={() => navigator(- 1)} class="text-dark">
                                <i class="fa-duotone fa-arrow-left me-1"></i></a> Attendance Report
                            </h4>
                            <div>
                                {step == 1 || step == 2 ?
                                    <>
                                        <span className='me-3'>{nextMonthName}-{nextYear}</span>
                                        <a href='#' onClick={handlePreviousMonth} className='me-2'><i class="fa-solid fa-angle-left"></i></a>
                                        <a href='#' onClick={handleNextMonth} className='me-2'><i class="fa-solid fa-angle-right"></i></a>

                                    </>
                                    :
                                    <>

                                        <span className='me-3'>{todate.toLocaleDateString()}</span>
                                        <a href='#' onClick={handlePreviousDate} className='me-2'><i class="fa-solid fa-angle-left"></i></a>
                                        <a href='#' onClick={handleNextDate} className='me-2'><i class="fa-solid fa-angle-right"></i></a>

                                    </>
                                }
                            </div>
                            <div class="dropdown">
                                <a class="btn btn-sm dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                    {step == 0 ? 'Daily attendance status' : step == 1 ? 'Employee present/absent status' : step == 2 ? 'Attendance data for payroll' : step == 3 ? 'Presence hours break-up' : step == 4 ? 'Overtime details' : 'check-in and check-out'}
                                </a>

                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <li><a class="dropdown-item" href="#" onClick={() => setStep(0)}>Daily attendance status</a></li>
                                    <li><a class="dropdown-item" href="#" onClick={() => setStep(1)}>Employee present/absent status</a></li>
                                    <li><a class="dropdown-item" href="#" onClick={() => setStep(2)}>Attendance data for payroll</a></li>
                                    {/* <li><a class="dropdown-item" href="#" onClick={()=>setStep(3)}>Presence hours break-up</a></li> */}
                                    {/* <li><a class="dropdown-item" href="#" onClick={()=>setStep(4)}>Overtime details</a></li> */}
                                    <li><a class="dropdown-item" href="#" onClick={() => setStep(5)}>check-in and check-out</a></li>
                                </ul>
                            </div>
                            <div className="flex-shrink-0">
                                <div className="app-search">
                                    <div class="dropdown">
                                        <a class="btn btn-sm btn-light rounded-circle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="fa-solid fa-ellipsis"></i>
                                        </a>

                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <li><a class="dropdown-item" href="#" onClick={()=>handleExportToExcel()}><i class="fa-duotone fa-download"></i> Export</a></li>
                                            <li><a class="dropdown-item" href="#" onClick={() => window.print()}><i class="fa-duotone fa-print"></i> Print</a></li>
                                            <li><a class="dropdown-item" href="#" onClick={()=>handleExportToPdf()}><i class="fa-duotone fa-file-pdf"></i> Download as PDF</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            {step == 0 ?
                                <ReactEcharts ref={echarts_react_1} style={{ height: "260px" }} option={option1} />
                                : step == 1 ?
                                    <EmployeePresentStatus present_data={present_data} />
                                    : step == 2 ?
                                        <AttendanceReportPayroll data={attendance_payroll} />
                                        : step == 4 ? ''
                                            : step == 5 ?
                                                <CheckInCheckoutReport data={daily_in_out} />
                                                : ''
                            }
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}
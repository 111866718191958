import API from "../../../Network/API";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import ProfileNav from "./ProfileNav";

export default function EmployeeJob(){
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const prams = useParams();
  const [prifle_data, setProfile] = useState(null);

  useEffect(() => {
    API.get(`/v1/oauth/users/${prams.id}/show`).then((response) => {
        setProfile(response.data.data.employee)
        setLoading(false)
    }).catch((error) => {
        setLoading(true)
    });
}, []);

    return (
        <>

          <div className="profile-foreground position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg" />
          </div>
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8 col-sm-12">
              <div className="pt-5 profile-wrapper">
                <div className="row g-4">
                  <div className="col-auto">
                    <div className="avatar-lg">
                      <img src={prifle_data?.image} alt="user-img" className="img-thumbnail rounded-circle" />
                    </div>
                  </div>
                  {/*end col*/}
                  <div className="col">
                    <div className>
                      <h3 className="text-white mb-1">{prifle_data?.name}</h3>
                      <p className="text-white">{prifle_data?.designation_name}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-10">
              <div className="row">
                <div className="col-md-3">
                  <ProfileNav sl={2} user_id={prifle_data?.user_id}/>
                </div>
                <div className="col-md-9">
                  <div className="card border-0 main-content-card profile-card-minius">
                    <div className="card-body">
                      <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3">
                        <h4 className="main-card-title mb-0 flex-grow-1">Job</h4>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex align-items-center mb-2">
                            <p className="card-title mb-0 fs-14">Job Details</p>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-duotone fa-user text-primary fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.manager}</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">MANAGER NAME</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-duotone fa-list-tree text-primary fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.department_name}</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">DEPARTMENT</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-solid fa-list-check text-primary fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.branch_name}</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">BRANCH</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-solid fa-list-check text-primary fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.probation_name}</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">PROBATION</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-regular fa-ballot-check text-info fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.shift_name}</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">SHIFT</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-duotone fa-calendar text-primary fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.join_date}</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">JOIN DATE</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <hr className="my-2" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex align-items-center mb-2">
                            <p className="card-title mb-0 fs-14">Payroll</p>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-light fa-timer text-primary fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.duration_type==1?'Monthly':'Hourly'}</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">SALARY TYPE</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-light fa-money-bill text-primary fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.payment_type==1?'Cash':'Bank'}</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">PAYSLIP TYPE</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-light fa-dollar-sign text-primary fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.basic_salary_num} SAR</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">BASIC SALARY</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-duotone fa-percent text-primary fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.house_rent_percent}%</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">HOUSEING</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-duotone fa-percent text-primary fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.medical_allowance_percent}%</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">MEDICAL</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-duotone fa-percent text-primary fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.car_rent_percent}%</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">TRANSPORTATION</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-light fa-dollar-sign text-primary fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.providant_fund_num} SAR</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">PROVIDENT FUND</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
          
                              <i className="fa-duotone fa-percent text-primary fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.gosi}%</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">TAX/GOSI</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="d-flex align-items-center mb-2">
                            <p className="card-title mb-0 fs-14">Allowances</p>
                          </div>
                        </div>
                        <div className="col-12">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Allowance Name</th>
                              <th>Amount(SAR)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {prifle_data?.allowances?.length>0 && prifle_data?.allowances.map((row,index)=>(
                          
                            <tr key={index}>
                              <td>{row.name}</td>
                              <td>{row.amount}</td>
                            </tr>
                                
                                ))}
                          </tbody>
                        </table>
                        </div>
                        
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    
        </>
    )

}
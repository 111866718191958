import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

export default function AppCard({data}) {
    const location = useLocation();
    const { t } = useTranslation();
    return (
        <>
            <Link to={`/all_requests/${data.id}/view`}>

                <div class={`card team-box border-0 shadow mb-1  ${location.pathname==`/all_requests/${data.id}/view`?'bg-white':'bg-custom'}`}>
                    <div class="card-body px-2">
                        <div class="d-flex align-items-center team-row">
                            <div class="flex-grow-1">
                                <div class="team-profile-img ">
                              
                                    <div class="avatar-lg img-thumbnail rounded-circle me-2">
                                    <div class="image-container height-38 d-block rounded-circle" style={{backgroundImage:`url(${data.image})`}}></div>
                                                  
                                                </div>
                                    <div class="team-content">
                                        <a href="#" class="d-block text-dark">
                                            <h5 class="fs-16 mb-1">
                                                <i class={`fa-light ${data.event_type==1?'fa-stethoscope':data.event_type==2?'fa-dollar':data.event_type==8?'fa-clock':''} me-1 text-success`}></i>
                                                {data.event_type==1?data.leaves?.leave_category:data.title}
                                            </h5>
                                        </a>
                                        <p class="text-muted mb-0">{data.user_name}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-shrink-0">
                                <div class="text-end">
                                    <div class="fs-16 mb-1 text-secondary">
                                        {data.leaves?.total_approval?.length>0 && data.leaves?.total_approval?.map((row,index)=>(
                                            <>
                                            
                                             <i class={`fa-light ${row.status==1 && row.allow_next==1?'fa-check text-success':row.status==0 && row.allow_next==1?'fa-clock text-warning':'fa-regular fa-circle'} me-1  `}></i>
                                            </>
                                           
                                        ))}
                                        

                                        {/* <i class="fa-regular fa-clock me-1 text-warning"></i>
                                        <i class="fa-regular fa-circle me-1"></i>
                                        <i class="fa-regular fa-circle"></i> */}
                                    </div>
                                    <small class="text-muted mb-0">{data.created_at}</small>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>



            </Link>
        </>
    )
}
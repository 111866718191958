import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BaseUrl from "../../../Network/BaseUrl";
import { toast } from "react-hot-toast";

export default function AddManageDeduction() {
    const [saveLoading, setSaveLoading] = useState(false);
    const formRef = useRef();
    const navigate = useNavigate()
    const [amount, setAmount] = useState(0)
    const [duration, setDuration] = useState(1)
    const [step, setStepNumber] = useState(1)
    const year = (new Date()).getFullYear();
    const yearLast = 2022;
    const years = Array.from(new Array(20), (val, index) => index + yearLast);
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
    const echarts_react_1 = useRef();
    const [month_id, setMonthId] = useState(currentMonth)
    const [year_id, setYear] = useState(year)

    const handleSubmit = (event) => {
        event.preventDefault();
        setSaveLoading(true)
        // Access form data using the formRef

        const fd = new FormData();

        fd.append(`month`, event.target.month.value)
        fd.append(`duration`, event.target.duration.value)
        fd.append(`amount`, event.target.amount.value)
        fd.append(`message`, event.target.remark.value)

        BaseUrl.post('/v1/oauth/loan_requests/save', fd)
            .then((response) => {
                setSaveLoading(false)
                toast.success(response.data.data.message);
                navigate(-1)
            })
            .catch((err) => {
                setSaveLoading(false)
                toast.error(err.response.data.message);
            });


    }

    const numbers = [];

    for (let i = 1; i <= 12; i++) {
        numbers.push(i);
    }
    return (
        <>
            <div className="container-fluid">
                <div className="page-title-box">
                    <div className="page-title-breadcrumb">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item"><a href="#">Payroll</a></li>
                            <li className="breadcrumb-item active">Add Deduction</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-10 offset-1">

                        <div className="card border-0 main-content-card">
                            <div className="card-body">
                                <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3">
                                    <h4 className="main-card-title mb-0 flex-grow-1">
                                        <Link to={`/manage_deduction`} className="text-dark">
                                            <i className="fa-duotone fa-arrow-left me-1" />
                                            Add deduction
                                        </Link>
                                    </h4>
                                    <div className="flex-shrink-0 text-end">
                                        <h4 className="fs-16 fw-bold mb-0">SAR {amount.toLocaleString()}</h4>
                                        <p className="text-secondary fw-bold mb-0">Total Amount</p>
                                    </div>
                                    
                                </div>

                                <form onSubmit={handleSubmit} ref={formRef}>
                                        <div className="c-form-area pb-3">
                                            <div className="row align-items-end mb-3">
                                                <div className="col-sm-6 col-md-7">
                                                    <div className="d-flex align-items-end">
                                                        <div className="flex-shrink-0 form-icon-avatar">
                                                            <span className="form-icon-title rounded-circle">
                                                                <i className="fa-duotone fa-coins text-primary" />
                                                            </span>
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <label htmlFor="amount" className="form-label fs-14 text-secondary mb-1 text-uppercase">AMOUNT (SAR)*</label>
                                                            <input min={1} max={60000} onChange={(e) => setAmount(e.target.value)} type="text" className="form-control rounded-0" required placeholder="Enter Amount" id="amount" name="amount" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-5">
                                                    <p className="mb-0 text-secondary">
                                                        Select the deduction amount <br />

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
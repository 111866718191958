

import { createSlice } from '@reduxjs/toolkit';

export const companySlice = createSlice({
    name:'company',
    initialState:{
        loading: false,
        data: {

        },
        main_data:[],
        error: false,
    },
    reducers:{
        COMPANY_FETCH_START:(state)=>{
            state.loading=true
        },
        COMPANY_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
            state.main_data=action.payload.data
        },
        ADD_COMPANY_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.main_data = [action.payload,...state.main_data]
        },
        COMPANY_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const { COMPANY_FETCH_START,COMPANY_FETCH_SUCCESS,COMPANY_FETCH_ERROR,ADD_COMPANY_FETCH_SUCCESS }=companySlice.actions;
export default companySlice.reducer;
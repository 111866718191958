import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import API from "../../../Network/API";
import { toast } from "react-hot-toast";

export default function AddSalarySheetBulk() {

    const [name, setName] = useState("");
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const inpRef = useRef()
    const modalRef1 = useRef()
    const year = (new Date()).getFullYear();
    const yearLast = 2022;
    const years = Array.from(new Array(20), (val, index) => index + yearLast);
    const navigator = useNavigate()
    const months = [
        {
            'id': '01',
            'name': 'January',
        },
        {
            'id': '02',
            'name': 'February',
        },
        {
            'id': '03',
            'name': 'March',
        },
        {
            'id': '04',
            'name': 'April',
        },
        {
            'id': '04',
            'name': 'May',
        },
        {
            'id': '06',
            'name': 'June',
        },
        {
            'id': '07',
            'name': 'July',
        },
        {
            'id': '08',
            'name': 'August',
        },
        {
            'id': '09',
            'name': 'September',
        },
        {
            'id': '10',
            'name': 'October',
        },
        {
            'id': '11',
            'name': 'November',
        },
        {
            'id': '12',
            'name': 'December',
        }

    ];
    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString('default', { month: 'long' });

    const [types, setType] = useState([]);
    useEffect(() => {
        API.get(`/v1/oauth/manage_salary/bulk`).then((response) => {
            setType(response.data.data.branch)
        }).catch((error) => {

        });
    }, []);



    const handleSubmit = (event) => {
        event.preventDefault();
        if(event.target.type_id.value==''){
            toast.error('Please select bonus type')
            return;
        }
        if(event.target.month_id.value==''){
            toast.error('Please select month')
            return;
        }
        if(event.target.year.value==''){
            toast.error('Please select year')
            return;
        }
        var data = {
          
            'branch': event.target.type_id.value,
            'year': event.target.year.value,
            'month_id': event.target.month_id.value,
            'remark': event.target.remark.value,
        }
        const responsea = API.post('/v1/oauth/manage_salary/bulk/save', data);
        toast.promise(responsea, {
            loading: 'Saving ...',
            success: (response) => {
                console.log(response.status)
                if (response.status === 500){
                    return `Server Error`;
                }else if (response.status === 200){
                     navigator('/salary_sheet')
                    return `${response.data.message}`;
                };
                
            },
            error: (e) => `${e.response.data.errors.name ?? e.response.data.message}`,
            duration: 6000,
        })

    }
    return (
        <>
            <div className="container-fluid">
                <div className="page-title-box">
                    <div className="page-title-breadcrumb">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item"><a href="#">Payroll</a></li>
                            <li className="breadcrumb-item active">Add Deduction</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-10 offset-1">

                        <div className="card border-0 main-content-card">
                            <div className="card-body">
                                <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3">
                                    <h4 className="main-card-title mb-0 flex-grow-1">
                                        <Link to={`/salary_sheet`} className="text-dark">
                                            <i className="fa-duotone fa-arrow-left me-2" />
                                            Generate Bulk Salary Sheet
                                        </Link>
                                    </h4>


                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div className="c-form-area pb-3">
                                        <div className="row align-items-end mb-3">
                                            <div className="col-sm-6 col-md-7">
                                                <div className="d-flex align-items-end">
                                                    <div className="flex-shrink-0 form-icon-avatar">
                                                        <span className="form-icon-title rounded-circle">
                                               
                                                            <i class="fa-duotone fa-code-branch text-warning"></i>
                                                        </span>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <label htmlFor="branch" className="form-label fs-14 text-secondary mb-1 text-uppercase">Branch*</label>
                                                        <select required name="type_id" className="form-select">
                                                            <option value={``}>Choose.......</option>
                                                        {types.map((type, index) => (
                                                    <option key={index} value={type.id}>{type.name}</option>
                                                ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="row align-items-end mb-3">
                                                <div className="col-sm-6 col-md-7">
                                                    <div className="d-flex align-items-end">
                                                        <div className="flex-shrink-0 form-icon-avatar">
                                                            <span className="form-icon-title rounded-circle">
                                                            <i class="fa-regular fa-calendar-week text-primary"></i>
                                                            </span>
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <label htmlFor="branch" className="form-label fs-14 text-secondary mb-1 text-uppercase">Year*</label>
                                                            <select name="year" required className="form-select">
                                                                {years?.map((value, index) => (
                                                                    <option key={`year${index}`} value={value} selected={year == value ? true : false}>{value}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row align-items-end mb-3">
                                                <div className="col-sm-6 col-md-7">
                                                    <div className="d-flex align-items-end">
                                                        <div className="flex-shrink-0 form-icon-avatar">
                                                            <span className="form-icon-title rounded-circle">
                                                                <i className="fa-duotone fa-calendar text-success" />
                                                            </span>
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <label htmlFor="branch" className="form-label fs-14 text-secondary mb-1 text-uppercase">Month*</label>
                                                            <select required name="month_id" className="form-select">
                                                                {months.map((month, index) => (
                                                                    <option key={index} value={month.id} selected={currentMonth == month.name ? true : false}>{month.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                    <div className="row align-items-end mb-3">
                                        <div className="col-sm-12">
                                            <div className="d-flex align-items-start">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i className="fa-duotone fa-message-captions text-secondary" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <textarea name="remark" className="form-control rounded-0" id="exampleFormControlTextarea1" rows={3} placeholder="Write a message" defaultValue={""} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2 ms-2 mb-2">
                                        <button type="submit" class="btn btn-success">Generate Salary {isLoading ? (
                                            <span
                                                className="spinner-border spinner-border-sm ml-5"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        ) : (
                                            <span></span>
                                        )}</button>
                                    </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default function LeaveReport(){
    return (
        <>
          <div className="container-fluid">
                <div className="page-title-box">
                    <div className="page-title-breadcrumb">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item active">Leave Report</li>
                        </ol>
                    </div>
                </div>

            </div>
        </>
    )
}
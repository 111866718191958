

import { createSlice } from '@reduxjs/toolkit';

export const employeeSlice = createSlice({
    name: 'EMPLOYEE',
    initialState: {
        loading: false,
        data: {

        },
        main_data: [],
        error: false,
    },
    reducers: {
        EMPLOYEE_FETCH_START: (state) => {
            state.loading = true
        },
        EMPLOYEE_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.main_data = action.payload.data
        },
        ADD_EMPLOYEE_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.main_data = [action.payload, ...state.main_data]
        },
        EDIT_EMPLOYEE_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            const newArray = state.main_data.filter(todo => todo.id !==action.payload.id)
             state.main_data = [...newArray,action.payload]
        },
        DELETE_EMPLOYEE:(state,action)=>{
            const filterPosts = state.main_data.filter(
                (post) => post.id !== action.payload
              );
            state.loading = false
            state.main_data=filterPosts
        },
        EMPLOYEE_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { EMPLOYEE_FETCH_START, EMPLOYEE_FETCH_SUCCESS, EMPLOYEE_FETCH_ERROR, ADD_EMPLOYEE_FETCH_SUCCESS } = employeeSlice.actions;
export default employeeSlice.reducer;


import { createSlice } from '@reduxjs/toolkit';

export const businessTripeRouteSlice = createSlice({
    name: 'businessTripeRoute',
    initialState: {
        loading: false,
        data: {

        },
        main_data: [],
        error: false,
    },
    reducers: {
        BUSINESS_TRIPE_ROUTE_FETCH_START: (state) => {
            state.loading = true
        },
        BUSINESS_TRIPE_ROUTE_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.main_data = action.payload.data
        },
        ADD_BUSINESS_TRIPE_ROUTE_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.main_data = [action.payload, ...state.main_data]
        },
        EDIT_BUSINESS_TRIPE_ROUTE_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            const newArray = state.main_data.filter(todo => todo.id !== action.payload.id)
            state.main_data = [...newArray, action.payload]
        },
        DELETE_BUSINESS_TRIPE_ROUTE: (state, action) => {
            const filterPosts = state.main_data.filter(
                (post) => post.id !== action.payload
            );
            state.loading = false
            state.main_data = filterPosts
        },
        BUSINESS_TRIPE_ROUTE_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { DELETE_BUSINESS_TRIPE_ROUTE, EDIT_BUSINESS_TRIPE_ROUTE_FETCH_SUCCESS, BUSINESS_TRIPE_ROUTE_FETCH_START, BUSINESS_TRIPE_ROUTE_FETCH_SUCCESS, BUSINESS_TRIPE_ROUTE_FETCH_ERROR, ADD_BUSINESS_TRIPE_ROUTE_FETCH_SUCCESS } = businessTripeRouteSlice.actions;
export default businessTripeRouteSlice.reducer;
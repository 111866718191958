import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {
    name:'nur'
  },
  accessToken: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    USER_LOGGED_IN: (state, action) => {
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
    },
    USER_LOGGED_OUT: (state) => {
      state.user = undefined;
      state.accessToken = undefined;
      localStorage.removeItem("auth");
    },
  },
});

export const { USER_LOGGED_IN, USER_LOGGED_OUT } = authSlice.actions;
export default authSlice.reducer;
import { useDispatch, useSelector } from "react-redux";
import API from "../../../../Network/API";
import { useEffect, useState } from "react";
import Paginate from "../../../../services/Paginate";
import { toast } from "react-hot-toast";

import { ALLOWANCE_FETCH_ERROR, ALLOWANCE_FETCH_SUCCESS, DELETE_ALLOWANCE } from "../../../../Reducer/AllowanceSlice";
import AddBank from "./AddBank";
import EditBank from "./EditBank";
import { BANK_FETCH_ERROR, BANK_FETCH_SUCCESS, DELETE_BANK } from "../../../../Reducer/BankSlice";

export default function Bank() {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [nxtPage, setNextPage] = useState(null);
  const [search_value, setSearchValue] = useState(null);
  const [data_value, setDataValue] = useState(null);

  const [show, setShow] = useState(false);

  const handleShow = (row) => {
    setShow(!show)
    setDataValue(row)
  };

  useEffect(() => {
    all_data('')
  }, []);

  const all_data = (name) => {
    API.get('/v1/oauth/bank_info?name='+name).then((response) => {
      dispatch(BANK_FETCH_SUCCESS(response.data.data))
      setLoading(false)
    }).catch((error) => {
      setLoading(true)
      BANK_FETCH_ERROR();
    });
  }

  const paginate = (pageNumber) => {
    API.get('/v1/oauth/bank_info?page='+pageNumber).then((response) => {
      dispatch(BANK_FETCH_SUCCESS(response.data.data))
      setLoading(false)
    }).catch((error) => {
      setLoading(true)
      BANK_FETCH_ERROR();
    });
  };

  const previousPage = (nxtPage) => {
    setNextPage(nxtPage)
  }

  const nextPage = (nxtPage) => {
    setNextPage(nxtPage)
  }

  const delete_designation = (id) => {

    if (window.confirm("Are you sure want to delete this?")) {
      const responsead = API.delete('/v1/oauth/bank_info/' + id + '').then((response) => {
        toast.promise(responsead, {
          loading: 'Delating ...',
          success: (response) => {
            dispatch(DELETE_BANK(id))
            return `Delete Successfully`;
          },
          error: (e) => `${e.response.data.errors.name ?? e.response.data.message}`,
          duration: 6000,
        })

      }).catch((error) => {
        setLoading(true)
      });
    }

  }

  const selector = useSelector((state) => state.bankReducer)
 
  return (
    <>
      <div className="page-title-box">
        <div className="page-title-breadcrumb">
          <ol className="breadcrumb m-0">
            <li className="breadcrumb-item"><a href="#">Home</a></li>
            <li className="breadcrumb-item"><a href="#">Settings</a></li>
            <li className="breadcrumb-item active">Bank List</li>
          </ol>
        </div>
      </div>
      <div className="card border-0 main-content-card">
        <div className="card-body">
          <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3 main-content-card-header">
            <h4 className="main-card-title mb-0 flex-grow-1">Bank List</h4>
            <div className="flex-shrink-0">
              <div className="app-search">
                <div className="input-group">
                  <div className="position-relative">
                    <input onChange={(e)=>all_data(e.target.value)} type="text" className="form-control" placeholder="Search..." autoComplete="off" id="search-options"/>
                    <span className="fa-light fa-search search-widget-icon" />
                  </div>
                  <button className="btn btn-add" type="button" id="button-addon2"  data-bs-toggle="modal" data-bs-target="#myModal"><i className="fa-regular fa-plus" /></button>
                </div>
              </div>
            </div>
          </div>
          <div className="live-preview">
            <ul className="list-group list-group-flush">
            {!selector?.loading && selector?.main_data?.map((row, index) => (
            <>
            <li class="list-group-item">
              <div class="d-flex align-items-center">
                <div class="me-auto">
                  {row.name}
                </div>
                <div class="dropdown action-dropdown">
                  <a class="btn btn-sm  py-0" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="fa-light fa-ellipsis-stroke-vertical"></i>
                  </a>
                  <ul class="dropdown-menu border-1 shadow-sm">
                    <li><a class="dropdown-item" href="#" onClick={() => handleShow(row)}><i class="fa-light fa-pen me-1" ></i>Edit</a></li>
                    <li><a class="dropdown-item" href="#" onClick={() => delete_designation(row.id)}><i class="fa-light fa-trash me-1"></i>Delete</a></li>
                  </ul>
                </div>
              </div>
            </li>

          </>
           ))}
            </ul>
            <Paginate nextPage={nextPage} previousPage={previousPage} previousPageUrl={selector?.data.prv_page_url} nextPageUrl={selector?.data.next_page_url} postsPerPage={selector?.data.per_page} paginate={paginate} totalPosts={selector?.data.total_page} hasMorePage={selector?.data.has_more_page} onFirstPage={selector?.data.first_page} hasPage={selector?.data.has_page} currentPage={selector?.data.current_page} />

          </div>
        </div>{/* end card-body */}
      </div>

      <AddBank/>
      <EditBank show={show} onHide={handleShow} row_data={data_value} />
    </>
  )
}
import { useEffect, useRef, useState } from "react";
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from "react-redux";
import API from "../../../../Network/API";
import Modal from 'react-bootstrap/Modal';
import { EDIT_SHIFT_FETCH_SUCCESS } from "../../../../Reducer/ShiftSlice";

export default function EditShift({ show, onHide, row_data }) {
    const [name, setName] = useState("");
    const [start_time, setStartTime] = useState("");
    const [end_time, setEndTime] = useState("");
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const inpRef = useRef()
    const modalRef1 = useRef()
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
 
        const data = new FormData();
        data.append('name', event.target.name.value)
        data.append('start_time', event.target.start_time.value)
        data.append('end_time', event.target.end_time.value)
        data.append('last_in_time', event.target.last_in_time.value)
        data.append('_method', 'PUT')

        const responsea = API.post('/v1/oauth/office_shift/' + row_data.id + '/update', data);
        toast.promise(responsea, {
            loading: 'Saving ...',
            success: (response) => {
                setLoading(false);
                modalRef1.current.click();
                document.getElementById("add_country_form").reset()
                dispatch(EDIT_SHIFT_FETCH_SUCCESS(response.data.data))
                if (response.status === 500) throw new Error('server error');
                return `${response.data.message}`;
            },
            error: (e) => `${e.response.data.errors.name ?? e.response.data.message}`,
            duration: 6000,
        })
        setLoading(false)
    }

    return (
        <>
            <Modal show={show} onHide={onHide}>
                <Modal.Header>
                    <h4 class="modal-title">Edit Shift</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" ref={modalRef1} onClick={onHide}></button>

                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={handleSubmit} id="add_country_form">
                        <div className="row">
                            <div className="col-sm-12 mb-3">
                                <label htmlFor="name" className="form-label c-label">Name</label>
                                <input defaultValue={row_data?.name} ref={inpRef} onChange={(e) => setName(e.target.value)} type="text" className="form-control rounded-pill" id="name" name="name" placeholder required />
                            </div>
                            <div className="col-sm-12 mb-3">
                                <label htmlFor="name" className="form-label c-label">Start Time</label>
                                <input defaultValue={row_data?.starttime} onChange={(e) => setStartTime(e.target.value)} type="time" className="form-control rounded-pill" name="start_time"  placeholder required />
                            </div>

                            <div className="col-sm-12 mb-3">
                                <label htmlFor="name" className="form-label c-label">End Time</label>
                                <input defaultValue={row_data?.endtime} onChange={(e) => setEndTime(e.target.value)} type="time" className="form-control rounded-pill" name="end_time" placeholder required />
                            </div>

                            <div className="col-sm-12 mb-3">
                                        <label htmlFor="last_in_time" className="form-label c-label">Last CheckIn Time<em className="text-danger">*</em></label>
                                        <input defaultValue={row_data?.last_time}  type="time" className="form-control rounded-pill" id="last_in_time" name="last_in_time" placeholder required />
                                    </div>

                        </div>

                        <div class="modal-footer">
                            <button type="submit" class="btn btn-success">Submit {isLoading ? (
                                <span
                                    className="spinner-border spinner-border-sm ml-5"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            ) : (
                                <span></span>
                            )}</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}
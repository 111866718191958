// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
 import { getMessaging, getToken, onMessage } from "firebase/messaging";
import BaseUrl from "./BaseUrl";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDcuazQLH7PkB27NuAxXVE5BztmZBKg4dY",
  authDomain: "alifhr-cb0eb.firebaseapp.com",
  projectId: "alifhr-cb0eb",
  storageBucket: "alifhr-cb0eb.appspot.com",
  messagingSenderId: "1009764446669",
  appId: "1:1009764446669:web:082ccbdcf18a081760d484",
  measurementId: "G-LGLQZVZPXF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging();

export const requestForToken = () => {
    return getToken(messaging, { vapidKey: 'BBXcgqy2-75AO30x8xX70PD7DJpAf3WLpxQkFh8cH-yLuPXNVHAC9P5zLAkt8SxaTGDiHsASfP_4nvu2inPjoZw' })
      .then((currentToken) => {
        if (currentToken) {
          const fd = new FormData();
          fd.append(`device_token`,currentToken)
          BaseUrl.post('/v1/oauth/save_fcm_token', fd)
            .then((response) => {
              console.log('fcm success');
            })
            .catch((err) => {
            
            });
          console.log('current token for client: ', currentToken);
          // Perform any other neccessary action with the token
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  };


  export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });

  
import { useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import API from '../../../Network/API';
import { EDIT_SALARY_SHEET_FETCH_SUCCESS } from '../../../Reducer/SalarySheetSlice';

export default function AddPayment({ show, onHide, row_data }) {
    const [isLoading, setLoading] = useState(false);
    const modalRef1 = useRef()
    const dispatch = useDispatch();

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        var data = {
            'id': row_data?.id,
            'user_id': row_data?.user_id,
            'pay_type': event.target.status.value,
            'comment': event.target.note.value,
        }
        const responsea = API.post('/v1/oauth/salary_payment/save', data);
        toast.promise(responsea, {
            loading: 'Saving ...',
            success: (response) => {
                setLoading(false);
                if (response.status === 500){
                    return `Server Error`;
                }else if (response.status === 200){
                    dispatch(EDIT_SALARY_SHEET_FETCH_SUCCESS(response.data.data))
                    modalRef1.current.click();
                    document.getElementById("add_country_form").reset()
                    
                    return `${response.data.message}`;
                };
                
            },
            error: (e) => `${e.response.data.errors ?? e.response.data.message}`,
            duration: 6000,
        })
    }
    return (
        <>
            <Modal show={show} onHide={onHide} backdrop="static">
                <Modal.Header>
                    <h4 class="modal-title">Make Payment (<span>{row_data?.month_name}-{row_data?.year}</span>)</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" ref={modalRef1} onClick={onHide}></button>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit} id="add_country_form">

                        <div className='row'>
                            <div className="col-sm-6 mb-2">
                                <label htmlFor="name" className="form-label c-label">Employee Name</label>
                                <input disabled defaultValue={row_data?.name} type="text" className="form-control" id="name" />
                            </div>
                            <div className="col-sm-6 mb-2">
                                <label htmlFor="basic_salary" className="form-label c-label">Basic Salary</label>
                                <input disabled defaultValue={row_data?.basic_salary} type="text" className="form-control" id="basic_salary" />
                            </div>
                            <div className="col-sm-6 mb-2">
                                <label htmlFor="total_addition" className="form-label c-label">Total Addition</label>
                                <input disabled defaultValue={row_data?.total_addition} type="text" className="form-control" id="total_addition" />
                            </div>
                            <div className="col-sm-6 mb-2">
                                <label htmlFor="total_deduction" className="form-label c-label">Total Deduction</label>
                                <input disabled defaultValue={row_data?.total_deduction} type="text" className="form-control" id="total_deduction" />
                            </div>
                            <div className="col-sm-6 mb-2">
                                <label htmlFor="total_deduction" className="form-label c-label">Net Payable</label>
                                <input disabled defaultValue={row_data?.net_payable} type="text" className="form-control" id="total_deduction" />
                            </div>
                            <div className="col-sm-6 mb-2">
                                <label htmlFor="total_deduction" className="form-label c-label">Payment Method*</label>
                                <select className='form-select' name='status' required>
                                    <option value={1}>Cash</option>
                                    <option value={2}>Cheque</option>
                                </select>
                            </div>
                            <div className="col-sm-12 mb-2">
                                <label htmlFor="total_deduction" className="form-label c-label">Message</label>
                                <textarea className='form-control' rows={3} cols={3} name='note'/>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-success">Pay {isLoading ? (
                                <span
                                    className="spinner-border spinner-border-sm ml-5"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            ) : (
                                <span></span>
                            )}</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}
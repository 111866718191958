import { useDispatch, useSelector } from "react-redux";
import API from "../../../../Network/API";
import { useEffect, useState } from "react";
import Paginate from "../../../../services/Paginate";

import { toast } from "react-hot-toast";
import { BRANCH_FETCH_ERROR, BRANCH_FETCH_SUCCESS, DELETE_BRANCH } from "../../../../Reducer/BranchSlice";
import AddBranch from "./AddBranch";
import EditBranch from "./EditBranch";
import { Link } from "react-router-dom";

export default function Branch() {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [nxtPage, setNextPage] = useState(null);
  const [search_value, setSearchValue] = useState(null);
  const [toggal, setToggal] = useState(false);
  const [data_value, setDataValue] = useState(null);

  const [show, setShow] = useState(false);

  const handleShow = (row) => {
    setShow(!show)
    setDataValue(row)
  };

  useEffect(() => {
    all_data('')
  }, []);

  const all_data = (name) => {
    API.get('/v1/oauth/branch?name=' + name).then((response) => {
      dispatch(BRANCH_FETCH_SUCCESS(response.data.data))
      setLoading(false)
    }).catch((error) => {
      setLoading(true)
      BRANCH_FETCH_ERROR();
    });
  }

  const paginate = (pageNumber) => {
    API.get('/v1/oauth/branch?page=' + pageNumber).then((response) => {
      dispatch(BRANCH_FETCH_SUCCESS(response.data.data))
      setLoading(false)
    }).catch((error) => {
      setLoading(true)
      BRANCH_FETCH_ERROR();
    });
  };

  const previousPage = (nxtPage) => {
    setNextPage(nxtPage)
  }

  const nextPage = (nxtPage) => {
    setNextPage(nxtPage)
  }

  const delete_probation = (id) => {

    if (window.confirm("Are you sure want to delete this?")) {
      const responsead = API.delete('/v1/oauth/branch/' + id + '').then((response) => {
        toast.promise(responsead, {
          loading: 'Delating ...',
          success: (response) => {
            dispatch(DELETE_BRANCH(id))
            return `Delete Successfully`;
          },
          error: (e) => `${e.response.data.errors.name ?? e.response.data.message}`,
          duration: 6000,
        })

      }).catch((error) => {
        setLoading(true)
      });
    }

  }



  const selector = useSelector((state) => state.branchReducer)

  return (
    <>
      <div className="page-title-box">
        <div className="page-title-breadcrumb">
          <ol className="breadcrumb m-0">
            <li className="breadcrumb-item"><a href="#">Home</a></li>
            <li className="breadcrumb-item"><a href="#">Settings</a></li>
            <li className="breadcrumb-item active">Branch</li>
          </ol>
        </div>
      </div>
      <div className="card border-0 main-content-card">
        <div className="card-body">
          <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3 main-content-card-header">
            <h4 className="main-card-title mb-0 flex-grow-1">Branch</h4>
            <div className="flex-shrink-0">
              <div className="app-search">
                <div className="input-group">
                  <div className="position-relative">
                    <input onChange={(e) => all_data(e.target.value)} type="text" className="form-control" placeholder="Search..." autoComplete="off" id="search-options" />
                    <span className="fa-light fa-search search-widget-icon" />
                  </div>
                  <Link to={`/settings/branche/create`} className="btn btn-add" type="button" id="button-addon2"><i className="fa-regular fa-plus" /></Link>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Name</th>
                  <th>Latitude</th>
                  <th>Longitude</th>
                  <th>Area</th>
                  <th>Action</th>
                </tr>
                
              </thead>
              <tbody>
                {!selector?.loading && selector?.main_data?.map((row, index) => (
                  <tr>
                    <td>{index+1}</td>
                    <td> {row.name}</td>
                    <td> {row.lat}</td>
                    <td>{row.long}</td>
                    <td> {row.area}</td>
                    <td>
                    <div class="dropdown action-dropdown">
                        <a class="btn btn-sm btn-info py-0" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                          Action <i class="fa-light fa-ellipsis-stroke-vertical"></i>
                        </a>
                        <ul class="dropdown-menu border-1 shadow-sm">
                          <li><Link to={`/settings/branche/${row.id}/edit`} class="dropdown-item" ><i class="fa-light fa-pen me-1" ></i>Edit</Link></li>
                          {row.is_default==0?
                          <li><a class="dropdown-item" href="#" onClick={() => delete_probation(row.id)}><i class="fa-light fa-trash me-1"></i>Delete</a></li>
                        :''}
                        </ul>
                      </div>
                    </td>
                  </tr>
                 ))}
                </tbody>
            </table>
           
            <Paginate nextPage={nextPage} previousPage={previousPage} previousPageUrl={selector?.data.prv_page_url} nextPageUrl={selector?.data.next_page_url} postsPerPage={selector?.data.per_page} paginate={paginate} totalPosts={selector?.data.total_page} hasMorePage={selector?.data.has_more_page} onFirstPage={selector?.data.first_page} hasPage={selector?.data.has_page} currentPage={selector?.data.current_page} />

          </div>
        </div>{/* end card-body */}
      </div>


  


    </>
  )
}
import { useEffect, useState } from "react";
import API from "../../../../Network/API";
import { toast } from "react-hot-toast";

export default function LateSetting() {
  const [isLoading, setLoading] = useState(false);
  const [data_value, setDataValue] = useState(null);
  const [profileInput, setProfileInput] = useState({});
  const [data_sett, setDataSetting] = useState(null);

  const handleChange1 = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setProfileInput({ ...profileInput, [name]: value });
  };

  useEffect(() => {
    setLoading(true)
    API.get('/v1/oauth/late_setting').then((response) => {
      setLoading(false)
      setDataValue(response.data.data)
      console.log(response.data)
    }).catch((error) => {
      setLoading(false)
    });


  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    var data = {
      'late_day': profileInput.late_day == undefined ? data_value?.late_day : profileInput.late_day,
      'deduction_day': profileInput.deduction_day == undefined ? data_value?.deduction_day : profileInput.deduction_day,
      'status_deduction': profileInput.status_deduction == undefined ? data_value?.status_deduction : profileInput.status_deduction,
    }

    const responsea = API.post('/v1/oauth/late_setting/save', data);
    toast.promise(responsea, {
      loading: 'Saving ...',
      success: (response) => {
        setLoading(false);
        if (response.status === 500) throw new Error('server error');
        return `${response.data.message}`;
      },
      error: (e) => `${e.response.data.errors.name ?? e.response.data.message}`,
      duration: 6000,
    })
    setLoading(false)
  }
  return (
    <>
      <div class="page-title-box">
        <div class="page-title-breadcrumb">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active">Late Configuration</li>
          </ol>
        </div>
      </div>
      <form onSubmit={handleSubmit} >


        <div className="align-items-center  pb-2 mb-1">
          <h4 className="main-card-title mb-0"> Late Configuration </h4>
        </div>

        <div className="card border-0 main-content-card">
          <div className="card-body">
            <div className="c-form-area pb-3">
              <div className="row align-items-end mb-3">
                <div className="col-sm-12 col-md-7">
                  <div className="d-flex align-items-end">
                    <div className="flex-shrink-0 form-icon-avatar">
                      <span className="form-icon-title rounded-circle">
                        <i className="fa-solid fa-envelope text-info" />
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <label htmlFor="late_day" className="form-label fs-14 text-secondary mb-1 text-uppercase">For Days*</label>
                      <input defaultValue={data_value?.late_day} type="text" className="form-control rounded-0" placeholder="0" id="late_day" name="late_day" onChange={(e) => handleChange1(e)} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-end mb-3">
                <div className="col-sm-12 col-md-7">
                  <div className="d-flex align-items-end">
                    <div className="flex-shrink-0 form-icon-avatar">
                      <span className="form-icon-title rounded-circle">
                        <i className="fa-solid fa-phone text-success text-info" />
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <label htmlFor="deduction_day" className="form-label fs-14 text-secondary mb-1 text-uppercase">Day of Salary Deduction*</label>
                      <input defaultValue={data_value?.deduction_day} type="text" className="form-control rounded-0" placeholder="0" id="deduction_day" name="deduction_day" onChange={(e) => handleChange1(e)} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-end mb-4">
                <div className="col-sm-12 col-md-7">
                  <div className="d-flex align-items-end">
                    <div className="flex-shrink-0 form-icon-avatar">
                      <span className="form-icon-title rounded-circle">
                        <i class="fa-regular fa-globe text-pink"></i>
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <label htmlFor="status_deduction" className="form-label fs-14 text-secondary mb-1 text-uppercase">Status</label>
                      <select class="form-select rounded-0" id="status_deduction" name="status_deduction" onChange={(e) => handleChange1(e)}>

                        <option value={``}>Choose....</option>
                        <option value={1} selected={data_value?.status_deduction==1?true:false}>Active</option>
                        <option value={2} selected={data_value?.status_deduction==2?true:false}>InActive</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              
              
              <div className="text-start mb-2 ">
                <button type="submit" className="btn btn-success rounded-pill">
                  <i className="fa-light fa-paper-plane-top me-1" />Save Late Configuration
                </button>
              </div>
            </div>

          </div>
        </div>
      </form>
    </>
  )

}
import { useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import API from "../../../Network/API";

export default function ChangePassowrd() {
    const [isLoading, setLoading] = useState(false);
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true)
        const fd = new FormData();
        fd.append(`current_password`, event.target.old_password.value)
        fd.append(`new_password`, event.target.new_password.value)
        fd.append(`confirm_password`, event.target.confirm_password.value)
        API.post('/v1/oauth/change-password', fd)
        .then((response) => {
            setLoading(false)
            toast.success(response.data.message);
            localStorage.clear();
            window.location.href = '/';
        })
        .catch((err) => {
            setLoading(false)
            toast.error(err.response.data.message);
        });

    }
    return (
        <>
            <div className="container-fluid">
                <div className="page-title-box">
                    <div className="page-title-breadcrumb">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item active">Change Password</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-10 offset-md-1">
                        <div className="card border-0 shadow-sm">
                            <div className="card-body">
                                <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3">
                                    <h4 className="main-card-title mb-0 flex-grow-1">
                                        <Link to={`/`} className="text-dark">
                                            <i className="fa-duotone fa-arrow-left me-1" />
                                            Change Password
                                        </Link>
                                    </h4>

                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="row align-items-end mb-3">
                                        <div className="col-sm-12 mb-2">
                                            <div className="d-flex align-items-start">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i className="fa-duotone fa-key text-secondary" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <input type="password" required name="old_password" className="form-control rounded-0" id="exampleFormControlTextarea1" placeholder="Current Password" defaultValue={""} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 mb-2">
                                            <div className="d-flex align-items-start">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i className="fa-duotone fa-key text-secondary" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <input type="password" required name="new_password" className="form-control rounded-0" id="exampleFormControlTextarea1" placeholder="New Password" defaultValue={""} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 mb-2">
                                            <div className="d-flex align-items-start">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i className="fa-duotone fa-key text-secondary" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <input type="password" required name="confirm_password" className="form-control rounded-0" id="exampleFormControlTextarea1" placeholder="Confirm Password" defaultValue={""} />
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="text-end ">
                                            <button type="submit" className="btn btn-success rounded-pill">
                                                Save Password {isLoading ? (
                                                    <span
                                                        className="spinner-border spinner-border-sm ml-5"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                ) : (
                                                    <span></span>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
import { useEffect, useState } from "react";
import API from "../../../../Network/API";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { COMPANY_FETCH_SUCCESS } from "../../../../Reducer/CompanySlice";

export default function CompanyProfile() {
  const [isLoading, setLoading] = useState(false);
  const [data_value, setDataValue] = useState(null);
  const [profileInput, setProfileInput] = useState({});
  const [data_sett, setDataSetting] = useState(null);
  const dispatch=useDispatch();
  const handleChange1 = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setProfileInput({ ...profileInput, [name]: value });
  };

  useEffect(() => {
    setLoading(true)
    API.get('/v1/oauth/company_profile').then((response) => {
      setLoading(false)
      setDataValue(response.data.data)
      dispatch(COMPANY_FETCH_SUCCESS(response.data.data))
    }).catch((error) => {
      setLoading(false)
    });

    API.get('/v1/oauth/setting').then((response) => {
      setLoading(false)
      setDataSetting(response.data.data)
   
    }).catch((error) => {
      setLoading(false)
    });

  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    var data = {
      'c_name': profileInput.c_name == undefined ? data_value?.name : profileInput.c_name,
      'c_email': profileInput.c_email == undefined ? data_value?.email : profileInput.c_email,
      'c_phone': profileInput.c_phone == undefined ? data_value?.phone : profileInput.c_phone,
      'city': profileInput.city == undefined ? data_value?.city : profileInput.city,
      'state': profileInput.state == undefined ? data_value?.state : profileInput.state,
      'zip_code': profileInput.zip_code == undefined ? data_value?.zip_code : profileInput.zip_code,
      'country_id': profileInput.country_id == undefined ? data_value?.country_id : profileInput.country_id,
      'industry_id': profileInput.industry_id == undefined ? data_value?.industry_id : profileInput.industry_id,
      'address': profileInput.address == undefined ? data_value?.address : profileInput.address,
      'currency_code': profileInput.currency_code == undefined ? data_value?.currency_code : profileInput.currency_code,
      'timezone': profileInput.timezone == undefined ? data_value?.timezone : profileInput.timezone,
      '_method':'PUT'
    }

    const responsea = API.post('/v1/oauth/company_profile/' + data_value?.id + '/update', data);
    toast.promise(responsea, {
      loading: 'Saving ...',
      success: (response) => {
        setLoading(false);
        if (response.status === 500) throw new Error('server error');
        return `${response.data.message}`;
      },
      error: (e) => `${e.response.data.errors.name ?? e.response.data.message}`,
      duration: 6000,
    })
    setLoading(false)
  }
  return (
    <>
      <div class="page-title-box">
        <div class="page-title-breadcrumb">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active">General Settings</li>
          </ol>
        </div>
      </div>
      <form onSubmit={handleSubmit} >
        <div className="align-items-center  pb-2 mb-1">
          <h4 className="main-card-title mb-0"> Account Owner <a href="" style={{ fontSize: '12px' }}>Change Owner</a> </h4>
        </div>

        <div className="card border-0 main-content-card mb-2">
          <div className="card-body">
            <div className="c-form-area pb-3">
              <div className="row align-items-end">
                <div className="col-sm-12 col-md-7">
                  <div className="d-flex align-items-end">
                    <div className="flex-shrink-0 form-icon-avatar">
                      <span className="form-icon-title rounded-circle">
                        <i className="fa-solid fa-user text-primary" />
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <label htmlFor="c_name" className="form-label fs-14 text-secondary mb-1 text-uppercase">{data_value?.user_name}</label>
                      <p className="mb-0" style={{ fontSize: '12px' }}>{data_value?.user_email}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="align-items-center  pb-2 mb-1">
          <h4 className="main-card-title mb-0"> General </h4>
        </div>

        <div className="card border-0 main-content-card">
          <div className="card-body">
            <div className="c-form-area pb-3">

              <div className="row align-items-end mb-3">
                <div className="col-sm-12 col-md-7">
                  <div className="d-flex align-items-end">
                    <div className="flex-shrink-0 form-icon-avatar">
                      <span className="form-icon-title rounded-circle">
                        <i className="fa-solid fa-building text-primary" />
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <label htmlFor="c_name" className="form-label fs-14 text-secondary mb-1 text-uppercase">Account Name*</label>
                      <input defaultValue={data_value?.name} type="text" className="form-control rounded-0" placeholder="Enter Name" id="c_name" name="c_name" onChange={(e) => handleChange1(e)} />

                    </div>
                  </div>
                  <p className="mb-0 mt-0 ms-5 ps-1" style={{ fontSize: '10px', color: '#c0c0c0' }}>Company Or Organization name,or whatever you want to name</p>
                </div>
              </div>

              <div className="row align-items-end mb-3">
                <div className="col-sm-12 col-md-7">
                  <div className="d-flex align-items-end">
                    <div className="flex-shrink-0 form-icon-avatar">
                      <span className="form-icon-title rounded-circle">
                        <i className="fa-solid fa-envelope text-info" />
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <label htmlFor="c_email" className="form-label fs-14 text-secondary mb-1 text-uppercase">Email*</label>
                      <input defaultValue={data_value?.email} type="text" className="form-control rounded-0" placeholder="Enter Email" id="c_email" name="c_email" onChange={(e) => handleChange1(e)} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-end mb-3">
                <div className="col-sm-12 col-md-7">
                  <div className="d-flex align-items-end">
                    <div className="flex-shrink-0 form-icon-avatar">
                      <span className="form-icon-title rounded-circle">
                        <i className="fa-solid fa-phone text-success text-info" />
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <label htmlFor="c_phone" className="form-label fs-14 text-secondary mb-1 text-uppercase">Phone Number*</label>
                      <input defaultValue={data_value?.phone} type="text" className="form-control rounded-0" placeholder="Enter Phone" id="c_phone" name="c_phone" onChange={(e) => handleChange1(e)} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-end mb-3">
                <div className="col-sm-12 col-md-7">
                  <div className="d-flex align-items-end">
                    <div className="flex-shrink-0 form-icon-avatar">
                      <span className="form-icon-title rounded-circle">
                        
                        <i class="fa-solid fa-city text-warning"></i>
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <label htmlFor="city" className="form-label fs-14 text-secondary mb-1 text-uppercase">City</label>
                      <input defaultValue={data_value?.city} type="text" className="form-control rounded-0" placeholder="Enter city name" id="city" name="city" onChange={(e) => handleChange1(e)} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-end mb-3">
                <div className="col-sm-12 col-md-7">
                  <div className="d-flex align-items-end">
                    <div className="flex-shrink-0 form-icon-avatar">
                      <span className="form-icon-title rounded-circle">
                      <i class="fa-solid fa-location-dot"></i>
                      
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <label htmlFor="state" className="form-label fs-14 text-secondary mb-1 text-uppercase">State/Province/Region</label>
                      <input defaultValue={data_value?.state} type="text" className="form-control rounded-0" placeholder="Enter state name" id="state" name="state" onChange={(e) => handleChange1(e)} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-end mb-3">
                <div className="col-sm-12 col-md-7">
                  <div className="d-flex align-items-end">
                    <div className="flex-shrink-0 form-icon-avatar">
                      <span className="form-icon-title rounded-circle">
                       
                        <i class="fa-solid fa-envelopes-bulk"></i>
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <label htmlFor="zip_code" className="form-label fs-14 text-secondary mb-1 text-uppercase">Zip/Postal code</label>
                      <input defaultValue={data_value?.zip_code} type="text" className="form-control rounded-0" placeholder="Enter zip code" id="zip_code" name="zip_code" onChange={(e) => handleChange1(e)} />
                    </div>
                  </div>
                </div>
              </div>


              <div className="row align-items-end mb-3">
                <div className="col-sm-12 col-md-7">
                  <div className="d-flex align-items-end">
                    <div className="flex-shrink-0 form-icon-avatar">
                      <span className="form-icon-title rounded-circle">
                        <i class="fa-regular fa-globe text-pink"></i>
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <label htmlFor="country" className="form-label fs-14 text-secondary mb-1 text-uppercase">Country</label>
                      <select class="form-select rounded-0" id="country" name="country_id" onChange={(e) => handleChange1(e)}>

                        {data_sett?.nationality?.map((row, index) => (

                          <>
                            {
                              row.id == data_value?.country_id ?
                                <option value={row.id} selected>{row.name}</option>
                                :
                                <option value={row.id}>{row.name}</option>
                            }
                          </>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-end mb-3">
                <div className="col-sm-12 col-md-7">
                  <div className="d-flex align-items-end">
                    <div className="flex-shrink-0 form-icon-avatar">
                      <span className="form-icon-title rounded-circle">

                        <i class="fa-regular fa-industry text-pink"></i>
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <label htmlFor="industry" className="form-label fs-14 text-secondary mb-1 text-uppercase">Industry</label>
                      <select class="form-select rounded-0" id="industry" name="industry_id" onChange={(e) => handleChange1(e)}>
                        <option>Choose...</option>

                        {data_sett?.industry?.map((row, index) => (

                          <>
                            {
                              row.id == data_value?.industry_id ?
                                <option value={row.id} selected>{row.name}</option>
                                :
                                <option value={row.id}>{row.name}</option>
                            }
                          </>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-end mb-3">
                <div className="col-sm-12 col-md-7">
                  <div className="d-flex align-items-end">
                    <div className="flex-shrink-0 form-icon-avatar">
                      <span className="form-icon-title rounded-circle">
                        <i class="fa-regular fa-dollar-sign text-success"></i>
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <label htmlFor="currency_code" className="form-label fs-14 text-secondary mb-1 text-uppercase">Currency code*</label>
                      <input required defaultValue={data_value?.currency_code} type="text" className="form-control rounded-0" placeholder="Ex: SAR" id="currency_code" name="currency_code" onChange={(e) => handleChange1(e)} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-end mb-3">
                <div className="col-sm-12 col-md-7">
                  <div className="d-flex align-items-end">
                    <div className="flex-shrink-0 form-icon-avatar">
                      <span className="form-icon-title rounded-circle">
                        <i className="fa-regular fa-timer text-warning" />
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <label htmlFor="currency_code" className="form-label fs-14 text-secondary mb-1 text-uppercase">Timezone*</label>
                      <select className="form-select rounded-0" required onChange={(e) => handleChange1(e)} name="timezone">
                        <option></option>
                        {data_sett?.timezone_list?.map((row, index) => (

                          <>
                            {
                              row.name == data_value?.timezone ?
                                <option value={row.name} selected>{row.timezone}</option>
                                :
                                <option value={row.name}>{row.timezone}</option>
                            }
                          </>
                        ))}

                      </select>
                    </div>
                  </div>
                </div>
              </div>



              <hr />
              <div className="row align-items-end mb-3">
                <div className="col-sm-12">
                  <div className="d-flex align-items-start">
                    <div className="flex-shrink-0 form-icon-avatar">
                      <span className="form-icon-title rounded-circle">
                        <i className="fa-regular fa-location-dot text-secondary" />
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <textarea defaultValue={data_value?.address} className="form-control rounded-0" id="exampleFormControlTextarea1" rows={3} placeholder="address" onChange={(e) => handleChange1(e)} name="address" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-end mb-4">
                <button type="submit" className="btn btn-success rounded-pill">
                  <i className="fa-light fa-paper-plane-top me-1" />Update
                </button>
              </div>
            </div>

          </div>
        </div>
      </form>
    </>
  )

}
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import API from "../../../Network/API";
import { toast } from "react-hot-toast";
import BaseUrl from "../../../Network/BaseUrl";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import Select from 'react-select';
export default function AddBusinessTripe() {
    const [isLoading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [loading, setLoad] = useState(false);
    const [categories, setCategory] = useState([]);
    const [routes, setRoute] = useState([]);
    const [methods, setMethod] = useState([]);
    const [demands, setDemand] = useState([]);
    const formRef = useRef();
    const navigate = useNavigate()
    const [file, setFile] = useState(null);
    const [balance, setBalance] = useState(0);

    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
      ]);
    
      const handleSelect = (ranges) => {
        setDateRange([ranges.selection]);
      };
    
      const calculateDays = () => {
        if (dateRange[0].startDate && dateRange[0].endDate) {
          const start = dateRange[0].startDate;
          const end = dateRange[0].endDate;
        //   console.log(new Date(start).getFullYear()+'-'+(new Date(start).getMonth()+1)+'-'+new Date(start).getDate());
        //   console.log(start);
          const timeDifference = end.getTime() - start.getTime();
          const daysCount = Math.ceil(timeDifference / (1000 * 3600 * 24));
          return daysCount;
        }
        return 0;
      };

    const setSelectedFile = (e) => {
        setFile(e.target.files[0])
    };


    useEffect(() => {
        
        setLoad(true)
        API.get(`/v1/oauth/business_tripes/create`).then((response) => {
            setCategory(response.data.data.types)
            setDemand(response.data.data.demands)
            setMethod(response.data.data.methods)
            setRoute(response.data.data.routes)
            setLoad(false)
        }).catch((error) => {
            setLoad(false)
        });
   
    }, []);


    const handleSubmit = (event) => {
        event.preventDefault();
        setSaveLoading(true)
        // Access form data using the formRef

        const fd = new FormData();
        fd.append(`ticket_demand`, event.target.ticket_demand_id.value)
        fd.append(`tripe_type`, event.target.tripe_type_id.value)
        fd.append(`travel_method`, event.target.travel_method.value)
        fd.append(`route`, event.target.route.value)

        
        if (file) {
            fd.append('attachment', file, file?.name)
        }

        fd.append(`tripe_reason`, event.target.tripe_reason.value)
        fd.append(`note`, event.target.note.value)

        BaseUrl.post('/v1/oauth/business_tripes/save', fd)
            .then((response) => {
                setSaveLoading(false)
                toast.success('Request submitted successfully');
                 navigate(-1)
            })
            .catch((err) => {
                setSaveLoading(false)
                toast.error(err.response.data.message);
            });


    }


    return (
        <>
            <div className="page-title-box">
                <div className="page-title-breadcrumb">
                    <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                        <li className="breadcrumb-item"><a href="#">My Requests</a></li>
                        <li className="breadcrumb-item active">Add Business Tripe Request</li>
                    </ol>
                </div>
            </div>

            <div className="row">
                <div className="col-md-11 offset-md-1">
                    <div className="card border-0 main-content-card">
                        <div className="card-body">
                            <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3">
                                <h4 className="main-card-title mb-0 flex-grow-1">
                                    <Link to={`/my_business_tripe`} className="text-dark">
                                        <i className="fa-duotone fa-arrow-left me-1" />
                                        Add Business Tripe Request
                                    </Link>
                                </h4>

                            </div>

                            <div className="c-form-area pb-3">
                                <form onSubmit={handleSubmit} ref={formRef}>

                                    <div className="row">
                                        <div className="col-sm-12 col-md-12 mb-3">
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i class="fa-light fa-list-check text-info"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="ticket_demand_id" className="form-label fs-14 text-secondary mb-1 text-uppercase">Ticket Demand*</label>
                                                    <select  required className="form-select rounded-0" id="ticket_demand_id" name="ticket_demand_id">
                                                        <option value={``} selected>Choose...</option>
                                                        {!loading && demands?.map((row, index) => (
                                                            <option value={row.id}>{row.name}</option>
                                                        ))}
                                                    </select>
                                                    
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <div className="col-sm-12 col-md-12 mb-3">
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i class="fa-light fa-list-check text-info"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="tripe_type_id" className="form-label fs-14 text-secondary mb-1 text-uppercase">Tripe Types*</label>
                                                    <select  required className="form-select rounded-0" id="tripe_type_id" name="tripe_type_id">
                                                        <option value={``} selected>Choose...</option>
                                                        {!loading && categories?.map((row, index) => (
                                                            <option value={row.id}>{row.name}</option>
                                                        ))}
                                                    </select>
                                                    
                                                </div>
                                            </div>
                                            
                                        </div>


                                        <div className="col-sm-12 col-md-12 mb-3">
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i class="fa-light fa-list-check text-info"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="route" className="form-label fs-14 text-secondary mb-1 text-uppercase">Route*</label>
                                                    <select  required className="form-select rounded-0" id="route" name="route">
                                                        <option value={``} selected>Choose...</option>
                                                        {!loading && routes?.map((row, index) => (
                                                            <option value={row.id}>{row.name}</option>
                                                        ))}
                                                    </select>
                                                    
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <div className="col-sm-12 col-md-12 mb-3">
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i class="fa-light fa-list-check text-info"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="travel_method" className="form-label fs-14 text-secondary mb-1 text-uppercase">Travel Method*</label>
                                                    <select  required className="form-select rounded-0" id="travel_method" name="travel_method">
                                                        <option value={``} selected>Choose...</option>
                                                        {!loading && methods?.map((row, index) => (
                                                            <option value={row.id}>{row.name}</option>
                                                        ))}
                                                    </select>
                                                    
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <div className="col-sm-12 col-md-12 mb-3">
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i class="fa-light fa-file text-info"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                   

                                                    <label htmlFor="attachment" className="form-label fs-14 text-secondary mb-1 text-uppercase">Attachment</label>
                                                    <input onChange={(e) => setSelectedFile(e)} type="file" className="form-control rounded-0" placeholder="" id="attachment" name="attachment" />
                                                    
                                                </div>
                                            </div>
                                            
                                        </div>
                                     
                                       
                                

                                    </div>

                                    
                                    <hr />


                                    <div className="row align-items-end mb-3">
                                        <div className="col-sm-12">
                                            <div className="d-flex align-items-start">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i className="fa-duotone fa-message-captions text-secondary" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <textarea name="tripe_reason" className="form-control rounded-0" id="tripe_reason" rows={3} placeholder="Write a tripe reason" defaultValue={""} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row align-items-end mb-3">
                                        <div className="col-sm-12">
                                            <div className="d-flex align-items-start">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i className="fa-duotone fa-message-captions text-secondary" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <textarea name="note" className="form-control rounded-0" id="note" rows={3} placeholder="Write a tripe note" defaultValue={""} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-end mb-4">
                                        <button type="submit" className="btn btn-success rounded-pill">
                                            Submit {saveLoading ? (
                                                <span
                                                    className="spinner-border spinner-border-sm ml-5"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            ) : (
                                                <span></span>
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>

                        </div>{/* end card-body */}
                    </div>
                </div>

            </div>
        </>
    )
}
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import API from "../../../Network/API";
import { toast } from "react-hot-toast";

export default function AddEmployeePeformance() {
    const [isLoading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [loading, setLoad] = useState(false);
    const [user, setUser] = useState();
    const [months, setMonth] = useState([]);
    const [criteria, setCriteria] = useState([]);
    const dispatch = useDispatch();
    const prams = useParams();
    const [inputData, setInputData] = useState([]);
    const formRef = useRef();
    const inputRef = useRef();
    const navigate=useNavigate()
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;

        // Create a copy of the inputData array
        const newData = [...inputData];
        newData[index] = { ...newData[index], [name]: value };

        setInputData(newData);
    };

    useEffect(() => {
        setLoading(true)
        setLoad(true)
        API.get(`/v1/oauth/users/${prams.id}/view`).then((response) => {
            setUser(response.data.data.employee)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
        });

        API.get(`/v1/oauth/employee_performance/create`).then((response) => {
            setMonth(response.data.data.month_list)
            setCriteria(response.data.data.types)
            setLoad(false)
        }).catch((error) => {
            setLoad(false)
        });

    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        setSaveLoading(true)
        // Access form data using the formRef
        const formData = new FormData(formRef.current);
        const inputData1 = Array.from(formData.entries());
        const fd = new FormData();
        // Use the received data as needed
        const formElements = formRef.current.elements;
        // const inputValue = formElements['rating'][0].value;
        // console.log(inputValue);
        // //  inputData.map((rw, k) => {
        // //     fd.append(`performance[${k}][rating]`, rw.rating)
        // // })

        criteria?.map((rw, k) => {
            fd.append(`criteria[${k}][criteri_id]`, formElements['criteri_id'][k].value)
            fd.append(`criteria[${k}][rating]`, formElements['rating'][k].value)
        })


        
        fd.append(`year`, event.target.year.value)
        fd.append(`month`, event.target.month.value)
        fd.append(`remark`, event.target.remark.value)
        fd.append(`user_id`, user.id)

        API.post('/v1/oauth/employee_performance/save', fd)
        .then((response) => {
            setSaveLoading(false)
            toast.success(response.data.message);
            navigate(-1)
        })
        .catch((err) => {
            setSaveLoading(false)
            toast.error(err.response.data.message);
        });


    }
    return (
        <>
            <div className="page-title-box">
                <div className="page-title-breadcrumb">
                    <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                        <li className="breadcrumb-item"><a href="#">Employee</a></li>
                        <li className="breadcrumb-item active">Add Performance</li>
                    </ol>
                </div>
            </div>
            <div className="row">
                <div className="col-md-10 offset-1">
                    <div className="card border-0 main-content-card">
                        <div className="card-body">
                            <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3">
                                <h4 className="main-card-title mb-0 flex-grow-1">
                                    <Link to={()=>navigate(-1)} className="text-dark">
                                        <i className="fa-duotone fa-arrow-left me-1" />
                                        Add Performance
                                    </Link>
                                </h4>
                                <div className="flex-shrink-0 text-end">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-2">
                                            <img src={user?.image} alt="" className="avatar-xs rounded-circle" />
                                        </div>
                                        <div className="flex-grow-1">
                                            {user?.name}
                                            <br />
                                            <span className="text-muted" style={{ fontSize: '11px' }}>{user?.employee_id}</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            
                                <div className="c-form-area pb-3">
                                <form onSubmit={handleSubmit} ref={formRef}>
                                    <div className="row align-items-end mb-3">
                                        <div className="col-sm-6 col-md-7">
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i class="fa-thin fa-calendar-days text-primary"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="year" className="form-label fs-14 text-secondary mb-1 text-uppercase">Year*</label>
                                                    <input required type="text" className="form-control rounded-0" placeholder="2023" id="year" name="year" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row align-items-end mb-3">
                                        <div className="col-sm-6 col-md-7">
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i class="fa-light fa-calendar-week text-info"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="month" className="form-label fs-14 text-secondary mb-1 text-uppercase">Month*</label>
                                                    <select required className="form-select rounded-0" id="month" name="month">
                                                        <option value={``} selected>Choose...</option>
                                                        {!loading && months?.map((row, index) => (
                                                            <option value={row.id}>{row.full_name}</option>
                                                        ))}


                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    
                                    <div className="row align-items-end mb-3">
                                        <h6>Performance Criteria</h6>
                                        <div className="col-sm-12">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Performance Category</th>
                                                        <th>Performance Criteria</th>
                                                        <th>Rating(out of 5)</th>
                                                    </tr>
                                                </thead>
                                                <tbody ref={inputRef}>
                                                
                                                    {!loading && criteria?.map((row, index) => (
                                                        <tr>
                                                            <td>{row.type_name}</td>
                                                            <td>
                                                                {row.name}
                                                                <input  onChange={(e) => handleInputChange(e, index)} type="hidden" name={`criteri_id`} value={row.id} />
                                                            </td>
                                                            <td>
                                                                <div class="input-group">
                                                                    <span class="input-group-text"><i className="fa-light fa-star text-warning"></i></span>
                                                                    <input  onChange={(e) => handleInputChange(e, index)} required defaultValue={0} type="number" min={0} max={5} class="form-control" placeholder="" name={`rating`} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row align-items-end mb-3">
                                        <div className="col-sm-12">
                                            <div className="d-flex align-items-start">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i className="fa-duotone fa-message-captions text-secondary" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <textarea name="remark" className="form-control rounded-0" id="exampleFormControlTextarea1" rows={3} placeholder="Write a message" defaultValue={""} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-end mb-4">
                                        <button type="submit" className="btn btn-success rounded-pill">
                                            Submit {saveLoading ? (
                                                <span
                                                    className="spinner-border spinner-border-sm ml-5"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            ) : (
                                                <span></span>
                                            )}
                                        </button>
                                    </div>
                                    </form>
                                </div>
                            
                        </div>{/* end card-body */}
                    </div>
                </div>

            </div>

        </>
    )
}
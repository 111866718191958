import { createSlice } from '@reduxjs/toolkit';
export const degreeSlice = createSlice({
    name:'degree',
    initialState:{
        loading: false,
        data: {

        },
        main_data:[],
        error: false,
    },
    reducers:{
        DEGREE_FETCH_START:(state)=>{
            state.loading=true
        },
        DEGREE_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
            state.main_data=action.payload.data
        },
        ADD_DEGREE_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.main_data = [action.payload,...state.main_data]
        },
        DEGREE_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const { DEGREE_FETCH_START,DEGREE_FETCH_SUCCESS,DEGREE_FETCH_ERROR,ADD_DEGREE_FETCH_SUCCESS }=degreeSlice.actions;
export default degreeSlice.reducer;
import API from "../Network/API"
import { USER_LOGGED_IN } from '../Reducer/authSlice';
import toast from 'react-hot-toast';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
export default function Login(){
    const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    if (typeof email !== "undefined") {
      if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/)) {
        toast.error('Email is not correct!');
        return false;
      }
    }
    var data = {
      'email': email,
      'password': password,
    }
    // headers: { 'content-type': 'application/x-www-form-urlencoded' }
    API.post('/v1/oauth/token', data).then(response => {
      setLoading(false);
      if (response.data.success === true) {
        localStorage.setItem(
          'alif_auth',
          JSON.stringify({
            user: response?.data?.data?.user,
            accessToken: response?.data?.data?.token,
          })
        )
        localStorage.setItem("alif_token",response?.data?.data?.token)
        const usr=response?.data?.data?.user;
        if(usr.role_id==1){
          window.location.href='/dashboard';
        }else{
          window.location.href='/home';
        }
       

        // dispatch(
        //   USER_LOGGED_IN({
        //     user: response?.data?.data?.user,
        //     accessToken: response?.data?.data?.token,
        //   })
        // )
        toast.success(response.message, { duration: 3000 })
      } else {

      }
    }).catch((error) => {
      setLoading(false);
      if (error.error.status === "FETCH_ERROR") {
        toast.error(error.error.error)
      } else if (error.error.data.status === 404) {
        toast.error(error.error.data.data.message)
      } else {
        toast.error("Something went wrong")
      }
    });

  }

    return (
        <>
        <div className="login-container">
        <div className="login-inner">
          <div className="login-two-pane">
            <div>
              <div className="login-form">
                <div>
                  <h4>Login into account</h4>
                  <p className="mb-10">Use your credentials to access your account.</p>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa-light fa-envelope" />
                        </span>
                      </div>
                      <input required className="form-control" name="email" type="email" placeholder="Email Address" onChange={(e) => setEmail(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group password-field">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa-light fa-lock-keyhole" />
                        </span>
                      </div>
                      <input onChange={(e) => setPassword(e.target.value)} required className="form-control" name="password" type="password" placeholder="Password" />
                    </div>
                  </div>
                  <div className="row mt-4 mb-4">
                    
                    <div className="col-12 ">
                      <a href="#" className="d-block color-green">Forgot password?</a>
                    </div>
                  </div>
                  <div>
                    <button type="submit" className="btn btn-success btn-block w-100">Login {isLoading ? (
                                                    <span
                                                        className="spinner-border spinner-border-sm ml-5"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                ) : (
                                                    <span></span>
                                                )}</button>
                  </div>
                  
                </form>
              </div>
            </div>
            <div className="right-pane">
              <div className="text-center" style={{width: '300px'}}>
                <div className="mb-10 forny-logo">
                  <img src="/assets/image/main-logo.png" alt="tigerhr"/>
                </div>
                <div className="mt-10">
          
                  <img src="/assets/image/left-bg.gif" alt="" className="img-fluid" />
                  <p style={{fontSize:'1.1rem'}}>
                     HR software that helps you transform your human resource processes
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </>
    );
}
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import API from "../../../Network/API";
import Paginate from "../../../services/Paginate";
import { MY_PAYSLIP_FETCH_SUCCESS } from "../../../Reducer/MyPayslipSlice";

export default function MyPayslip() {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [nxtPage, setNextPage] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    all_data()
  }, []);

  const all_data = () => {
    API.get('/v1/oauth/my_payroll').then((response) => {
      dispatch(MY_PAYSLIP_FETCH_SUCCESS(response.data.data))
      setLoading(false)
    }).catch((error) => {
      setLoading(true)
     
    });
  }
  const paginate = (pageNumber) => {
    API.get('/v1/oauth/my_payroll?page='+pageNumber).then((response) => {
      dispatch(MY_PAYSLIP_FETCH_SUCCESS(response.data.data))
      setLoading(false)
    }).catch((error) => {
      setLoading(true)
     
    });
  };

  const previousPage = (nxtPage) => {
    setNextPage(nxtPage)
  }

  const nextPage = (nxtPage) => {
    setNextPage(nxtPage)
  }

  const selector = useSelector((state) => state.myPayslipReducer)
 
 
  return (
    <>
    <div className="container-fluid">
      <div className="page-title-box">
        <div className="page-title-breadcrumb">
          <ol className="breadcrumb m-0">
            <li className="breadcrumb-item"><a href="#">Home</a></li>
            <li className="breadcrumb-item active">{t('payslip')}</li>
          </ol>
        </div>
      </div>
      <div className="row align-items-center py-2">
        <div className="col-sm">
          <div className="d-flex align-items-center">
            <h5 className="card-title mb-0 me-3">{t('payslip')}</h5>
            
          </div>
        </div>
        <div className="col-sm-auto">
          <div className="d-flex gap-1 flex-wrap">
            <button type="button" className="btn bg-white shadow-sm">
              <i className="fa-light fa-download" />
            </button>
          
            
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card border-0 shadow-sm">
            <div className="table-responsive table-card">
              <table className="table text-center table-hover table-centered align-middle table-nowrap mb-0">
                <thead className="text-muted table-main-bg">
                  <tr>
                    
                    <th scope="col">{t("date")}</th>
                    <th scope="col">{t("Month")}</th>
                    <th scope="col">{t("Payment Type")}</th>
                    <th scope="col">{t("Payable")}</th>
                    <th scope="col">{t("Total Addition")}</th>
                    <th scope="col">{t("Total Deduction")}</th>
                    <th scope="col">{t('sstatus')}</th>
                    <th scope="col">{t('action')}</th>
                  
                  </tr>
                </thead>
                <tbody>
                {!selector?.loading && selector?.main_data?.map((row, index) => (
                  <tr>
                   

                    <td>
                    {row.date}
                    </td>
                  
                   
                    <td>
                      <h5 className="fs-14 my-1">
                        <a href="#" className="text-reset">{row.month}-{row.year} </a>
                      </h5>
                   </td>
                    <td>
                    <h5 className="fs-14 my-1">
                        <a href="#" className="text-reset">{row.pay_type} </a>
                      </h5>
                    </td>
                    <td>
                    <h5 className="fs-14 my-1">
                        <a href="#" className="text-reset">{row.total_pay} </a>
                      </h5>
                    </td>
                 
                    <td>
                    <h5 className="fs-14 my-1">
                        <a href="#" className="text-reset">{row.total_addition} </a>
                      </h5>
                    </td>
                    <td>
                    <h5 className="fs-14 my-1">
                        <a href="#" className="text-reset">{row.total_deduction} </a>
                      </h5>
                    </td>
                    <td>
                    <span className="badge bg-success">Paid</span>
                    </td>

                    <td>
                    <a href="#" className="me-2"><i class="fa-solid fa-download"></i></a> 
                    <a href="#"><i class="fa-solid fa-file-pdf"></i></a>
                    </td>

                  </tr>
                 ))}
                </tbody>
              </table>
            </div>
            <Paginate nextPage={nextPage} previousPage={previousPage} previousPageUrl={selector?.data.prv_page_url} nextPageUrl={selector?.data.next_page_url} postsPerPage={selector?.data.per_page} paginate={paginate} totalPosts={selector?.data.total_page} hasMorePage={selector?.data.has_more_page} onFirstPage={selector?.data.first_page} hasPage={selector?.data.has_page} currentPage={selector?.data.current_page} />

          </div>
        </div>
      </div>

      </div>
    </>
  )
}
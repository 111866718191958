
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

export default function ReportNav(){
    const location = useLocation();
    const { t } = useTranslation();
    return(
        <>
        <div className="page-sidebar">
              <div className="page-sidebar-content">
                <span className="page-sidebar-title">{t("Report")}</span>
                <Link to={`#`} className={`page-sidebar-heading ${location.pathname=='/settings/nationalities'?'active':''}`}>{t("Attendance Report")}</Link>
                <Link to={`#`} className={`page-sidebar-heading ${location.pathname=='/settings/nationalities'?'active':''}`}>{t("Leave Report")}</Link>
                <Link to={`#`} className={`page-sidebar-heading ${location.pathname=='/settings/nationalities'?'active':''}`}>{t("Overtime Report")}</Link>
                <Link to={`#`} className={`page-sidebar-heading ${location.pathname=='/settings/nationalities'?'active':''}`}>{t("Salary Sheet Report")}</Link>
              </div>
              <hr className="m-0" />
              
            </div>
        
        </>
    )
}
import { createSlice } from '@reduxjs/toolkit';
export const relationshipSlice = createSlice({
    name:'relationship',
    initialState:{
        loading: false,
        data: {

        },
        main_data:[],
        error: false,
    },
    reducers:{
        RELATIONSHIP_FETCH_START:(state)=>{
            state.loading=true
        },
        RELATIONSHIP_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
            state.main_data=action.payload.data
        },
        ADD_RELATIONSHIP_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.main_data = [action.payload,...state.main_data]
        },
        RELATIONSHIP_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const { RELATIONSHIP_FETCH_START,RELATIONSHIP_FETCH_SUCCESS,RELATIONSHIP_FETCH_ERROR,ADD_RELATIONSHIP_FETCH_SUCCESS }=relationshipSlice.actions;
export default relationshipSlice.reducer;
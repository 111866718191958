

import { createSlice } from '@reduxjs/toolkit';

export const permissionSlice = createSlice({
    name: 'permission',
    initialState: {
        loading: false,
        data: {

        },
        main_data: [
            "All Request",
            "Approvals",
            "Attendance",
            "Attendance Report",
            "Document",
            "Employee",
            "Gosi",
            "Integration",
            "Leave Management",
            "Leave Report",
            "Leave Request",
            "Leave Types",
            "Loan Request",
            "Manage Bonus",
            "Manage Deduction",
            "My Approval",
            "My Request",
            "My Timesheet",
            "Overtime Request",
            "Payroll",
            "Payroll Report",
            "Public Holiday",
            "Reports",
            "Salary Sheet",
            "Settings",
            "Weekly Holidays"
        ],
        error: false,
    },
    reducers: {
        PERMISSION_FETCH_START: (state) => {
            state.loading = true
        },
        PERMISSION_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.main_data = action.payload
        },
        ADD_PERMISSION_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.main_data = [action.payload, ...state.main_data]
        },
        PERMISSION_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { PERMISSION_FETCH_START, PERMISSION_FETCH_SUCCESS, PERMISSION_FETCH_ERROR, ADD_PERMISSION_FETCH_SUCCESS } = permissionSlice.actions;
export default permissionSlice.reducer;
export default function CheckInCheckoutReport(props){
    return (
        <>
        <div className="table-responsive">
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Employee ID</th>
                        <th>Employee Name</th>
                        <th>Designation</th>
                        <th className="text-center">Check In</th>
                        <th className="text-center">Check Out</th>
                        <th className="text-center">Total Hours</th>
                        <th className="text-center">Late Hours</th>
                        <th className="text-center">Overtime Hours</th>
                        <th className="text-center">Break Hours</th>
                        <th className="text-center">Shift</th>
                    </tr>
                </thead>
                <tbody>

                    {props?.data?.attendances.length>0 && props?.data?.attendances.map((row,index)=>(

                        <tr>
                            <td>{row.employee_id}</td>
                            <td>
                            <div className="d-flex align-items-center">
                                                        <div className="flex-shrink-0 me-2">
                                                            <img src={row.image} alt="" className="avatar-xs rounded-circle" />
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            {row.name}
                                                           
                                                        </div>

                                                    </div>
                            </td>
                            <td>  {row.designation}</td>
                            <td className="text-center">{row.check_in}</td>
                            <td className="text-center">{row.check_out}</td>
                            <td className="text-center">{row.total_hour}</td>
                            <td className="text-center">{row.total_late}</td>
                            <td className="text-center">{row.total_overtime}</td>
                            <td className="text-center">{row.break_time}</td>
                            <td className="text-center">{row.shift_name}</td>
                        </tr>
                    ))}
                    
                </tbody>
            </table>
        </div>
        </>
    )
}
import { useEffect, useState } from "react";
import AddIntegration from "./AddIntegration";
import API from "../../../../Network/API";

export default function IntegrationIndex() {
    const [isLoading, setLoading] = useState(false);
    const [data_value, setDataValue] = useState(null);
    const [data, setData] = useState(null);

    const [show, setShow] = useState(false);
  
    const handleShow = (row) => {
      setShow(!show)
      setDataValue(row)
    };

    useEffect(() => {
        API.get('/v1/oauth/company_profile').then((response) => {
            setLoading(false)
            setData(response.data.data)
          
        }).catch((error) => {
            setLoading(false)
        });
      }, []);
    

    return (
        <>
            <div className="page-title-box">
                <div className="page-title-breadcrumb">
                    <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                        <li className="breadcrumb-item"><a href="#">Settings</a></li>
                        <li className="breadcrumb-item">Administration</li>
                        <li className="breadcrumb-item active">Integrations</li>
                    </ol>
                </div>
            </div>

            <div className="card border-0 main-content-card">
                <div className="card-body">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Note</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>GOSI</td>
                                <td>Register and Get Access to GOSI Online Account</td>
                                <td>
                                    <div class="form-check form-switch">
                                        <input checked={data?.enable_gosi==1?true:false} class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" onChange={()=>handleShow(data)} />
                                        <label class="form-check-label" for="flexSwitchCheckDefault">Enable</label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <AddIntegration show={show} onHide={handleShow} row_data={data_value}/>
                </div>
            </div>
        </>
    )
}
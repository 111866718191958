import { createSlice } from '@reduxjs/toolkit';

export const notifySlice = createSlice({
    name:'notify',
    initialState:{
        loading: false,
        data: {},
        error: false,
    },
    reducers:{
        Notify_FETCH_START:(state)=>{
            state.loading=true
        },
        Notify_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
        },
        Notify_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const { Notify_FETCH_START,Notify_FETCH_ERROR,Notify_FETCH_SUCCESS }=notifySlice.actions;
export default notifySlice.reducer;
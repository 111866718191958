import { useDispatch, useSelector } from "react-redux";
import API from "../../../../Network/API";
import { useEffect, useState } from "react";

import { toast } from "react-hot-toast";
import AddEventApproval from "./AddEventApproval";
import EditEventApproval from "./EditEventApproval";
import { Link, useNavigate, useParams } from "react-router-dom";
import { EVENT_USERS_FETCH_SUCCESS } from "../../../../Reducer/EventApprovalUserSlice";


export default function EventApprovalView() {
    const [isLoading, setLoading] = useState(false);
    const dispatch=useDispatch()
    const [data_value, setDataValue] = useState(null);
    const [category, setCategory] = useState(null);
    const { id } = useParams();
    const [show, setShow] = useState(false);
    const [show_edit, setShowEdit] = useState(false);

    const handleShow = (row) => {
        setShow(!show)
        setDataValue(row)
    };

    const handleShowEdit = (row) => {
        setShowEdit(!show_edit)
        setDataValue(row)
    };


    useEffect(() => {
        API.get('/v1/oauth/event_approvals/' + id + '/view').then((response) => {
            setLoading(false)
            setCategory(response.data.data.category)
            dispatch(EVENT_USERS_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
            setLoading(true)
        });

    }, [id]);

    const selector = useSelector((state) => state.eventApprovalUserReducer)

    return (
        <>
            <div className="container-fluid">
                <div className="page-title-box">
                    <div className="page-title-breadcrumb">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item"><Link to={`/home`}>Home</Link></li>
                            <li className="breadcrumb-item"><Link to={`/approvals`}>Approvals</Link></li>
                            <li className="breadcrumb-item active">{category?.name}</li>
                        </ol>
                    </div>
                </div>
                <div className="card border-0 main-content-card">
                    <div className="card-body">
                        <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3 main-content-card-header">
                            <h4 className="main-card-title mb-0 flex-grow-1"><Link to={`/approvals`} class="text-dark">
                                <i class="fa-duotone fa-arrow-left me-1"></i></Link> Approvals List ({category?.name})</h4>
                            <div className="flex-shrink-0">
                                <div className="app-search">
                                    <div className="input-group">
                                        <button className="btn btn-add btn-success text-white" type="button" onClick={() => handleShow(category)}><i className="fa-regular fa-plus" /> Add Approval</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="live-preview table-responsive">
                            <table className="table table-hover table-centered align-middle table-nowrap mb-0 text-center">
                                <thead>
                                    <tr>
                                        <th>SL</th>
                                        <th>Employee Name</th>
                                        <th>Designation</th>
                                        <th>Priority</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selector?.main_data?.map((user, index) => (
                                        <tr>
                                            <td>{index}</td>
                                            <td width={`15%`}>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 me-2">
                                                        <img src="assets/image/avatar-1.jpg" alt="" className="avatar-xs rounded-circle" />
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        {user.user_name}
                                                        <br />
                                                        <span className="text-muted" style={{ fontSize: '11px' }}>{user.employee_id}</span>
                                                    </div>

                                                </div>


                                            </td>
                                            <td>{user.desig_name}</td>
                                            <td>{user.priority}</td>
                                            <td>
                                                <div class="dropdown action-dropdown">
                                                    <a class="btn btn-sm  py-0" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="fa-light fa-ellipsis-stroke-vertical"></i>
                                                    </a>
                                                    <ul class="dropdown-menu border-1 shadow-sm">
                                                        <li><a class="dropdown-item" href="#" onClick={()=>handleShowEdit(user)}><i class="fa-light fa-plus me-1" ></i>Edit priority</a></li>
                                                        <li><a class="dropdown-item" href="#"><i class="fa-light fa-eye me-1"></i>Delete</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>


                        </div>
                    </div>{/* end card-body */}
                </div>
            </div>

            <AddEventApproval show={show} onHide={handleShow} row_data={data_value} />
            <EditEventApproval show={show_edit} onHide={handleShowEdit} row_data={data_value} />

        </>
    )
}
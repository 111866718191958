import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import API from "../../../Network/API";
import Paginate from "../../../services/Paginate";
import { useTranslation } from "react-i18next";
import { LOAN_REQUEST_FETCH_ERROR, LOAN_REQUEST_FETCH_SUCCESS } from "../../../Reducer/LoanRequestSlice";

export default function LoanRequest() {
    const com_selector = useSelector((state) => state.companyReducer)
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [nxtPage, setNextPage] = useState(null);
    const { t } = useTranslation();
    useEffect(() => {
        all_data()
    }, []);

    const all_data = () => {
        API.get('/v1/oauth/loan_requests').then((response) => {
            dispatch(LOAN_REQUEST_FETCH_SUCCESS(response.data.data))
            setLoading(false)
        }).catch((error) => {
            setLoading(true)
            LOAN_REQUEST_FETCH_ERROR();
        });
    }
    const paginate = (pageNumber) => {
        API.get('/v1/oauth/loan_requests?page=' + pageNumber).then((response) => {
            dispatch(LOAN_REQUEST_FETCH_SUCCESS(response.data.data))
            setLoading(false)
        }).catch((error) => {
            setLoading(true)
            LOAN_REQUEST_FETCH_ERROR();
        });
    };

    const previousPage = (nxtPage) => {
        setNextPage(nxtPage)
    }

    const nextPage = (nxtPage) => {
        setNextPage(nxtPage)
    }

    const selector = useSelector((state) => state.loanRequestReducer)


    return (
        <>
            <div className="container-fluid">
                <div className="page-title-box">
                    <div className="page-title-breadcrumb">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item active">{t('Loan Requests')}</li>
                        </ol>
                    </div>
                </div>
                <div className="row align-items-center py-2">
                    <div className="col-sm">
                        <div className="d-flex align-items-center">
                            <h5 className="card-title mb-0 me-3">{t('Loan Requests')}</h5>

                        </div>
                    </div>
                    <div className="col-sm-auto">
                        <div className="d-flex gap-1 flex-wrap">
                            <button type="button" className="btn bg-white shadow-sm">
                                <i className="fa-light fa-download" />
                            </button>

                            <Link to={`/loan_request/create`} className="btn bg-white shadow-sm">
                                <i className="fa-light fa-plus" /> Add New
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card border-0 shadow-sm">
                            <div className="table-responsive table-card">
                                <table className="table text-center table-hover table-centered align-middle table-nowrap mb-0">
                                    <thead className="text-muted table-main-bg">
                                        <tr>
                                            <th scope="col">{t("Loan Type")}</th>

                                            <th scope="col">{t("Amount")}</th>
                                            <th scope="col">{t("Duration")}</th>
                                            <th scope="col">{t("Installment")}</th>
                                            <th scope="col">{t('sstatus')}</th>
                                            <th scope="col">{t('action')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!selector?.loading && selector?.main_data?.map((row, index) => (
                                            <tr>


                                                <td>
                                                    <h5 className="fs-14 my-1">
                                                        <a href="#" className="text-reset">{row.loan_type_name} </a>
                                                    </h5>
                                                </td>

                                                <td>
                                                    <h5 className="fs-14 my-1">
                                                        <a href="#" className="text-reset">{com_selector?.data?.currency_code} {row.amount} </a>
                                                    </h5>
                                                </td>

                                                <td>
                                                    <h5 className="fs-14 my-1">
                                                        <a href="#" className="text-reset">{row.duration} Months</a>
                                                    </h5>
                                                </td>
                                                <td>
                                                    <h5 className="fs-14 my-1">
                                                        <a href="#" className="text-reset">{com_selector?.data?.currency_code} {row.monthly_installment} </a>
                                                    </h5>
                                                </td>
                                                <td>
                                                    {row.status == 1 ? <span className="badge bg-success">Approved</span> : row.status == 2 ? <span className="badge bg-success">Rejected</span> : <span className="badge bg-warning">Pending</span>}
                                                </td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn btn-light btn-sm dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i className="fa-light fa-ellipsis" />
                                                        </button>
                                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow-sm fs-14" style={{}}>
                                                            {row.status == 2 ?
                                                                <li>
                                                                    <Link to={`/loan_request/${row.id}/edit`} className="dropdown-item">
                                                                        <i className="fa-light fa-edit me-2 text-muted" /> Edit
                                                                    </Link>
                                                                </li> : ''
                                                            }

                                                            <li>
                                                                <Link to={`/loan_request/${row.id}/view`} className="dropdown-item">
                                                                    <i className="fa-light fa-eye me-2 text-muted" /> View
                                                                </Link>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <Paginate nextPage={nextPage} previousPage={previousPage} previousPageUrl={selector?.data.prv_page_url} nextPageUrl={selector?.data.next_page_url} postsPerPage={selector?.data.per_page} paginate={paginate} totalPosts={selector?.data.total_page} hasMorePage={selector?.data.has_more_page} onFirstPage={selector?.data.first_page} hasPage={selector?.data.has_page} currentPage={selector?.data.current_page} />

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
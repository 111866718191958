import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const exportTableToPdf = (tableId, fileName) => {
  const table = document.getElementById(tableId);
  
  html2canvas(table).then((canvas) => {
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgWidth = 210;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    
    pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
    pdf.save(fileName);
  });
};

export default exportTableToPdf;

import { useEffect, useRef, useState } from "react";
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from "react-redux";
import API from "../../../../Network/API";
import Modal from 'react-bootstrap/Modal';
import { EDIT_TAX_SETUP_FETCH_SUCCESS } from "../../../../Reducer/TaxSetupSlice";

export default function EditTaxSetup({show,onHide,row_data}) {
    const [name, setName] = useState("");
    const [percent, setPercent] = useState("");
    const [start_salary, setStartSalary] = useState("");
    const [end_salary, setEndSalary] = useState("");

    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const inpRef = useRef()
    const modalRef1 = useRef()

    const [profileInput, setProfileInput] = useState({});

    const handleChange1 = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setProfileInput({ ...profileInput, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
       
        var data = {
            'name': event.target.title.value,
            'percent': event.target.percent.value,
            'start_amount': 0,
            'end_amount': 0,
            '_method':'PUT'
        }
        const responsea = API.post('/v1/oauth/tax_setups/'+row_data.id+'/update', data);
        toast.promise(responsea, {
            loading: 'Saving ...',
            success: (response) => {
                setLoading(false);
                modalRef1.current.click();
                document.getElementById("add_country_form").reset()
                dispatch(EDIT_TAX_SETUP_FETCH_SUCCESS(response.data.data))
                if (response.status === 500) throw new Error('server error');
                return `${response.data.message}`;
            },
            error: (e) => `${e.response.data.errors.name ?? e.response.data.message}`,
            duration: 6000,
        })
        setLoading(false)
    }

    return (
        <>
            <Modal show={show} onHide={onHide}>
                <Modal.Header>
                    <h4 class="modal-title">Edit Insurance/Income Tax Rule</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" ref={modalRef1} onClick={onHide}></button>

                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={handleSubmit} id="add_country_form">
                        <div className="row">
                            <div className="col-sm-12 mb-1">
                                <label htmlFor="name" className="form-label">Title</label>
                                <input name="title" defaultValue={row_data?.title} ref={inpRef} onChange={(e)=>handleChange1(e)} type="text" className="form-control rounded-pill" id="name" placeholder required />
                            </div>
                            {/* <div className="col-sm-12 mb-1">
                                        <label htmlFor="name" className="form-label ">Amount</label>
                                        <input name="start_amount"  defaultValue={row_data?.start_amount}  onChange={(e)=>handleChange1(e)} type="text" className="form-control rounded-pill" id="name" placeholder required />
                                    </div> */}
                                
                                    <div className="col-sm-12 mb-3">
                                        <label htmlFor="name" className="form-label ">Percent(%)</label>
                                        <input name="percent" defaultValue={row_data?.percent}  onChange={(e)=>handleChange1(e)} type="text" className="form-control rounded-pill" id="name" placeholder required />
                                    </div>
                        </div>

                        <div class="modal-footer">
                            <button type="submit" class="btn btn-success">Submit {isLoading ? (
                                <span
                                    className="spinner-border spinner-border-sm ml-5"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            ) : (
                                <span></span>
                            )}</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}


import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        loading: false,
        data: {

        },
        main_data: [],
        error: false,
    },
    reducers: {
        USER_FETCH_START: (state) => {
            state.loading = true
        },
        USER_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.main_data = action.payload.data
        },
        ADD_USER_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.main_data = [action.payload, ...state.main_data]
        },
        USER_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { USER_FETCH_START, USER_FETCH_SUCCESS, USER_FETCH_ERROR, ADD_USER_FETCH_SUCCESS } = userSlice.actions;
export default userSlice.reducer;
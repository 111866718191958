import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import API from "../../../Network/API";
import { toast } from "react-hot-toast";
export default function AddPromotion() {
    const [isLoading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [loading, setLoad] = useState(false);
    const [user, setUser] = useState();
    const [months, setMonth] = useState([]);
    const [departments, setDepartment] = useState([]);
    const [designations, setDesignation] = useState([]);
    const dispatch = useDispatch();
    const prams = useParams();
    const [inputData, setInputData] = useState([]);
    const formRef = useRef();
    const inputRef = useRef();
    const navigate = useNavigate()
    useEffect(() => {
        setLoading(true)
        setLoad(true)
        API.get(`/v1/oauth/users/${prams.id}/view`).then((response) => {
            setUser(response.data.data.employee)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
        });
        API.get(`/v1/oauth/employee_promotion/create`).then((response) => {
            setDepartment(response.data.data.department)
            setDesignation(response.data.data.designation)
            setLoad(false)
        }).catch((error) => {
            setLoad(false)
        });



    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        setSaveLoading(true)
        // Access form data using the formRef

        const fd = new FormData();
        fd.append(`department_id`, event.target.department_id.value)
        fd.append(`designation_id`, event.target.designation_id.value)
        fd.append(`promotion_salary`, event.target.salary.value)
        fd.append(`date`, event.target.date.value)
        fd.append(`remark`, event.target.remark.value)
        fd.append(`user_id`, user.id)

        API.post('/v1/oauth/employee_promotion/save', fd)
            .then((response) => {
                setSaveLoading(false)
                toast.success(response.data.message);
                navigate(-1)
            })
            .catch((err) => {
                setSaveLoading(false)
                toast.error(err.response.data.message);
            });


    }
    return (
        <>
            <div className="page-title-box">
                <div className="page-title-breadcrumb">
                    <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                        <li className="breadcrumb-item"><Link to={'/employee'}>Employee</Link></li>
                        <li className="breadcrumb-item active">Add Promotion</li>
                    </ol>
                </div>
            </div>

            <div className="row">
                <div className="col-md-10 offset-1">
                    <div className="card border-0 main-content-card">
                        <div className="card-body">
                            <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3">
                                <h4 className="main-card-title mb-0 flex-grow-1">
                                    <Link to={() => navigate(-1)} className="text-dark">
                                        <i className="fa-duotone fa-arrow-left me-1" />
                                        Add Promotion
                                    </Link>
                                </h4>
                                <div className="flex-shrink-0 text-end">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-2">
                                            <img src={user?.image} alt="" className="avatar-xs rounded-circle" />
                                        </div>
                                        <div className="flex-grow-1">
                                            {user?.name}
                                            <br />
                                            <span className="text-muted" style={{ fontSize: '11px' }}>{user?.employee_id}</span>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="c-form-area pb-3">
                                <form onSubmit={handleSubmit} ref={formRef}>

                                    <div className="row align-items-end mb-3">
                                        <div className="col-sm-6 col-md-6">
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i class="fa-light fa-list-check text-info"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="department_name" className="form-label fs-14 text-secondary mb-1 text-uppercase">Current Department*</label>
                                                    <select required className="form-select rounded-0" id="department_name" >
                                                        <option>{user?.department_name}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-sm-6 col-md-6">
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i class="fa-light fa-list-check text-primary"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="designation_name" className="form-label fs-14 text-secondary mb-1 text-uppercase">Current Designation*</label>
                                                    <select required className="form-select rounded-0" id="designation_name">
                                                        <option>{user?.designation_name}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row align-items-end mb-3">
                                        <div className="col-sm-6 col-md-6">
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i class="fa-light fa-list text-info"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="department_id" className="form-label fs-14 text-secondary mb-1 text-uppercase">Promoted Department*</label>
                                                    <select required className="form-select rounded-0" id="department_id" name="department_id">
                                                        <option value={``} selected>Choose...</option>
                                                        {!loading && departments?.map((row, index) => (
                                                            <option value={row.id}>{row.name}</option>
                                                        ))}


                                                    </select>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-sm-6 col-md-6">
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i class="fa-light fa-list text-success"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="designation_id" className="form-label fs-14 text-secondary mb-1 text-uppercase">Promoted Designation*</label>
                                                    <select required className="form-select rounded-0" id="designation_id" name="designation_id">
                                                        <option value={``} selected>Choose...</option>
                                                        {!loading && designations?.map((row, index) => (
                                                            <option value={row.id}>{row.name}</option>
                                                        ))}


                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row align-items-end mb-3">
                                        <div className="col-sm-6 col-md-6">
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i class="fa-thin fa-calendar-days text-primary"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="date" className="form-label fs-14 text-secondary mb-1 text-uppercase">Promotion Date*</label>
                                                    <input required type="date" className="form-control rounded-0" placeholder="" id="date" name="date" />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-sm-6 col-md-6">
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i class="fa-thin fa-dollar text-primary"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="current_salary" className="form-label fs-14 text-secondary mb-1 text-uppercase">Current Salary(Basic)*</label>
                                                    <input readOnly type="text" className="form-control rounded-0 bg-light" placeholder="" id="current_salary" name="current_salary" defaultValue={user?.basic_salary} />
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    <div className="row align-items-end mb-3">
                                        <div className="col-sm-6 col-md-6">
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i class="fa-thin fa-dollar text-primary"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="salary" className="form-label fs-14 text-secondary mb-1 text-uppercase">Promotion Salary(Basic)*</label>
                                                    <input required type="text" className="form-control rounded-0" placeholder="" id="salary" name="salary" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="row align-items-end mb-3">
                                        <div className="col-sm-12">
                                            <div className="d-flex align-items-start">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i className="fa-duotone fa-message-captions text-secondary" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <textarea name="remark" className="form-control rounded-0" id="exampleFormControlTextarea1" rows={3} placeholder="Write a details" defaultValue={""} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-end mb-4">
                                        <button type="submit" className="btn btn-success rounded-pill">
                                            Submit {saveLoading ? (
                                                <span
                                                    className="spinner-border spinner-border-sm ml-5"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            ) : (
                                                <span></span>
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>

                        </div>{/* end card-body */}
                    </div>
                </div>

            </div>
        </>
    )
}
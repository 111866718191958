import { useRef, useState } from "react";
import toast from 'react-hot-toast';
import { useDispatch } from "react-redux";
import API from "../../../Network/API";
import { ADD_MY_ATTENDANCE_FETCH_SUCCESS } from "../../../Reducer/MyAttendanceSlice";
import { default as Select } from "react-select";
export default function AddAttendance({users}) {

    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const inpRef = useRef()
    const modalRef1 = useRef()

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        var data = {
            'check_in': event.target.check_in.value,
            'check_out': event.target.check_out.value,
            'user_id': event.target.user_id.value,
        }
       API.post('/v1/oauth/save_check_in_out', data).then((response) => {
        
        toast.success(response.data.message);
           modalRef1.current.click();
        document.getElementById("add_country_form").reset()
          dispatch(ADD_MY_ATTENDANCE_FETCH_SUCCESS(response.data.data))
       })
       .catch((err) => {
           toast.error(err.response.data.message);
           setLoading(false)
       });

    //    toast.promise(responsea, {
    //     loading: 'Saving ...',
    //     success: (response) => {
    //         setLoading(false);
    //         modalRef1.current.click();
    //         document.getElementById("add_country_form").reset()
    //        dispatch(ADD_MY_ATTENDANCE_FETCH_SUCCESS(response.data.data))
    //       if (response.status === 500) throw new Error('server error');
    //       return `${response.data.message}`;
    //     },
    //     error: (e) => `${e.response.data.errors.name??e.response.data.message}`,
    //     duration: 6000,
    //   })
      
    }

    return (
        <>
            <div class="modal" id="myModal">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Add Attendance</h4>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" ref={modalRef1}></button>
                        </div>
                        <div class="modal-body">
                            <form onSubmit={handleSubmit} id="add_country_form">
                                <div className="row">
                                    <div className="col-sm-12 mb-3">
                                        <label htmlFor="user_id" className="form-label c-label">Employee</label>
                                        <Select 
                                         name="user_id"
                                         placeholder={`Select Employee`}
                                         options={users}
                    
                                         closeMenuOnSelect={true}
                                         hideSelectedOptions={true}
                                     />
                                    </div>
                                    <div className="col-sm-12 mb-3">
                                        <label htmlFor="check_in" className="form-label c-label">Check In</label>
                                        <input ref={inpRef} type="datetime-local" className="form-control rounded-pill" id="check_in" name="check_in" placeholder required />
                                    </div>
                                    <div className="col-sm-12 mb-3">
                                        <label htmlFor="check_out" className="form-label c-label">Check Out</label>
                                        <input ref={inpRef} type="datetime-local" className="form-control rounded-pill" id="check_out" name="check_out" placeholder required />
                                    </div>
                                </div>

                                <div class="modal-footer">
                                    <button type="submit" class="btn btn-success">Submit {isLoading ? (
                                        <span
                                            className="spinner-border spinner-border-sm ml-5"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    ) : (
                                        <span></span>
                                    )}</button>
                                </div>
                            </form>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
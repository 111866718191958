import {  useRef, useState } from "react";
import toast from 'react-hot-toast';
import { useDispatch } from "react-redux";
import API from "../../../../Network/API";
import Modal from 'react-bootstrap/Modal';

import { EDIT_LOAN_CATEGORY_FETCH_SUCCESS } from "../../../../Reducer/LoanCategorySlice";

export default function EditLoanCategory({show,onHide,row_data}) {

    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const inpRef = useRef()
    const modalRef1 = useRef()



    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        const data = new FormData();
        data.append('name', event.target.name.value)

        data.append('_method', 'PUT')


        if (event.target.is_attachment.checked) {
            data.append('is_attachment', 1)
        } else {
            data.append('is_attachment', 0)
        }

        const response = API.post('/v1/oauth/loan_categories/' + row_data.id + '/update', data);
        toast.promise(response, {
            loading: 'Saving ...',
            success: (response) => {
                setLoading(false);
                modalRef1.current.click();
                document.getElementById("add_country_form").reset()
                dispatch(EDIT_LOAN_CATEGORY_FETCH_SUCCESS(response.data.data))
                if (response.status === 500) throw new Error('server error');
                return `${response.data.message}`;
            },
            error: (e) => `${e.response.data.errors.name ?? e.response.data.message}`,
            duration: 6000,
        })
        setLoading(false)
    }

    return (
        <>
            <Modal show={show} onHide={onHide}>
                <Modal.Header>
                    <h4 class="modal-title">Edit Loan Types</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" ref={modalRef1} onClick={onHide}></button>

                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={handleSubmit} id="add_country_form">
                    
                        <div className="row">
                            <div className="col-sm-12 mb-3">
                                <label htmlFor="name" className="form-label c-label">Loan Type Name</label>
                                <input defaultValue={row_data?.name} ref={inpRef} name="name" type="text" className="form-control rounded-pill" id="name" placeholder required />
                            </div>
                        </div>
  



                        <div className="row">
                            <div className="col-sm-12 mb-2">
                                <label htmlFor="is_attachment" className="form-label c-label"><input type="checkbox" name="is_attachment" id="is_attachment" defaultChecked={row_data?.is_mandatory_attachment==1?true:false} /> <span className="es-2">Submitting an attachment is mandatory when apply loan against this policy</span></label>

                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="submit" class="btn btn-success">Submit {isLoading ? (
                                <span
                                    className="spinner-border spinner-border-sm ml-5"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            ) : (
                                <span></span>
                            )}</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}
import axios from 'axios';
import BaseApi from './BaseApi';

export default axios.create({
    // baseURL: `https://alifhr-admin.itadventurebd.com/api`,
     baseURL: BaseApi()+'/api',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+localStorage.getItem("alif_token")??""
    }
});
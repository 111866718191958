import { Link } from "react-router-dom";

export default function AddSalarySheet(){
    return (
        <>
        <div className="container-fluid">
                <div className="page-title-box">
                    <div className="page-title-breadcrumb">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item"><a href="#">Payroll</a></li>
                            <li className="breadcrumb-item active">Add Deduction</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-10 offset-1">

                        <div className="card border-0 main-content-card">
                            <div className="card-body">
                                <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3">
                                    <h4 className="main-card-title mb-0 flex-grow-1">
                                        <Link to={`/salary_sheet`} className="text-dark">
                                            <i className="fa-duotone fa-arrow-left me-1" />
                                            Generate Bulk Salary Sheet
                                        </Link>
                                    </h4>
                               
                                    
                                </div>

                                <form >
                                        <div className="c-form-area pb-3">
                                            <div className="row align-items-end mb-3">
                                                <div className="col-sm-6 col-md-7">
                                                    <div className="d-flex align-items-end">
                                                        <div className="flex-shrink-0 form-icon-avatar">
                                                            <span className="form-icon-title rounded-circle">
                                                                <i className="fa-duotone fa-coins text-primary" />
                                                            </span>
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <label htmlFor="amount" className="form-label fs-14 text-secondary mb-1 text-uppercase">Department*</label>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-5">
                                                    <p className="mb-0 text-secondary">
                                                        Select the deduction amount <br />

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
import { createSlice } from '@reduxjs/toolkit';

export const myVacationSlice = createSlice({
    name: 'myVacation',
    initialState: {
        loading: false,
        data: {

        },
        main_data: [],
        error: false,
    },
    reducers: {
        MY_VACATION_FETCH_START: (state) => {
            state.loading = true
        },
        MY_VACATION_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.main_data = action.payload.data
        },
        ADD_MY_VACATION_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.main_data = [action.payload, ...state.main_data]
        },
        EDIT_MY_VACATION_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            const newArray = state.main_data.filter(todo => todo.id !== action.payload.id)
            state.main_data = [...newArray, action.payload]
        },
        DELETE_MY_VACATION: (state, action) => {
            const filterPosts = state.main_data.filter(
                (post) => post.id !== action.payload
            );
            state.loading = false
            state.main_data = filterPosts
        },
        MY_VACATION_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { EDIT_MY_VACATION_FETCH_SUCCESS, DELETE_MY_VACATION, MY_VACATION_FETCH_START, MY_VACATION_FETCH_SUCCESS, MY_VACATION_FETCH_ERROR, ADD_MY_VACATION_FETCH_SUCCESS } = myVacationSlice.actions;
export default myVacationSlice.reducer;
import { createSlice } from '@reduxjs/toolkit';
export const departmentSlice = createSlice({
    name:'department',
    initialState:{
        loading: false,
        data: {

        },
        main_data:[],
        error: false,
    },
    reducers:{
        DEPARTMENT_FETCH_START:(state)=>{
            state.loading=true
        },
        DEPARTMENT_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
            state.main_data=action.payload.data
        },
        ADD_DEPARTMENT_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.main_data = [action.payload,...state.main_data]
        },
        EDIT_DEPARTMENT_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            const newArray = state.main_data.filter(todo => todo.id !==action.payload.id)
             state.main_data = [...newArray,action.payload]
        },
        DELETE_DEPARTMENT:(state,action)=>{
            const filterPosts = state.main_data.filter(
                (post) => post.id !== action.payload
              );
            state.loading = false
            state.main_data=filterPosts
        },
        DEPARTMENT_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const {EDIT_DEPARTMENT_FETCH_SUCCESS,DELETE_DEPARTMENT, DEPARTMENT_FETCH_START,DEPARTMENT_FETCH_SUCCESS,DEPARTMENT_FETCH_ERROR,ADD_DEPARTMENT_FETCH_SUCCESS }=departmentSlice.actions;
export default departmentSlice.reducer;
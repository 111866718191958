import { useDispatch, useSelector } from "react-redux";
import API from "../../../../Network/API";
import { useEffect, useState } from "react";
import Paginate from "../../../../services/Paginate";

import AddStatus from "./AddStatus";
import { STATUS_FETCH_ERROR, STATUS_FETCH_SUCCESS } from "../../../../Reducer/StatusSlice";

export default function Status() {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [nxtPage, setNextPage] = useState(null);
  const [search_value, setSearchValue] = useState(null);

  useEffect(() => {
    all_data('')
  }, []);

  const all_data = (name) => {
    API.get('/v1/oauth/marital_status?name='+name).then((response) => {
      dispatch(STATUS_FETCH_SUCCESS(response.data.data))
      setLoading(false)
    }).catch((error) => {
      setLoading(true)
      STATUS_FETCH_ERROR();
    });
  }

  const paginate = (pageNumber) => {
    API.get('/v1/oauth/marital_status?page='+pageNumber).then((response) => {
      dispatch(STATUS_FETCH_SUCCESS(response.data.data))
      setLoading(false)
    }).catch((error) => {
      setLoading(true)
      STATUS_FETCH_ERROR();
    });
  };

  const previousPage = (nxtPage) => {
    setNextPage(nxtPage)
  }

  const nextPage = (nxtPage) => {
    setNextPage(nxtPage)
  }

  const selector = useSelector((state) => state.statusReducer)
 
  return (
    <>
      <div className="page-title-box">
        <div className="page-title-breadcrumb">
          <ol className="breadcrumb m-0">
            <li className="breadcrumb-item"><a href="#">Home</a></li>
            <li className="breadcrumb-item"><a href="#">Settings</a></li>
            <li className="breadcrumb-item active">Marital Status</li>
          </ol>
        </div>
      </div>
      <div className="card border-0 main-content-card">
        <div className="card-body">
          <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3 main-content-card-header">
            <h4 className="main-card-title mb-0 flex-grow-1">Marital Status</h4>
            <div className="flex-shrink-0">
              <div className="app-search">
                <div className="input-group">
                  <div className="position-relative">
                    <input onChange={(e)=>all_data(e.target.value)} type="text" className="form-control" placeholder="Search..." autoComplete="off" id="search-options"/>
                    <span className="fa-light fa-search search-widget-icon" />
                  </div>
                  <button className="btn btn-add" type="button" id="button-addon2"  data-bs-toggle="modal" data-bs-target="#myModal"><i className="fa-regular fa-plus" /></button>
                </div>
              </div>
            </div>
          </div>
          <div className="live-preview">
            <ul className="list-group list-group-flush">
            {!selector?.loading && selector?.main_data?.map((row, index) => (
              <li className="list-group-item">{row.name} {row.status==0?<span className="badge bg-warning" style={{fontSize:'9px'}}>Pending</span>:''}</li>
            ))}
            </ul>
            <Paginate nextPage={nextPage} previousPage={previousPage} previousPageUrl={selector?.data.prv_page_url} nextPageUrl={selector?.data.next_page_url} postsPerPage={selector?.data.per_page} paginate={paginate} totalPosts={selector?.data.total_page} hasMorePage={selector?.data.has_more_page} onFirstPage={selector?.data.first_page} hasPage={selector?.data.has_page} currentPage={selector?.data.current_page} />

          </div>
        </div>{/* end card-body */}
      </div>

      <AddStatus/>
    </>
  )
}
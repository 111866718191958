import { useEffect, useRef, useState } from "react";
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from "react-redux";
import API from "../../../../Network/API";
import Modal from 'react-bootstrap/Modal';
import { EDIT_EVENT_APPROVAL_FETCH_SUCCESS } from "../../../../Reducer/EventApprovalSlice";
import { EVENT_USERS_FETCH_SUCCESS } from "../../../../Reducer/EventApprovalUserSlice";

export default function EditEventApproval({show,onHide,row_data}) {
    const [name, setName] = useState("");
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const inpRef = useRef()
    const modalRef1 = useRef()
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        var data = {
            'priority': name==''?row_data?.priority:name,
            'event_category': row_data?.event_category_id,
            'user_id': row_data?.user_id,
            '_method':'PUT'
        }
        const responsea = API.post('/v1/oauth/event_approvals/'+row_data.id+'/update', data);
        toast.promise(responsea, {
            loading: 'Saving ...',
            success: (response) => {
                setLoading(false);
                modalRef1.current.click();
                dispatch(EDIT_EVENT_APPROVAL_FETCH_SUCCESS(response.data.data.category))
                dispatch(EVENT_USERS_FETCH_SUCCESS(response.data.data))
                if (response.status === 500) throw new Error('server error');
                return `${response.data.message}`;
            },
            error: (e) => `${e.response.data.errors.name ?? e.response.data.message}`,
            duration: 6000,
        })
        setLoading(false)
    }

    return (
        <>
            <Modal show={show} onHide={onHide}>
                <Modal.Header>
                    <h4 class="modal-title">Edit Priority</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" ref={modalRef1} onClick={onHide}></button>

                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={handleSubmit} id="add_country_form">
                    <div className="row">
                            <div className="col-sm-12 mb-3">
                                <label htmlFor="name" className="form-label c-label">Name</label>
                                <input disabled defaultValue={row_data?.user_name} type="text" className="form-control rounded-pill" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 mb-3">
                                <label htmlFor="name" className="form-label c-label">Priority</label>
                                <input defaultValue={row_data?.priority} ref={inpRef} onChange={(e) => setName(e.target.value)} type="text" className="form-control rounded-pill" id="name" placeholder required />
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="submit" class="btn btn-success">Submit {isLoading ? (
                                <span
                                    className="spinner-border spinner-border-sm ml-5"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            ) : (
                                <span></span>
                            )}</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}
import { useEffect, useRef, useState } from "react";
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from "react-redux";
import API from "../../../../Network/API";
import Modal from 'react-bootstrap/Modal';

import { EDIT_PERFORMANCE_CRITERIA_FETCH_SUCCESS } from "../../../../Reducer/PerformanceCriteriaSlice";

export default function EditPerformanceCriteria({show,onHide,row_data}) {
    const [name, setName] = useState("");
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const inpRef = useRef()
    const modalRef1 = useRef()
    const [type_id, setTypeId] = useState("");
    const [types, setType] = useState();
    useEffect(() => {
        API.get('/v1/oauth/performance_criteria/create').then((response) => {
            setType(response.data.data)
        }).catch((error) => {
        });
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
   
        var data = {
            'name': name==""?row_data?.name:name,
            'performance_type_id': type_id==""?row_data?.performance_type_id:type_id,
            '_method':'PUT'
        }
        const responsea = API.post('/v1/oauth/performance_criteria/'+row_data.id+'/update', data);
        toast.promise(responsea, {
            loading: 'Saving ...',
            success: (response) => {
                setLoading(false);
                modalRef1.current.click();
                document.getElementById("add_country_form").reset()
                dispatch(EDIT_PERFORMANCE_CRITERIA_FETCH_SUCCESS(response.data.data))
                if (response.status === 500) throw new Error('server error');
                return `${response.data.message}`;
            },
            error: (e) => `${e.response.data.errors.name ?? e.response.data.message}`,
            duration: 6000,
        })
        setLoading(false)
    }

    return (
        <>
            <Modal show={show} onHide={onHide}>
                <Modal.Header>
                    <h4 class="modal-title">Edit  Criteria</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" ref={modalRef1} onClick={onHide}></button>

                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={handleSubmit} id="add_country_form">
                        <div className="row">
                        <div className="col-sm-12 mb-3">
                                        <label htmlFor="name" className="form-label c-label">Performance Type <em className="text-danger">*</em></label>
                                        <select className="form-select rounded-pill" required onChange={(e) => setTypeId(e.target.value)}>
                                            <option value={``}>Choose....</option>
                                            {types?.map((row, index) => (
                                                <option selected={row_data?.performance_type_id==row.id?true:false} value={row.id}>{row.name}</option>
                                            ))}
                                        </select>
                                    </div>
                            <div className="col-sm-12 mb-3">
                                <label htmlFor="name" className="form-label c-label">Name</label>
                                <input defaultValue={row_data?.name} ref={inpRef} onChange={(e) => setName(e.target.value)} type="text" className="form-control rounded-pill" id="name" placeholder required />
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="submit" class="btn btn-success">Submit {isLoading ? (
                                <span
                                    className="spinner-border spinner-border-sm ml-5"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            ) : (
                                <span></span>
                            )}</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}
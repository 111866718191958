import { useEffect, useRef, useState } from "react";
import toast from 'react-hot-toast';
import { useDispatch } from "react-redux";
import API from "../../../../Network/API";
import { ADD_EVENT_APPROVAL_FETCH_SUCCESS, EDIT_EVENT_APPROVAL_FETCH_SUCCESS } from "../../../../Reducer/EventApprovalSlice";
import Modal from 'react-bootstrap/Modal';
import { ADD_EVENT_USERS_FETCH_SUCCESS, EVENT_USERS_FETCH_SUCCESS } from "../../../../Reducer/EventApprovalUserSlice";
export default function AddEventApproval({ show, onHide, row_data }) {

    const [name, setName] = useState("");
    const [employee_id, setEmployee] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [users, setUser] = useState([]);
    const dispatch = useDispatch();
    const inpRef = useRef()
    const modalRef1 = useRef()

    useEffect(() => {
        API.get('/v1/oauth/event_approvals/create').then((response) => {
            setLoading(false)
            setUser(response.data.data.users);
        }).catch((error) => {
            setLoading(true)

        });
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        var data = {
            'event_category': row_data?.id,
            'user_id': employee_id,
            'priority': name,
        }
        const responsea = API.post('/v1/oauth/event_approvals/save', data);
        toast.promise(responsea, {
            loading: 'Saving ...',
            success: (response) => {
                setLoading(false);
                modalRef1.current.click();
                document.getElementById("add_country_form").reset()
                dispatch(EDIT_EVENT_APPROVAL_FETCH_SUCCESS(response.data.data.category))
                dispatch(EVENT_USERS_FETCH_SUCCESS(response.data.data))
                if (response.status === 500) throw new Error('server error');
                return `${response.data.message}`;
            },
            error: (e) => `${e.response.data.errors.name ?? e.response.data.message}`,
            duration: 6000,
        })
        setLoading(false)
    }

    return (
        <>
            <Modal show={show} onHide={onHide}>
                <Modal.Header>
                    <h4 class="modal-title">Add Approval</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" ref={modalRef1} onClick={onHide}></button>

                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={handleSubmit} id="add_country_form">
                        <div className="row">
                        <div className="col-sm-12 mb-3">
                                <label htmlFor="name" className="form-label c-label">Approval Title</label>
                                <input disabled defaultValue={row_data?.name} readOnly type="text" className="form-control rounded-pill"  />
                            </div>
                            <div className="col-sm-12 mb-3">
                                <label htmlFor="user_id" className="form-label fs-14 text-secondary mb-1 text-uppercase">Employee Name*</label>
                                <select onChange={(e) => setEmployee(e.target.value)} className="form-select rounded-0" id="user_id" name="user_id" required>
                                    <option selected value={``}>Choose...</option>
                                    {!isLoading && users?.map((row, index) => (
                                        <option value={row.id}>{row.name} ({row.employee_id})</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-sm-12 mb-3">
                                <label htmlFor="name" className="form-label c-label">Priority</label>
                                <input ref={inpRef} onChange={(e) => setName(e.target.value)} type="text" className="form-control rounded-pill" id="email" placeholder required />
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="submit" class="btn btn-success">Submit {isLoading ? (
                                <span
                                    className="spinner-border spinner-border-sm ml-5"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            ) : (
                                <span></span>
                            )}</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, Outlet } from "react-router-dom";
import API from "../../../Network/API";
import Paginate from "../../../services/Paginate";
import { MY_ATTENDANCE_FETCH_ERROR, MY_ATTENDANCE_FETCH_SUCCESS } from "../../../Reducer/MyAttendanceSlice";
import { useTranslation } from "react-i18next";
import { MY_APPROVAL_FETCH_ERROR, MY_APPROVAL_FETCH_SUCCESS } from "../../../Reducer/MyApprovalSlice";
import ApprovalCard from "./ApprovalCard";

export default function MyApproval() {
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [nxtPage, setNextPage] = useState(null);
    const { t } = useTranslation();
    useEffect(() => {
        all_data()
    }, []);

    const all_data = () => {
        API.get('/v1/oauth/my_approval').then((response) => {
            dispatch(MY_APPROVAL_FETCH_SUCCESS(response.data.data))
            setLoading(false)
        }).catch((error) => {
            setLoading(true)
            MY_APPROVAL_FETCH_ERROR();
        });
    }
    const paginate = (pageNumber) => {
        API.get('/v1/oauth/my_approval?page=' + pageNumber).then((response) => {
            dispatch(MY_APPROVAL_FETCH_SUCCESS(response.data.data))
            setLoading(false)
        }).catch((error) => {
            setLoading(true)
            MY_APPROVAL_FETCH_ERROR();
        });
    };

    const previousPage = (nxtPage) => {
        setNextPage(nxtPage)
    }

    const nextPage = (nxtPage) => {
        setNextPage(nxtPage)
    }

    const selector = useSelector((state) => state.myApprovalReducer)


    return (
        <>
            <div className="row g-1">
                <div className="col-md-5 col-sm-5 col-lg-4" style={{ background: '#f8f9fc' }}>
                <div class="task-list task-list-view-filter me-2 ms-2 mt-2 mb-5">
                    {!selector.loading && selector?.main_data?.map((row,index)=>(
                        <ApprovalCard key={index} data={row}/>

                    ))}
                   </div>
                </div>
                <div className="col-md-7 col-sm-7 col-lg-8">
                    <Outlet />
                </div>
            </div>
        </>
    )
}
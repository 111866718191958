import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import API from "../../../Network/API";
import { MY_ATTENDANCE_FETCH_ERROR, MY_ATTENDANCE_FETCH_SUCCESS } from "../../../Reducer/MyAttendanceSlice";
import Paginate from "../../../services/Paginate";
import { default as Select } from "react-select";
import AddAttendance from "./AddAttendance";
export default function AllAttendance() {
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [nxtPage, setNextPage] = useState(null);
    const [users, setUser] = useState(null);
    const { t } = useTranslation();
    useEffect(() => {
        all_data()
        API.get('/v1/oauth/ajax_users').then((response) => {
            setUser(response.data.data)
            setLoading(false)
        }).catch((error) => {
            setLoading(true)
        });
    }, []);

    const all_data = () => {
        API.get('/v1/oauth/timesheet').then((response) => {
            dispatch(MY_ATTENDANCE_FETCH_SUCCESS(response.data.data))
            setLoading(false)
        }).catch((error) => {
            setLoading(true)
            MY_ATTENDANCE_FETCH_ERROR();
        });
    }
    const paginate = (pageNumber) => {
        API.get('/v1/oauth/timesheet?page=' + pageNumber).then((response) => {
            dispatch(MY_ATTENDANCE_FETCH_SUCCESS(response.data.data))
            setLoading(false)
        }).catch((error) => {
            setLoading(true)
            MY_ATTENDANCE_FETCH_ERROR();
        });
    };

    const previousPage = (nxtPage) => {
        setNextPage(nxtPage)
    }

    const nextPage = (nxtPage) => {
        setNextPage(nxtPage)
    }

    const selector = useSelector((state) => state.myAttendanceSlice)

    const handleSubmit = (event) => {
        event.preventDefault();
       
        API.get(`/v1/oauth/timesheet?user_id=${event.target.user_id.value}&start_date=${event.target.start_time.value}&end_date=${event.target.end_time.value}`).then((response) => {
            dispatch(MY_ATTENDANCE_FETCH_SUCCESS(response.data.data))
            setLoading(false)
        }).catch((error) => {
            setLoading(true)
            MY_ATTENDANCE_FETCH_ERROR();
        });
    }
    return (
        <>
            <div className="container-fluid">
                <div className="page-title-box">
                    <div className="page-title-breadcrumb">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item active">{t('Attendance')}</li>
                        </ol>
                    </div>
                </div>
                <div className="row align-items-center py-2">
                    <div className="col-sm">
                        <div className="d-flex align-items-center">
                            <h5 className="card-title mb-0 me-3">{t('Attendance')}</h5>

                        </div>
                    </div>
                    <div className="col-sm-auto">
                        <div className="d-flex gap-1 flex-wrap">
                            <div className="flex-shrink-0">

                                <div className="app-search bg-white">
                                <form onSubmit={handleSubmit} id="add_country_form">
                                    <div className="input-group">
                                        <Select name="user_id"
                                            placeholder={`All Employee`}
                                            options={users}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            isClearable
                                        />
                                        <input defaultValue={selector?.data?.start_time} type="date" className="form-control" autoComplete="off" name="start_time" />
                                        <input defaultValue={selector?.data?.end_time} type="date" className="form-control"  autoComplete="off" name="end_time" />
                                        <button className="btn btn-success" type="submit"><i className="fa-regular fa-search" /></button>
                                    </div>
                                    </form>
                                </div>

                            </div>

                          
                            <button type="button" className="btn btn-primary shadow-sm btn-sm" id="button-addon2" data-bs-toggle="modal" data-bs-target="#myModal">
                                <i className="fa-light fa-plus" /> Add Attendance
                            </button>

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card border-0 shadow-sm">
                            <div className="table-responsive table-card">
                                <table className="table text-center table-hover table-centered align-middle table-nowrap mb-0">
                                    <thead className="text-muted table-main-bg">
                                        <tr>
                                            <th scope="col">{t("employee_name")}</th>
                                            <th scope="col">{t("date")}</th>
                                            <th scope="col">{t("check_in")}</th>
                                            <th scope="col">{t("check_out")}</th>
                                            <th scope="col">{t("total_hour")}</th>
                                            <th scope="col">{t("total_late")}</th>
                                            <th scope="col">{t('sstatus')}</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!selector?.loading && selector?.main_data?.map((row, index) => (
                                            <tr>
                                                <td width={`15%`}>
                                                    <div className="d-flex align-items-center">
                                                        <div className="flex-shrink-0 me-2">
                                                            <img src={row.image} alt="" className="avatar-xs rounded-circle" />
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            {row.name}
                                                            <br />
                                                            <span className="text-muted" style={{ fontSize: '11px' }}>{row.designation}</span>
                                                        </div>

                                                    </div>
                                                </td>

                                                <td>
                                                    {row.date}
                                                </td>


                                                <td>
                                                    <h5 className="fs-14 my-1">
                                                        <a href="#" className="text-reset">{row.check_in} </a>
                                                    </h5>
                                                </td>
                                                <td>
                                                    <h5 className="fs-14 my-1">
                                                        <a href="#" className="text-reset">{row.check_out} </a>
                                                    </h5>
                                                </td>
                                                <td>
                                                    <h5 className="fs-14 my-1">
                                                        <a href="#" className="text-reset">{row.total_hour} </a>
                                                    </h5>
                                                </td>

                                                <td>
                                                    <h5 className="fs-14 my-1">
                                                        <a href="#" className="text-reset">{row.total_late} </a>
                                                    </h5>
                                                </td>
                                                <td>
                                                    {row.is_approved == 1 ? <span className="badge bg-success">Approved</span> : <span className="badge bg-warning">Pending</span>}
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <Paginate nextPage={nextPage} previousPage={previousPage} previousPageUrl={selector?.data.prv_page_url} nextPageUrl={selector?.data.next_page_url} postsPerPage={selector?.data.per_page} paginate={paginate} totalPosts={selector?.data.total_page} hasMorePage={selector?.data.has_more_page} onFirstPage={selector?.data.first_page} hasPage={selector?.data.has_page} currentPage={selector?.data.current_page} />
                            <AddAttendance users={users} />
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
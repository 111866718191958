import { createSlice } from '@reduxjs/toolkit';
export const statusSlice = createSlice({
    name:'status',
    initialState:{
        loading: false,
        data: {

        },
        main_data:[],
        error: false,
    },
    reducers:{
        STATUS_FETCH_START:(state)=>{
            state.loading=true
        },
        STATUS_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
            state.main_data=action.payload.data
        },
        ADD_STATUS_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.main_data = [action.payload,...state.main_data]
        },
        STATUS_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const { STATUS_FETCH_START,STATUS_FETCH_SUCCESS,STATUS_FETCH_ERROR,ADD_STATUS_FETCH_SUCCESS }=statusSlice.actions;
export default statusSlice.reducer;


import { createSlice } from '@reduxjs/toolkit';

export const loanCategorySlice = createSlice({
    name: 'loan_category',
    initialState: {
        loading: false,
        data: {},
        main_data: [],
        error: false,
    },
    reducers: {
        LOAN_CATEGORY_FETCH_START: (state) => {
            state.loading = true
        },
        LOAN_CATEGORY_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.main_data = action.payload.data
        },
        ADD_LOAN_CATEGORY_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.main_data = [action.payload, ...state.main_data]
        },
        EDIT_LOAN_CATEGORY_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            const newArray = state.main_data.filter(todo => todo.id !== action.payload.id)
            state.main_data = [...newArray, action.payload]
        },
        DELETE_LOAN_CATEGORY: (state, action) => {
            const filterPosts = state.main_data.filter(
                (post) => post.id !== action.payload
            );
            state.loading = false
            state.main_data = filterPosts
        },
        LOAN_CATEGORY_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { DELETE_LOAN_CATEGORY, EDIT_LOAN_CATEGORY_FETCH_SUCCESS, LOAN_CATEGORY_FETCH_START, LOAN_CATEGORY_FETCH_SUCCESS, LOAN_CATEGORY_FETCH_ERROR, ADD_LOAN_CATEGORY_FETCH_SUCCESS } = loanCategorySlice.actions;
export default loanCategorySlice.reducer;
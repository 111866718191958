export default function EmployeePresentStatus({ present_data }) {
    const getDayNameFromDate = (dateString) => {
        const date = new Date(dateString);
        const options = { weekday: 'short' };
        return date.toLocaleDateString('en-US', options);
    };

    return (
        <>
            <div className="table-responsive" style={{height:'450px',overflow:'auto'}}>
             
               <p className="p-0 m-0 mb-1">P=Present, A=Absent, L=Leave, W=Weekend, H=Holiday</p>
             
                <table className="rms_table table table-bordered" id="month_presnt_status">
                    <thead>
                        <tr>
                            <th className="align-middle text-center"><p className="p-0 m-0" style={{ width: '100px' }}>Employee ID</p></th>
                            <th className="align-middle text-center"><p className="p-0 m-0" style={{ width: '200px' }}>Employee Name</p></th>
                            <th className="align-middle text-center"><p className="p-0 m-0" style={{ width: '150px' }}>Designation</p></th>
                            {present_data?.dates?.length > 0 && present_data?.dates?.map((row, index) => (
                                <th className="text-center align-middle">
                                    <p className="p-0 m-0" style={{ width: '50px' }}>{present_data?.month} {index + 1}</p>
                                    <p className="p-0 m-0" style={{ fontSize: '11px', color: '#ccc' }}>{getDayNameFromDate(row)}</p>
                                </th>
                            ))}


                        </tr>
                    </thead>
                    <tbody>
                        {present_data?.users?.length > 0 && present_data?.users?.map((row, index) => (
                            <tr>
                                <td>{row.employee_id}</td>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-2">
                                            <img src={row.image} alt="" className="avatar-xs rounded-circle" />
                                        </div>
                                        <div className="flex-grow-1">
                                            {row.name}

                                        </div>

                                    </div>
                                </td>
                                <td> {row.designation_name}</td>
                                {present_data?.dates?.length > 0 && present_data?.dates?.map((row_date, index) => (
                                <td className={`text-center align-middle ${present_data.weekday?.find(todo=>todo.day_name==getDayNameFromDate(row_date))?'bg-weekday':''}`}>
                                        {row.attendances?.length>0 && row.attendances?.find(todo => todo.date == row_date)?
                                        <p className="p-0 m-0" style={{borderBottom:'2px solid green'}}>P</p>
                                        :
                                        row.leave?.length>0 && row.leave?.find(todo => todo.start_date >= row_date && todo.end_date <= row_date)?
                                        <p className="p-0 m-0" style={{borderBottom:'2px solid purple'}}>L</p>
                                        :
                                        present_data.weekday?.find(todo=>todo.day_name==getDayNameFromDate(row_date))?    
                                        <p className="p-0 m-0" >W</p>
                                        :
                                        present_data.publicHoliday?.find(todo => todo.start_date >= row_date && todo.end_date <= row_date)?    
                                        <p className="p-0 m-0">H</p>
                                        :
                                        <p className="p-0 m-0" style={{borderBottom:'2px solid red'}}>A</p>
                                        }
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}
import { useEffect, useRef, useState } from "react";
import toast from 'react-hot-toast';
import { useDispatch } from "react-redux";
import API from "../../../../Network/API";
import { ADD_ROLE_FETCH_SUCCESS, EDIT_ROLE_FETCH_SUCCESS } from "../../../../Reducer/RoleSlice";
import Modal from 'react-bootstrap/Modal';


export default function EditRole({show,onHide,row_data}) {
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const inpRef = useRef()
    const modalRef1 = useRef()
    // Initialize state to keep track of the selected values for each row
    const [selectedValues, setSelectedValues] = useState([]);
    const [showDiv, setShowDiv] = useState(false);
    const [showIndex, setShowIndex] = useState(false);
    const [showRows, setShowRows] = useState([]);
    const [checkAll, setCheckAll] = useState([]);
    // Function to update the selected value for a specific row
    const handleRowChange = (index, value) => {
        setSelectedValues((prevSelectedValues) => {
            const updatedValues = [...prevSelectedValues];
            updatedValues[index] = value;
            return updatedValues;
        });

        if (value === 'some') {
            setShowRows((prevShowRows) => {
              const updatedShowRows = [...prevShowRows];
              updatedShowRows[index] = !prevShowRows[index];
              return updatedShowRows;
            });

            setCheckAll((prevShowRows) => {
                const updatedShowRows = [...prevShowRows];
                updatedShowRows[index] = false; // show the row when "all" is selected
                return updatedShowRows;
              });

          } else if (value === 'none') {
            setShowRows((prevShowRows) => {
              const updatedShowRows = [...prevShowRows];
              updatedShowRows[index] = false; // hide the row when "none" is selected
              return updatedShowRows;
            });

            setCheckAll((prevShowRows) => {
                const updatedShowRows = [...prevShowRows];
                updatedShowRows[index] = false; // 
                return updatedShowRows;
              });
          } else {

            setShowRows((prevShowRows) => {
              const updatedShowRows = [...prevShowRows];
              updatedShowRows[index] = false; // 
              return updatedShowRows;
            });

            setCheckAll((prevShowRows) => {
              const updatedShowRows = [...prevShowRows];
              updatedShowRows[index] = true; 
              return updatedShowRows;
            });


          }

        
    };

    useEffect(() => {
        API.get('/v1/oauth/role_menus').then((response) => {
            setData(response.data.data)
        }).catch((error) => {

        });
    }, []);
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        const menuInputs = event.target.route;
        const selectedMenus = Array.from(menuInputs).filter((checkbox) => checkbox.checked);
        // Get the values of the checked checkboxes
        const selectedMenuValues = selectedMenus.map((checkbox) => checkbox.value);

        const subMenuInputs = event.target.subMenu;
        const selectedSubMenus = Array.from(subMenuInputs).filter((checkbox) => checkbox.checked);
        // Get the values of the checked checkboxes
        const selectedSubMenuValues = selectedSubMenus.map((checkbox) => checkbox.value);

        var data = {
            'name': event.target.name.value,
            'note': event.target.note.value,
            'menu_permission': selectedMenuValues,
            'submenu_permission': selectedSubMenuValues,
            '_method':'PUT'
        }


        const responsea = API.post(`/v1/oauth/roles/${row_data?.id}/update`, data);
        toast.promise(responsea, {
            loading: 'Saving ...',
            success: (response) => {
                setLoading(false);
                modalRef1.current.click();
                document.getElementById("add_country_form").reset()
                dispatch(EDIT_ROLE_FETCH_SUCCESS(response.data.data))
                setSelectedValues([])
                if (response.status === 500) throw new Error('server error');
                return `${response.data.message}`;
            },
            error: (e) => `${e.response.data.errors.name ?? e.response.data.message}`,
            duration: 6000,
        })
        setLoading(false)
    }

   

    return (
        <>
             <Modal show={show} onHide={onHide}>
                <Modal.Header className="modal-lg">
                    <h4 class="modal-title">Edit Role</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" ref={modalRef1} onClick={onHide}></button>

                </Modal.Header>

                <Modal.Body>
                            <form onSubmit={handleSubmit} id="add_country_form">
                                <div className="row">
                                    <div className="col-sm-12 mb-3">
                                        <label htmlFor="name" className="form-label c-label">Name*</label>
                                        <input defaultValue={row_data?.name} type="text" className="form-control rounded-pill" name="name" id="name" placeholder required />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 mb-3">
                                        <label htmlFor="name" className="form-label c-label">Note</label>
                                        <textarea defaultValue={row_data?.note} type="text" className="form-control" name="note" id="note" placeholder />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 mb-3">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Module Permission</th>
                                                    <th>Access</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data?.menus?.length > 0 && data?.menus?.map((row, index) => (

                                                    <>
                                                        <tr className="index" key={index}>
                                                            <td>{row.name} </td>
                                                            <td>
                                                                <div class="form-check form-check-inline">
                                                                    <input onChange={() => handleRowChange(index, row.route)} defaultChecked={row_data?.permissions?.includes(row.route)?true:false}  class="form-check-input" type="checkbox" name={`route`} id={`inlineRadio${index}`} value={row.route} />
                                                                    <label class="form-check-label" htmlFor={`inlineRadio${index}`}>All</label>
                                                                </div>
                                                                {/* {row?.sub_menus?.length >0?
                                                                <div class="form-check form-check-inline">
                                                                    <input onChange={() => handleRowChange(index, 'some')}  class="form-check-input" type="checkbox" name={`route`} id={`inlineRadioSome_${index}`} value={'some'} />
                                                                    <label class="form-check-label" htmlFor={`inlineRadioSome_${index}`}>Some</label>
                                                                </div>
                                                                :''} */}
                                                                {/* <div class="form-check form-check-inline">
                                                                    <input onChange={() => handleRowChange(index, 'none')} class="form-check-input" type="checkbox" name={`route`} id={`inlineRadiob${index}`} value="none" />
                                                                    <label class="form-check-label" htmlFor={`inlineRadiob${index}`}>None</label>
                                                                </div> */}
                                                            </td>
                                                        </tr>
                                                        <>
                                                            {row?.sub_menus?.length >0?

                                                                <tr  className={`sub_menu_${index} ${showRows[index] ? '' : ''}`}>
                                                                    <td colSpan={2} style={{ border: 'none' }}>
                                                                        <div className="ms-3">
                                                                        {row?.sub_menus?.length > 0 && row?.sub_menus?.map((srow, k) => (
                                                                            <div key={k} class="form-check">
                                                                                {checkAll[index]?
                                                                                <input defaultChecked={row_data?.permissions?.includes(srow.route)?true:false}  name="subMenu" class="form-check-input" type="checkbox" value={srow.route} id={`defaultCheck${index}_${k}`} />
                                                                                :
                                                                                <input defaultChecked={row_data?.permissions?.includes(srow.route)?true:false}  name="subMenu" class="form-check-input" type="checkbox" value={srow.route} id={`defaultCheck${index}_${k}`} />
                                        
                                                                                }

                                                                                <label class="form-check-label" for={`defaultCheck${index}_${k}`}>
                                                                                    {srow.name} 
                                                                                </label>
                                                                            </div>
))}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                :''}
                                                            
                                                        </>
                                                    </>




                                                ))}



                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="submit" class="btn btn-success">Save {isLoading ? (
                                        <span
                                            className="spinner-border spinner-border-sm ml-5"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    ) : (
                                        <span></span>
                                    )}</button>
                                </div>
                            </form>
                            </Modal.Body>
            </Modal>
        </>
    )
}
import { Outlet, Route, Routes } from "react-router-dom";
import SettingNav from "../../_partials/setting_nav";
import Nationality from "./settings/nationality/Nationality";
import Department from "./settings/department/Department";

export default function Setting(){
    return (
        <>
        <div className="row">
        <div className="col-md-4 col-sm-4 col-lg-3">
          <div className="card border-0 rounded-0">
            <SettingNav/>
          </div>
        </div>
        <div className="col-md-8 col-sm-8 col-lg-9">
        <Outlet />
        </div>
      </div>
        </>
    )
}
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function SettingNav(){
  const location = useLocation();
  const { t } = useTranslation();
    return (
        <>
        <div className="page-sidebar">
              <div className="page-sidebar-content">
                <span className="page-sidebar-title">{t("about")}</span>
                <Link to={`/settings/nationalities`} className={`page-sidebar-heading ${location.pathname=='/settings/nationalities'?'active':''}`}>{t("nationality")}</Link>
                <Link to={`/settings/religions`} className={`page-sidebar-heading ${location.pathname=='/settings/religions'?'active':''}`}>{t("religion")}</Link>
                <Link to={`/settings/genders`} className={`page-sidebar-heading ${location.pathname=='/settings/genders'?'active':''}`}>{t("gender")}</Link>
                <Link to={`/settings/status`} className={`page-sidebar-heading ${location.pathname=='/settings/status'?'active':''}`}>{t("status")}</Link>
              </div>
              <hr className="m-0" />
              <div className="page-sidebar-content">
                <span className="page-sidebar-title">{t("emergency_contact")}</span>
                <Link to={`/settings/relationship`} className={`page-sidebar-heading ${location.pathname=='/settings/relationship'?'active':''}`}>{t("relationship")}</Link>
         
              </div>
              <hr className="m-0" />
              <div className="page-sidebar-content">
                <span className="page-sidebar-title">{t("education")}</span>
                <Link to={`/settings/degrees`} className={`page-sidebar-heading ${location.pathname=='/settings/degrees'?'active':''}`}>{t("degree")}</Link>
         
              </div>
              <hr className="m-0" />
              <div className="page-sidebar-content">
                <span className="page-sidebar-title">{t("dependents")}</span>
                <Link to={`/settings/id_types`} className={`page-sidebar-heading ${location.pathname=='/settings/id_types'?'active':''}`}>{t("id_types")}</Link>
              </div>
              <hr className="m-0" />
              <div className="page-sidebar-content">
                <span className="page-sidebar-title">{t("job_description")}</span>
                <Link to={`/settings/departments`} className={`page-sidebar-heading ${location.pathname=='/settings/departments'?'active':''}`}>{t("department")}</Link>
                <Link to={`/settings/designations`} className={`page-sidebar-heading ${location.pathname=='/settings/designations'?'active':''}`}>{t("designation")}</Link>
                <Link to={`/settings/probation`} className={`page-sidebar-heading ${location.pathname=='/settings/probation'?'active':''}`}>{t("probation")}</Link>
                <Link to={`/settings/bank`} className={`page-sidebar-heading ${location.pathname=='/settings/bank'?'active':''}`}>{t("bank")}</Link>
              </div>
              <hr className="m-0" />
              <div className="page-sidebar-content">
                <span className="page-sidebar-title">{t("document")}</span>
                <Link to={`/settings/document_types`} className={`page-sidebar-heading ${location.pathname=='/settings/document_types'?'active':''}`}>{t('document_types')}</Link>
              </div>
              <hr className="m-0" />
              <div className="page-sidebar-content">
                <span className="page-sidebar-title">PAYROLL</span>
                <Link to={`/settings/late_configuration`} className={`page-sidebar-heading ${location.pathname=='/settings/late_configuration'?'active':''}`}>Late Configuration</Link>
                <Link to={`/settings/bonus_types`} className={`page-sidebar-heading ${location.pathname=='/settings/bonus_types'?'active':''}`}>Bonus Types</Link>
                <Link to={`/settings/tax_setup`} className={`page-sidebar-heading ${location.pathname=='/settings/tax_setup'?'active':''}`}>Tax/GOSI Percent</Link>
                <Link to={`/settings/fund_rules`} className={`page-sidebar-heading ${location.pathname=='/settings/fund_rules'?'active':''}`}>Provident Fund Interest</Link>
                <Link to={`/settings/allowances`} className={`page-sidebar-heading ${location.pathname=='/settings/allowances'?'active':''}`}>Allowances</Link>
                <Link to={`/settings/deductions`} className={`page-sidebar-heading ${location.pathname=='/settings/deductions'?'active':''}`}>Deductions</Link>
                <Link to={`/settings/fiscal_year`} className={`page-sidebar-heading ${location.pathname=='/settings/fiscal_year'?'active':''}`}>Fiscal Year</Link>
                <Link to={`/settings/loan_types`} className={`page-sidebar-heading ${location.pathname=='/settings/loan_types'?'active':''}`}>Loan Types</Link>
                <Link to={`/settings/overtime_categories`} className={`page-sidebar-heading ${location.pathname=='/settings/overtime_categories'?'active':''}`}>Overtime Types</Link>
                <Link to={`/settings/expense_categories`} className={`page-sidebar-heading ${location.pathname=='/settings/expense_categories'?'active':''}`}>Expense Types</Link>
              </div>
              <hr className="m-0" />
              <div className="page-sidebar-content">
                <span className="page-sidebar-title">Leave Management</span>
                <Link to={`/settings/holiday_types`} className={`page-sidebar-heading ${location.pathname=='/settings/holiday_types'?'active':''}`}>Public Holiday Types</Link>
                <Link to={`/settings/weekly_holidays`} className={`page-sidebar-heading ${location.pathname=='/settings/weekly_holidays'?'active':''}`}>Weekly Holidays</Link>
                <Link to={`/settings/leave_categories`} className={`page-sidebar-heading ${location.pathname=='/settings/leave_categories'?'active':''}`}>Leave Categories</Link>
                <Link to={`/settings/vacation_categories`} className={`page-sidebar-heading ${location.pathname=='/settings/vacation_categories'?'active':''}`}>Vacation Categories</Link>
                
                </div>
              <hr className="m-0" />
              <div className="page-sidebar-content">
                <span className="page-sidebar-title">ORGANIZATION</span>
                
              <Link to={`/settings/shift`} className={`page-sidebar-heading ${location.pathname=='/settings/shift'?'active':''}`}>Shift</Link>
                <Link to={`/settings/branches`} className={`page-sidebar-heading ${location.pathname=='/settings/branches'?'active':''}`}>Location or Branch</Link>
                {/* <Link to={`/settings/app_setting`} className={`page-sidebar-heading ${location.pathname=='/settings/app_setting'?'active':''}`}>Attendance Settings</Link> */}
              </div>
              <hr className="m-0" />
              <div className="page-sidebar-content">
                <span className="page-sidebar-title">Performance</span>
                <Link to={`/settings/performance_types`} className={`page-sidebar-heading ${location.pathname=='/settings/performance_types'?'active':''}`}>Performance Types</Link>
                <Link to={`/settings/performance_criteria`} className={`page-sidebar-heading ${location.pathname=='/settings/performance_criteria'?'active':''}`}>Performance Criteria</Link>
              </div>
              <hr className="m-0" />
              <div className="page-sidebar-content">
                <span className="page-sidebar-title">ASSETS</span>
                <Link to={`/settings/asset_types`} className={`page-sidebar-heading `}>Asset Types</Link>
              </div>
              <hr className="m-0" />
              <div className="page-sidebar-content">
                <span className="page-sidebar-title">OTHER's</span>
                <Link to={`/settings/disciplinary_rules`} className={`page-sidebar-heading ${location.pathname=='/settings/disciplinary_rules'?'active':''}`}>Disciplinary Rules</Link>
                <Link to={`/settings/business_tripe_categories`} className={`page-sidebar-heading ${location.pathname=='/settings/business_tripe_categories'?'active':''}`}>Business Tripe Categories</Link>
                <Link to={`/settings/business_tripe_routes`} className={`page-sidebar-heading ${location.pathname=='/settings/business_tripe_routes'?'active':''}`}>Business Tripe Routes</Link>
                <Link to={`/settings/business_tripe_methods`} className={`page-sidebar-heading ${location.pathname=='/settings/business_tripe_methods'?'active':''}`}>Business Tripe Methods</Link>
              </div>
              <hr className="m-0" />
              <div className="page-sidebar-content">
                <span className="page-sidebar-title">Recruitment</span>
                <Link to={`/settings/recruitment_categories`} className={`page-sidebar-heading ${location.pathname=='/settings/recruitment_categories'?'active':''}`}>Recruitment categories</Link>

              </div>
              <hr className="m-0" />
              <div className="page-sidebar-content">
                <span className="page-sidebar-title">Administration</span>
                <Link to={`/settings/company`} className={`page-sidebar-heading ${location.pathname=='/settings/company'?'active':''}`}>General Sttings</Link>
                {/* <Link to={`/settings/billing`} className={`page-sidebar-heading ${location.pathname=='/settings/billing'?'active':''}`}>Billing & Subscription</Link> */}
                <Link to={`/settings/roles`} className={`page-sidebar-heading ${location.pathname=='/settings/roles'?'active':''}`}>Roles</Link>
                <Link to={`/settings/integrations`} className={`page-sidebar-heading ${location.pathname=='/settings/integrations'?'active':''}`}>Integrations</Link>
              </div>
            </div>
        </>
    )
}
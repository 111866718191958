export default function AttendanceReportPayroll(props){
    return (
        <>
        <div className="table-responsive ">
            <table className="table table-bordered caption-top print-table" id="attendance_report_payroll">
            <caption>Attendance data for payroll - Month of {props?.data?.month}-{props?.data?.year}</caption>
                <thead>
                    <tr>
                    <th className="align-middle text-center"><p className="p-0 m-0" style={{ width: '100px' }}>Employee ID</p></th>
                            <th className="align-middle text-center"><p className="p-0 m-0" style={{ width: '200px' }}>Employee Name</p></th>
                            <th className="align-middle text-center"><p className="p-0 m-0" style={{ width: '150px' }}>Designation</p></th>
                        <th className="text-center align-middle">TTL Days</th>
                        <th className="text-center align-middle">Actual Working Days</th>
                        <th className="text-center align-middle">Weekends &amp; Holiday</th>
                        <th className="text-center align-middle">Present Days</th>
                        <th className="text-center align-middle">Leave Days</th>
                        <th className="text-center align-middle">Absent Days</th>
                        <th className="text-center align-middle">Late Days</th>
                        <th className="text-center align-middle">Overtime Hours</th>
                    </tr>
                </thead>
                <tbody>
                    
                {props?.data?.users.length>0 && props?.data?.users.map((row,index)=>(
                    <tr>
                        <td>{row.employee_id}</td>
                            <td>
                            <div className="d-flex align-items-center">
                                                        <div className="flex-shrink-0 me-2">
                                                            <img src={row.image} alt="" className="avatar-xs rounded-circle" />
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            {row.name}
                                                           
                                                        </div>

                                                    </div>
                            </td>
                            <td>  {row.designation_name}</td>
                        <td className="text-center">{props?.data?.totalDays}</td>
                        <td className="text-center">{props?.data?.acualDays}</td>
                        <td className="text-center">{props?.data?.weekend}</td>
                        <td className="text-center">{row.present}</td>
                        <td className="text-center">{row.leave_count}</td>
                        <td className="text-center">{(Number(props?.data?.acualDays)-Number(row.present)-Number(row.leave_count))}</td>
                        <td className="text-center">{row.late_days}</td>
                        <td className="text-center">{row.totalOvetime}</td>
                    </tr>
                     ))}
                </tbody>
            </table>
        </div>
        </>
    )
}
import API from "../../../Network/API";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams,Link } from "react-router-dom";
import ProfileNav from "./ProfileNav";
import Paginate from "../../../services/Paginate";
import { useTranslation } from "react-i18next";

export default function EmployeeAttendance(){
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const prams = useParams();
  const [prifle_data, setProfile] = useState(null);
  const [nxtPage, setNextPage] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    API.get(`/v1/oauth/users/${prams.id}/attendance`).then((response) => {
        setProfile(response.data.data)
        setLoading(false)
    }).catch((error) => {
        setLoading(true)
    });
}, []);


const previousPage = (nxtPage) => {
    setNextPage(nxtPage)
}

const nextPage = (nxtPage) => {
    setNextPage(nxtPage)
}

const paginate = (pageNumber) => {
    API.get(`/v1/oauth/users/${prams.id}/attendance?page=${pageNumber}`).then((response) => {
        setProfile(response.data.data)
        setLoading(false)
    }).catch((error) => {
        setLoading(true)
    });
};
    return (
        <>

          <div className="profile-foreground position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg" />
          </div>
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8 col-sm-12">
              <div className="pt-5 profile-wrapper">
                <div className="row g-4">
                  <div className="col-auto">
                    <div className="avatar-lg">
                      <img src={prifle_data?.employee?.image} alt="user-img" className="img-thumbnail rounded-circle" />
                    </div>
                  </div>
                  {/*end col*/}
                  <div className="col">
                    <div className>
                      <h3 className="text-white mb-1">{prifle_data?.employee?.name}</h3>
                      <p className="text-white">{prifle_data?.employee?.designation_name}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-10">
              <div className="row">
                <div className="col-md-3">
                  <ProfileNav sl={4} user_id={prifle_data?.employee?.user_id}/>
                </div>
                <div className="col-md-9">
                  <div className="card border-0 main-content-card profile-card-minius">
                    <div className="card-body">
                      <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3">
                        <h4 className="main-card-title mb-0 flex-grow-1">Attendance</h4>
                        
                      </div>
                   
                      <div className="table-responsive table-card">
                      <table className="table text-center table-hover table-centered align-middle table-nowrap mb-0">
                <thead className="text-muted table-main-bg">
                  <tr>
                    
                    <th scope="col">{t("date")}</th>
                    <th scope="col">{t("check_in")}</th>
                    <th scope="col">{t("check_out")}</th>
                    <th scope="col">{t("total_hour")}</th>
                    <th scope="col">{t("total_late")}</th>
                    <th scope="col">{t('sstatus')}</th>
                  
                  </tr>
                </thead>
                <tbody>
                {prifle_data?.data?.length>0 && prifle_data?.data?.map((row, index) => (
                  <tr>
                    
                    <td className="fs-12 my-1">{row.date}</td>
        
                    <td className="fs-12 my-1">  {row.check_in}</td>
                    <td className="fs-12 my-1"> {row.check_out} </td>
                    <td className="fs-12 my-1"> {row.total_hour} </td>
                 
                    <td className="fs-12 my-1">{row.total_late} </td>
                    <td>
                      {row.is_approved==1?<span className="badge bg-success">Approved</span>:<span className="badge bg-warning">Pending</span>}
                    </td>
                   
                  </tr>
                 ))}
                </tbody>
              </table>
                            </div>
                            <Paginate nextPage={nextPage} previousPage={previousPage} previousPageUrl={prifle_data?.prv_page_url} nextPageUrl={prifle_data?.next_page_url} postsPerPage={prifle_data?.per_page} paginate={paginate} totalPosts={prifle_data?.total_page} hasMorePage={prifle_data?.has_more_page} onFirstPage={prifle_data?.first_page} hasPage={prifle_data?.has_page} currentPage={prifle_data?.current_page} />


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    
        </>
    )

}
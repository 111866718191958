import API from "../../../Network/API";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import ProfileNav from "./ProfileNav";

export default function EmployeeProfile(){
  const [isLoading, setLoading] = useState(false);
  const [loading, setLoad] = useState(false);
  const dispatch = useDispatch();
  const prams = useParams();
  const [prifle_data, setProfile] = useState(null);

  useEffect(() => {
    API.get(`/v1/oauth/users/${prams.id}/show`).then((response) => {
        setProfile(response.data.data.employee)
        setLoading(false)
    }).catch((error) => {
        setLoading(true)
    });
}, [prams.id]);

    return (
        <>

          <div className="profile-foreground position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg" />
          </div>
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8 col-sm-12">
              <div className="pt-5 profile-wrapper">
                <div className="row g-4">
                  <div className="col-auto">
                    <div className="avatar-lg">
                      <img src={prifle_data?.image} alt="user-img" className="img-thumbnail rounded-circle" />
                    </div>
                  </div>
                  {/*end col*/}
                  <div className="col">
                    <div className>
                      <h3 className="text-white mb-1">{prifle_data?.name}</h3>
                      <p className="text-white">{prifle_data?.designation_name}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-10">
              <div className="row">
                <div className="col-md-3">
                  <ProfileNav sl={1} user_id={prifle_data?.user_id}/>
                </div>
                <div className="col-md-9">
                  <div className="card border-0 main-content-card profile-card-minius">
                    <div className="card-body">
                      <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3">
                        <h4 className="main-card-title mb-0 flex-grow-1">Personal</h4>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex align-items-center mb-2">
                            <p className="card-title mb-0 fs-14">About</p>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-duotone fa-calendar-days text-primary fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.dob_txt}</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">BIRTH DATE</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-regular fa-compass text-info fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.religion_name}</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">Religion</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-solid fa-venus-mars text-warning fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.gender_name}</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">Gender</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-thin fa-globe text-success fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.nationality_name}</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">Nationality</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-regular fa-ballot-check text-info fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.marital_status}</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">MARITAL STATUS</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-solid fa-id-card text-primary fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.id_type_name} : {prifle_data?.id_number}</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">ID NUMBER</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <hr className="my-2" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex align-items-center mb-2">
                            <p className="card-title mb-0 fs-14">Contact</p>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-solid fa-at text-primary fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.work_email}</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">Work Email</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-light fa-mobile text-primary fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.mobile_no}</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">Mobile No</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-duotone fa-phone text-primary fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.phone}</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">Phone</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-solid fa-at text-primary fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.private_email}</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">Private Email</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <hr className="my-2" />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex align-items-center mb-2">
                            <p className="card-title mb-0 fs-14">Emergency Contact</p>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-duotone fa-user text-primary fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.emergency_contact_name}</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">Name</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-light fa-mobile text-primary fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.emergency_contact_phone}</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">Mobile No</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-light fa-paperclip text-primary fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.relation_name}</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">Relationship</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-solid fa-at text-primary fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 mb-1">{prifle_data?.emergency_contact_email}</h5>
                                <p className="fs-13 text-muted mb-0 text-uppercase">Email</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <hr className="my-2" />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex align-items-center mb-2">
                            <p className="card-title mb-0 fs-14">Address</p>
                          </div>
                        </div>
                        <div className="col-sm-12 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-light fa-address-book text-secondary fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 text-muted  mb-1 text-uppercase">Prsent Address</h5>
                                <p className="fs-12 mb-0 ">{prifle_data?.present_address}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-light fa-address-book text-secondary fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14 text-muted mb-1 text-uppercase">PRESENT ADDRESS</h5>
                                <p className="fs-12  mb-0 ">{prifle_data?.parmanent_address}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                     

                        <div className="col-12">
                          <hr className="my-2" />
                        </div>
                      </div>
                      
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex align-items-center mb-2">
                            <p className="card-title mb-0 fs-14">Educational Information</p>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                            
                              <i className="fa-solid fa-diploma text-info fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14   mb-1 ">{prifle_data?.degree_name}</h5>
                                <p className="fs-13 mb-0 text-muted text-uppercase">DEGREE</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                          
                              <i className="fa-duotone fa-calendar text-warning fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14  mb-1 ">{prifle_data?.passing_year}</h5>
                                <p className="fs-13 text-muted  mb-0 text-uppercase">PASSING YEAR</p>
                              </div>
                            </div>
                          </div>
                        </div>
                     
                        <div className="col-sm-6 col-12">
                          <div className="d-flex align-items-center py-2">
                            <div className="avatar-xs flex-shrink-0 me-2 text-center">
                              <i className="fa-light fa-graduation-cap text-primary fs-22" />
                            </div>
                            <div className="flex-grow-1">
                              <div>
                                <h5 className="fs-14  mb-1 ">{prifle_data?.passing_point}</h5>
                                <p className="fs-13 text-muted  mb-0 text-uppercase">GRAD/PONIT</p>
                              </div>
                            </div>
                          </div>
                        </div>

                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    
        </>
    )

}
import { useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import API from '../../../Network/API';
import { EDIT_SALARY_SHEET_FETCH_SUCCESS } from '../../../Reducer/SalarySheetSlice';
import exportTableToPdf from '../../../CustomHooks/exportTableToPdf';
import exportTableToExcel from '../../../CustomHooks/exportTableToExcel';

export default function Payslip({ show, onHide, row_data }) {
    const [isLoading, setLoading] = useState(false);
    const modalRef1 = useRef()
    const dispatch = useDispatch();
    const Print = () => {
        //console.log('print');  
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }
    const handleExportToExcel = () => {
        exportTableToExcel('printablediv', 'Payslip');
    }

    const handleExportToPdf = () => {
        exportTableToPdf('printablediv', 'Payslip.pdf');
    }
    return (
        <>
            <Modal show={show} onHide={onHide} className='modal-lg modal-dialog-scrollable ' >
                <Modal.Header>
                    <h4 className="modal-title">Payslip (<span>{row_data?.month_name}-{row_data?.year}</span>)</h4>
                    <button type="button" className="btn-close me-2" data-bs-dismiss="modal" ref={modalRef1} onClick={onHide}></button>
                    <div className="dropdown">
                        <a className="btn btn-sm btn-light rounded-circle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="fa-solid fa-ellipsis"></i>
                        </a>

                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <li><a className="dropdown-item" href="#" onClick={() => handleExportToExcel()}><i className="fa-duotone fa-download"></i> Export</a></li>
                            <li><a className="dropdown-item" href="#" onClick={() => Print()}><i className="fa-duotone fa-print"></i> Print</a></li>
                            <li><a className="dropdown-item" href="#" onClick={() => handleExportToPdf()}><i className="fa-duotone fa-file-pdf"></i> Download as PDF</a></li>
                        </ul>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='row me-2 ms-2' id='printablediv'>
                    <div className='container mt-3'>
                        <table className='table'>
                            <tr>
                                <th width={`20%`}>Employee Name:</th>
                                <td colSpan={3}>{row_data?.name}</td>
                            </tr>
                            <tr>
                                <th>Designation:</th>
                                <td colSpan={3}>{row_data?.designation_name}</td>
                            </tr>
                            <tr>
                                <th>Month & Year:</th>
                                <td colSpan={3}>{row_data?.month_name}-{row_data?.year}</td>
                            </tr>

                        </table>
                        <table className='table table-bordered'>
                            <thead>
                                <tr>
                                    <th>Earning</th>
                                    <th></th>
                                    <th>Deduction</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Basic Salary</td>
                                    <td className='text-center'>
                                        {row_data?.basic_salary?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                    </td>
                                    <td>Provident Fund</td>
                                    <td className='text-center'>
                                        {row_data?.total_deduct_fund?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Housing</td>
                                    <td className='text-center'>
                                        {row_data?.house?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                    </td>
                                    <td>GOSI</td>
                                    <td className='text-center'>
                                        {row_data?.total_deduct_tax?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Transportation</td>
                                    <td className='text-center'>
                                        {row_data?.transportation?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                    </td>
                                    <td>Absent Deduction</td>
                                    <td className='text-center'>
                                        {row_data?.total_deduct_absent?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Medical Allowance</td>
                                    <td className='text-center'>
                                        {row_data?.medical_allowance?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                    </td>
                                    <td>Lateness Deduction</td>
                                    <td className='text-center'>
                                        {row_data?.deduct_lateness?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                    </td>
                                </tr>

                                <tr>
                                    <td>Others Allowance</td>
                                    <td className='text-center'>
                                        {row_data?.other_allowance?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                    </td>
                                    <td>Loan Deduction </td>
                                    <td className='text-center'>
                                        {row_data?.total_deduct_loan?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                    </td>
                                </tr>

                                <tr>
                                    <td>Total Addition</td>
                                    <td className='text-center'>
                                        {row_data?.total_addition?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                    </td>
                                    <td>Total Deduction</td>
                                    <td className='text-center'>
                                        {row_data?.total_deduction?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td className='text-center'>

                                    </td>
                                    <td>Net Payable</td>
                                    <td className='text-center'>
                                        {row_data?.net_payable?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='row mt-3'>
                                        <div className='col-md-6'><span style={{borderTop:'1px solid #000000'}}>Employee Signature</span></div>
                                        <div className='col-md-6 text-end'><span style={{borderTop:'1px solid #000000'}}>Authorized Signature</span></div>
                    </div>
                    </div>

                </Modal.Body>
               

            </Modal>
        </>
    )
}
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import API from "../../../Network/API";
import { toast } from "react-hot-toast";
import BaseUrl from "../../../Network/BaseUrl";
export default function AddLoanRequest() {
    const selector = useSelector((state) => state.companyReducer)
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const formRef = useRef();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [categories, setCategory] = useState([]);
    const [amount, setAmount] = useState(0)
    const [duration, setDuration] = useState(1)
    const [step, setStepNumber] = useState(1)
    const year = (new Date()).getFullYear();
    const yearLast = 2022;
    const years = Array.from(new Array(20), (val, index) => index + yearLast);
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
    const echarts_react_1 = useRef();
    const [month_id, setMonthId] = useState(currentMonth)
    const [year_id, setYear] = useState(year)
    useEffect(() => {
        setLoading(true)
        API.get(`/v1/oauth/loan_types`).then((response) => {
            setCategory(response.data.data)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
        });


    }, []);
    const handleSubmit = (event) => {
        event.preventDefault();
        setSaveLoading(true)
        // Access form data using the formRef

        const fd = new FormData();

        fd.append(`month`, event.target.month.value)
        fd.append(`duration`, event.target.duration.value)
        fd.append(`amount`, event.target.amount.value)
        fd.append(`loan_type_id`, event.target.category_id.value)
        fd.append(`message`, event.target.remark.value)

        BaseUrl.post('/v1/oauth/loan_requests/save', fd)
            .then((response) => {
                setSaveLoading(false)
                toast.success(response.data.data.message);
                navigate(-1)
            })
            .catch((err) => {
                setSaveLoading(false)
                toast.error(err.response.data.message);
            });


    }

    const numbers = [];

    for (let i = 1; i <= 12; i++) {
      numbers.push(i);
    }
    return (
        <>
            <div className="page-title-box">
                <div className="page-title-breadcrumb">
                    <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                        <li className="breadcrumb-item"><a href="#">My Requests</a></li>
                        <li className="breadcrumb-item active">Add Loan Request</li>
                    </ol>
                </div>
            </div>
            <div className="row">
                <div className="col-md-10 offset-1">

                    <div className="card border-0 main-content-card">
                        <div className="card-body">
                            <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3">
                                <h4 className="main-card-title mb-0 flex-grow-1">
                                    <Link to={`/loan_request`} className="text-dark">
                                        <i className="fa-duotone fa-arrow-left me-1" />
                                        Add Loan Request
                                    </Link>
                                </h4>
                                <div className="flex-shrink-0 text-end">
                                    <h4 className="fs-16 fw-bold mb-0">{selector?.data?.currency_code} {amount.toLocaleString()}</h4>
                                    <p className="text-secondary fw-bold mb-0">Loan Amount</p>
                                </div>
                            </div>
                            <form onSubmit={handleSubmit} ref={formRef}>
                                <div className="c-form-area pb-3">
                                    <div className="row align-items-end mb-3">

                                    <div className="col-sm-6 col-md-7 mb-3">
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i class="fa-light fa-list-check text-info"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="category" className="form-label fs-14 text-secondary mb-1 text-uppercase">Loan Types*</label>
                                                    <select required className="form-select rounded-0" id="category" name="category_id">
                                                        <option value={``} selected>Choose...</option>
                                                        {!loading && categories?.map((row, index) => (
                                                            <option value={row.id}>{row.name}</option>
                                                        ))}
                                                    </select>
                                                   
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className={`col-sm-6 col-md-5`}>
                                    
                                            
                                        </div>

                                        <div className="col-sm-6 col-md-7">
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i className="fa-duotone fa-coins text-primary" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="amount" className="form-label fs-14 text-secondary mb-1 text-uppercase">AMOUNT ({selector?.data?.currency_code})*</label>
                                                    <input min={1}  onChange={(e)=>setAmount(e.target.value)} type="text" className="form-control rounded-0" required placeholder="Enter Amount" id="amount" name="amount" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-5">
                                            <p className="mb-0 text-secondary">
                                                Select the loan amount <br />
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row align-items-end mb-3">
                                        <div className="col-sm-6 col-md-7">
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i className="fa-duotone fa-clock text-info" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="DURATION" className="form-label fs-14 text-secondary mb-1 text-uppercase">DURATION*</label>
                                                    <select  onChange={(e)=>setDuration(e.target.value)} className="form-select rounded-0" id="DURATION" name="duration" required>
                                                        <option selected>Choose...</option>
                                                        {numbers.map((numb, index) => (
                                                        <option key={index} value={numb} >{numb} Months</option>
                                                    ))}
                                                      
                                                  
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-5">
                                            <p className="mb-0 text-secondary">Select the loan duration</p>
                                            <p className="mb-0 text-secondary">(1-12 months)</p>
                                        </div>
                                    </div>
                                    <div className="row align-items-end mb-3">
                                        <div className="col-sm-6 col-md-7">
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i className="fa-duotone fa-calendar text-success" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label htmlFor="MONTH" className="form-label fs-14 text-secondary mb-1 text-uppercase">PAYMENT MONTH*</label>
                                                    <select className="form-select rounded-0" id="MONTH" name="month" required>
                                                        <option selected>Choose...</option>
                                                        {months.map((month, index) => (
                                                        <option key={index} value={month} selected={currentMonth == month ? true : false}>{month}-{year}</option>
                                                    ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-5">
                                            <p className="mb-0 text-secondary">Select the payroll period in which you</p>
                                            <p className="mb-0 text-secondary">want to add loan amount</p>
                                        </div>
                                    </div>
                                    <div className="row align-items-end mb-4">
                                        <div className="col-sm-6 col-md-7">
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i className="fa-duotone fa-wallet text-primary" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <label className="form-label fs-14 text-secondary mb-1 text-uppercase">MONTHLY INSTALMENT</label>
                                                    <h6 className="mb-0">{selector?.data?.currency_code} {(Number(amount)/Number(duration)).toLocaleString()}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-5">
                                            <p className="mb-0 text-secondary">How much will be deducted each</p>
                                            <p className="mb-0 text-secondary">month to repay the loan</p>
                                        </div>
                                    </div>
                                    <div className="row align-items-end mb-3">
                                        <div className="col-sm-12">
                                            <div className="d-flex align-items-end">
                                                <div className="flex-shrink-0 form-icon-avatar" />
                                                <div className="flex-grow-1 ms-3">
                                                    <h6 className="fs-15 text-secondary fw-bold">LOAN  POLICY</h6>
                                                    <p className="mb-1 fw-bold fs-13">
                                                        <i className="fa-regular fa-check me-1 text-success fs-15" />
                                                        <span className="text-secondary">Minimum length of  service: 1 month(s)</span>
                                                    </p>
                                                    <p className="mb-1 fw-bold fs-13">
                                                        <i className="fa-regular fa-check me-1 text-success fs-15" />
                                                        <span className="text-secondary">Maximum amount: 3 x basic salary</span>
                                                    </p>
                                                    <p className="mb-1 fw-bold fs-13">
                                                        <i className="fa-regular fa-check me-1 text-success fs-15" />
                                                        <span className="text-secondary">Maximum loan duration: 12 months</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row align-items-end mb-3">
                                        <div className="col-sm-12">
                                            <div className="d-flex align-items-start">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i className="fa-duotone fa-message-captions text-secondary" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <textarea name="remark" className="form-control rounded-0" id="exampleFormControlTextarea1" rows={3} placeholder="Write a message" defaultValue={""} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-end mb-4">
                                        <button type="submit" className="btn btn-success rounded-pill">
                                            <i className="fa-light fa-paper-plane-top me-1" />Send Request {saveLoading ? (
                                                <span
                                                    className="spinner-border spinner-border-sm ml-5"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            ) : (
                                                <span></span>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>{/* end card-body */}
                    </div>
                </div>
            </div>
        </>
    )
}
import { useRef, useState } from "react";
import toast from 'react-hot-toast';
import { useDispatch } from "react-redux";
import API from "../../../../Network/API";


import { ADD_VACATION_CATEGORY_FETCH_SUCCESS } from "../../../../Reducer/VacationCategorySlice";

export default function AddVacationCategory() {

    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const inpRef = useRef()
    const modalRef1 = useRef()

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const data = new FormData();
        data.append('name', event.target.name.value)
        data.append('balance', event.target.balance.value)
        if (event.target.is_paid.checked) {
            data.append('is_paid', 1)
        } else {
            data.append('is_paid', 0)
        }

        if (event.target.is_attachment.checked) {
            data.append('is_attachment',1)
        } else {
            data.append('is_attachment', 0)
        }

        const responsea = API.post('/v1/oauth/vacation_categories/save', data);
        toast.promise(responsea, {
            loading: 'Saving ...',
            success: (response) => {
                setLoading(false);
                modalRef1.current.click();
                document.getElementById("add_country_form").reset()
                dispatch(ADD_VACATION_CATEGORY_FETCH_SUCCESS(response.data.data))
                if (response.status === 500) throw new Error('server error');
                return `${response.data.message}`;
            },
            error: (e) => `${e.response.data.errors.name ?? e.response.data.message}`,
            duration: 6000,
        })
        setLoading(false)
    }

    return (
        <>
            <div class="modal" id="myModal">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Add Vacation Types</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" ref={modalRef1}></button>
                        </div>
                        <div class="modal-body">
                            <form onSubmit={handleSubmit} id="add_country_form">
                                
                                <div className="row">
                                    <div className="col-sm-12 mb-3">
                                        <label htmlFor="name" className="form-label c-label">Vacation Type Name <em className="text-danger">*</em></label>
                                        <input ref={inpRef} name="name" type="text" className="form-control rounded-pill" id="name" placeholder="" required />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 mb-3">
                                        <label htmlFor="name" className="form-label c-label">Vacation Days for all employee <em className="text-danger">*</em></label>
                                        <input type="text" name="balance" className="form-control rounded-pill" id="balance" placeholder="0" required />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12 mb-1">
                                        <label htmlFor="is_paid" className="form-label c-label"><input type="checkbox" name="is_paid" id="is_paid" defaultChecked /> <span className="es-2">This Leave policy is paid</span></label>

                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-sm-12 mb-2">
                                        <label htmlFor="is_attachment" className="form-label c-label"><input type="checkbox" name="is_attachment" id="is_attachment" /> <span className="es-2">Submitting an attachment is mandatory when apply vacation against this policy</span></label>

                                    </div>
                                </div>


                                <div class="modal-footer">
                                    <button type="submit" class="btn btn-success">Submit {isLoading ? (
                                        <span
                                            className="spinner-border spinner-border-sm ml-5"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    ) : (
                                        <span></span>
                                    )}</button>
                                </div>
                            </form>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
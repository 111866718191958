export default function HomeGrid(props) {
    return (
        <>
            <div className="col-md-2 text-center col-4">
                <div className="grid">
                    <div className={`grid-icon  d-flex justify-items-center`}>
                        <div className={`ion_box ${props.color} ${props.class}`}>
                            <i className={props.icon}></i>
                        </div>

                    </div>
                    <div className="grid-text">
                        <h6>{props.title}</h6>
                    </div>
                    <div className="grid-number">
                        <h6 className={props.valueColor}>{props.value}</h6>
                    </div>
                </div>
            </div>
        </>
    );
}
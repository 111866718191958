import { useDispatch, useSelector } from "react-redux";
import API from "../../../../Network/API";
import { useEffect, useState } from "react";
import Paginate from "../../../../services/Paginate";

import { DELETE_DEPARTMENT, DEPARTMENT_FETCH_ERROR, DEPARTMENT_FETCH_SUCCESS } from "../../../../Reducer/DepartmentSlice";
import { toast } from "react-hot-toast";
import AddEventApproval from "./AddEventApproval";
import EditEventApproval from "./EditEventApproval";
import { EVENT_APPROVAL_FETCH_ERROR, EVENT_APPROVAL_FETCH_SUCCESS } from "../../../../Reducer/EventApprovalSlice";
import { Link, useNavigate } from "react-router-dom";


export default function EventApproval() {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [nxtPage, setNextPage] = useState(null);
  const [search_value, setSearchValue] = useState(null);
  const [data_value, setDataValue] = useState(null);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleShow = (row) => {
    setShow(!show)
    setDataValue(row)
  };


  useEffect(() => {
    all_data('')
  }, []);

  const all_data = (name) => {
    API.get('/v1/oauth/event_approvals?name=' + name).then((response) => {
      dispatch(EVENT_APPROVAL_FETCH_SUCCESS(response.data.data))
      setLoading(false)
    }).catch((error) => {
      setLoading(true)
      EVENT_APPROVAL_FETCH_ERROR();
    });
  }

  const paginate = (pageNumber) => {
    API.get('/v1/oauth/event_approvals?page=' + pageNumber).then((response) => {
      dispatch(EVENT_APPROVAL_FETCH_SUCCESS(response.data.data))
      setLoading(false)
    }).catch((error) => {
      setLoading(true)
      EVENT_APPROVAL_FETCH_ERROR();
    });
  };

  const previousPage = (nxtPage) => {
    setNextPage(nxtPage)
  }

  const nextPage = (nxtPage) => {
    setNextPage(nxtPage)
  }

  const showEvent = (id) => {

  }

  const selector = useSelector((state) => state.eventApprovalReducer)

  return (
    <>
      <div className="container-fluid">
        <div className="page-title-box">
          <div className="page-title-breadcrumb">
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item"><a href="#">Home</a></li>
              <li className="breadcrumb-item active">Approvals</li>
            </ol>
          </div>
        </div>
        <div className="card border-0 main-content-card">
          <div className="card-body">
            <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3 main-content-card-header">
              <h4 className="main-card-title mb-0 flex-grow-1"><a onClick={() => navigate(-1)} href="#" class="text-dark">
                                            <i class="fa-duotone fa-arrow-left me-1"></i></a>Approvals</h4>
              <div className="flex-shrink-0">
                <div className="app-search">
                  <div className="input-group">
                    <div className="position-relative">
                      <input onChange={(e) => all_data(e.target.value)} type="text" className="form-control" placeholder="Search..." autoComplete="off" id="search-options" />
                      <span className="fa-light fa-search search-widget-icon" />
                    </div>
                    <button className="btn btn-add" type="button" id="button-addon2" data-bs-toggle="modal" data-bs-target="#myModal"><i className="fa-regular fa-plus" /></button>
                  </div>
                </div>
              </div>
            </div>
            <div className="live-preview table-responsive">
              <table className="table table-hover table-centered align-middle table-nowrap mb-0 text-center">
                <thead>
                  <tr>
                    <th>SL</th>
                    <th>Title</th>
                    <th>Total Approval</th>
                    <th width={'10%'}>Teams</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!selector?.loading && selector?.main_data?.map((row, index) => (
                    <tr>
                      <td>{index}</td>
                      <td>{row.name}</td>
                      <td>{row.total_users}</td>
                      <td>
                        <div className="avatar-group">
                          {row?.users?.map((user, k) => (
                            <div className="avatar-group-item">
                              <Link to={`/user/profile/${user.uid}`} className="d-inline-block" data-bs-toggle="tooltip" data-bs-placement="top" title data-bs-original-title="Nina Schmidt">
                                <img src="/assets/image/avatar-8.jpg" alt="" className="rounded-circle avatar-xxs" />
                              </Link>
                            </div>
                          ))
                          }
                          {row?.users?.length > 3 ?
                            <div className="avatar-group-item">
                              <a href="#">
                                <div className="avatar-xxs">
                                  <span className="avatar-title rounded-circle bg-primary text-white">
                                    {row?.users?.length}
                                  </span>
                                </div>
                              </a>
                            </div>
                            : ''}
                        </div>
                      </td>
                      <td>
                        <div class="dropdown action-dropdown">
                          <a class="btn btn-sm  py-0" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa-light fa-ellipsis-stroke-vertical"></i>
                          </a>
                          <ul class="dropdown-menu border-1 shadow-sm">
                            <li><a class="dropdown-item" href="#" onClick={() => handleShow(row)}><i class="fa-light fa-plus me-1" ></i>Add Approval</a></li>
                            <li><Link class="dropdown-item" to={`/approvals/${row.id}`}><i class="fa-light fa-eye me-1"></i>View</Link></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>


              <Paginate nextPage={nextPage} previousPage={previousPage} previousPageUrl={selector?.data.prv_page_url} nextPageUrl={selector?.data.next_page_url} postsPerPage={selector?.data.per_page} paginate={paginate} totalPosts={selector?.data.total_page} hasMorePage={selector?.data.has_more_page} onFirstPage={selector?.data.first_page} hasPage={selector?.data.has_page} currentPage={selector?.data.current_page} />

            </div>
          </div>{/* end card-body */}
        </div>
      </div>

      <AddEventApproval show={show} onHide={handleShow} row_data={data_value} />

    </>
  )
}
import React from 'react';
const Paginate = ({ postsPerPage, totalPosts, paginate, previousPage, nextPage,previousPageUrl, nextPageUrl, hasMorePage, onFirstPage, hasPage, currentPage }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }
    if (!hasPage) {
        return (<></>)
    }

    return (
        <div className="pagination-container mt-1">
            <ul className="pagination">
                <li onClick={onFirstPage ? `` : ()=>previousPage(previousPageUrl)} className={`page-item ${onFirstPage ? 'disabled' : ''}`}>
                    <a class="page-link" href="#"> Prev </a>
                </li>
                {pageNumbers.map((number) => (

                    <>
                        {number == currentPage ?
                            <li
                                key={number}
                                className="page-item active">
                                <a class="page-link" href="#"> {number} </a>
                            </li>
                            :
                            <li
                                key={number}
                                onClick={() => paginate(number)}
                                className="page-item">
                                <a class="page-link" href="#"> {number} </a>
                            </li>
                        }

                    </>

                ))}

                {hasMorePage?
                <li onClick={()=>nextPage(nextPageUrl)} className="page-item">
                    <a class="page-link" href="#"> Next </a>
                </li>
                :
                <li className="page-item disabled">
                    <a class="page-link" href="#"> Next </a>
                </li>
                }
            </ul>
        </div>
    );
};

export default Paginate;
import { useEffect, useRef, useState } from "react";
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from "react-redux";
import API from "../../../../Network/API";
import Modal from 'react-bootstrap/Modal';
import { EDIT_SHIFT_FETCH_SUCCESS } from "../../../../Reducer/ShiftSlice";
import { Link, useNavigate, useParams } from "react-router-dom";

export default function EditBranch() {
    const [name, setName] = useState("");
    const [lat, setLat] = useState("");
    const [long, setLong] = useState("");
    const [area, setArea] = useState("");
    const [row_data, setRowData] = useState("");
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const inpRef = useRef()
    const modalRef1 = useRef()
    const navigate = useNavigate();
    const prams = useParams();
    const [address, setAddress] = useState("");
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        var data = {
            'name': name == "" ? row_data?.name : name,
            'lat': lat == "" ? row_data?.lat : lat,
            'long': long == "" ? row_data?.long : long,
            'area': area == "" ? row_data?.area : area,
            'address': address == "" ? row_data?.address : address,
            '_method':'PUT'
        }
        const responsea = API.post('/v1/oauth/branch/' + row_data.id + '/update', data);
        toast.promise(responsea, {
            loading: 'Saving ...',
            success: (response) => {
                setLoading(false);
              
                dispatch(EDIT_SHIFT_FETCH_SUCCESS(response.data.data))
                if (response.status === 500) throw new Error('server error');
                return `${response.data.message}`;
            },
            error: (e) => `${e.response.data.errors.name ?? e.response.data.message}`,
            duration: 6000,
        })
        setLoading(false)
    }

    useEffect(() => {
        API.get('/v1/oauth/branch/' + prams.id+'/edit').then((response) => {
            setRowData(response.data.data)
            setLoading(false)
          }).catch((error) => {
            setLoading(true)
          
          });
      }, [prams.id]);

    return (
        <>
        <div className="page-title-box">
                <div className="page-title-breadcrumb">
                    <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                        <li className="breadcrumb-item"><a href="#">Settings</a></li>
                        <li className="breadcrumb-item"><a href="#">Branch</a></li>
                        <li className="breadcrumb-item active">Edit</li>
                    </ol>
                </div>
            </div>
            <div className="card border-0 main-content-card">
                <div className="card-body">
                    <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3 main-content-card-header">
                        <h4 className="main-card-title mb-0 flex-grow-1"><Link onClick={() => navigate(-1)}><i class="fa-duotone fa-arrow-left me-1"/></Link> Edit Branch</h4>
                    </div>
                    <div className="live-preview">
                    <form onSubmit={handleSubmit} id="add_country_form">
                        <div className="row">
                            <div className="col-sm-12 mb-3">
                                <label htmlFor="name" className="form-label c-label">Name</label>
                                <input defaultValue={row_data?.name} ref={inpRef} onChange={(e) => setName(e.target.value)} type="text" className="form-control rounded-0" id="name" placeholder required />
                            </div>
                            <div className="col-sm-12 mb-3">
                                    <label htmlFor="address" className="form-label c-label">Address</label>
                                    <textarea defaultValue={row_data?.address} onChange={(e) => setAddress(e.target.value)} type="text" className="form-control rounded-0" id="address" placeholder required />
                                </div>
                            <div className="col-sm-6 mb-3">
                                <label htmlFor="name" className="form-label c-label">Latitude</label>
                                <input defaultValue={row_data?.lat} onChange={(e) => setLat(e.target.value)} type="text" className="form-control rounded-0" id="latitude" name="latitude" placeholder required />
                            </div>

                            <div className="col-sm-6 mb-3">
                                <label htmlFor="name" className="form-label c-label">Longitude</label>
                                <input defaultValue={row_data?.long} onChange={(e) => setLong(e.target.value)} type="text" className="form-control rounded-0" id="longitude" name="longitude" placeholder required />
                            </div>
                            <div className="col-sm-12 mb-3">
                                <label htmlFor="name" className="form-label c-label">Distance(Earth's radius (Meters)) How many distance will be allow for check-in</label>
                                <input defaultValue={row_data?.area} onChange={(e) => setArea(e.target.value)} type="text" className="form-control rounded-0" id="area" name="area" placeholder required />
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="submit" class="btn btn-success">Submit {isLoading ? (
                                <span
                                    className="spinner-border spinner-border-sm ml-5"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            ) : (
                                <span></span>
                            )}</button>
                        </div>
                    </form>
                    </div>
                </div>
                </div>
        </>
    )
}
import { createSlice } from '@reduxjs/toolkit';
export const eventApprovalSlice = createSlice({
    name:'eventApproval',
    initialState:{
        loading: false,
        data: {

        },
        main_data:[],
        users_data:[],
        error: false,
    },
    reducers:{
        EVENT_APPROVAL_FETCH_START:(state)=>{
            state.loading=true
        },
        EVENT_APPROVAL_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
            state.main_data=action.payload.data
        },
        ADD_EVENT_APPROVAL_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.main_data = [action.payload,...state.main_data]
        },
        EDIT_EVENT_APPROVAL_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            const newArray = state.main_data.filter(todo => todo.id !==action.payload.id)
             state.main_data = [...newArray,action.payload]
        },
        DELETE_EVENT_APPROVAL:(state,action)=>{
            const filterPosts = state.main_data.filter(
                (post) => post.id !== action.payload
              );
            state.loading = false
            state.main_data=filterPosts
        },
        EVENT_APPROVAL_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const {EDIT_EVENT_APPROVAL_FETCH_SUCCESS,DELETE_EVENT_APPROVAL, EVENT_APPROVAL_FETCH_START,EVENT_APPROVAL_FETCH_SUCCESS,EVENT_APPROVAL_FETCH_ERROR,ADD_EVENT_APPROVAL_FETCH_SUCCESS }=eventApprovalSlice.actions;
export default eventApprovalSlice.reducer;
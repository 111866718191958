import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { EMPLOYEE_FETCH_ERROR, EMPLOYEE_FETCH_SUCCESS } from "../../../Reducer/EmployeeSlice";
import API from "../../../Network/API";
import Paginate from "../../../services/Paginate";
import PostLoader from '../../../assets/loader.gif';
import Agent from "../../../Network/Agent";
export default function Employee() {

  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [nxtPage, setNextPage] = useState(null);
  const [search_value, setSearchValue] = useState(null);
  const [data_value, setDataValue] = useState(null);
  const [show, setShow] = useState(false);

  
  useEffect(() => {
    Agent.EmployeeAgent.all(dispatch);
  }, []);

  const all_data = (name) => {
    API.get('/v1/oauth/users?name='+name).then((response) => {
      dispatch(EMPLOYEE_FETCH_SUCCESS(response.data.data))
      setLoading(false)
    }).catch((error) => {
      setLoading(true)
      EMPLOYEE_FETCH_ERROR();
    });
  }
  
  const paginate = (pageNumber) => {
    API.get('/v1/oauth/users?page='+pageNumber).then((response) => {
      dispatch(EMPLOYEE_FETCH_SUCCESS(response.data.data))
    }).catch((error) => {
      EMPLOYEE_FETCH_ERROR();
    });
  };

  const previousPage = (nxtPage) => {
    setNextPage(nxtPage)
  }

  const nextPage = (nxtPage) => {
    setNextPage(nxtPage)
  }

  const selector = useSelector((state) => state.employeeReducer)
 
 
  return (
    <>
    <div className="container-fluid">
      <div className="page-title-box">
        <div className="page-title-breadcrumb">
          <ol className="breadcrumb m-0">
            <li className="breadcrumb-item"><a href="#">Home</a></li>
  
            <li className="breadcrumb-item active">Employee</li>
          </ol>
        </div>
      </div>
      <div className="row align-items-center py-2">
        <div className="col-sm">
          <div className="d-flex align-items-center">
            <h5 className="card-title mb-0 me-3">{selector?.data?.total_page} Employee</h5>
            <button type="button" className="btn bg-white shadow-sm">
              <i className="fa-light fa-filter me-1" /> Filter
            </button>
          </div>
        </div>
        <div className="col-sm-auto">
          <div className="d-flex gap-1 flex-wrap">
            <button type="button" className="btn bg-white shadow-sm">
              <i className="fa-light fa-download" />
            </button>
            <button type="button" className="btn bg-white shadow-sm">
              <i className="fa-light fa-print" />
            </button>
            <Link to={`/employee/create`} className="btn add-top-btn">
              <i className="fa-light fa-plus me-1" /> Add Employee</Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card border-0 shadow-sm">
            <div className="table-responsive table-card">
              <table className="table custom_table table-hover table-centered align-middle table-nowrap mb-0">
                <thead className="text-muted table-main-bg">
                  <tr>
                    <th scope="col">Employee Name</th>
                    <th scope="col">Department</th>
                    <th scope="col">Date of Joining</th>
                    <th scope="col">Manager</th>
                    <th scope="col">Role</th>
                    <th scope="col">Rating</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                {!selector?.loading && selector?.main_data?.map((row, index) => (
                  <tr>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-2">
                          <img src={row.image} alt="" className="avatar-xs rounded-circle" />
                        </div>
                        <div className="flex-grow-1">
                          {row.name}
                          <br/>
                          <span className="text-muted" style={{fontSize:'11px'}}>{row.employee_id}</span>
                        </div>
                        
                      </div>
                    </td>
                  
                   
                    <td>
                      <h5 className="fs-14 my-1">
                        <a href="#" className="text-reset">{row.department_name} </a>
                      </h5>
                      <span className="text-muted fs-10">Designation: {row.designation_name}</span>
                      <p className="text-muted fs-10 p-b- mb-0">Branch: {row.branch_name}</p>
                    </td>
                    <td>
                      <span className="fs-14 my-1">{row.join_date}</span>
                      <p className="text-muted fs-10 p-b- mb-0">Job Status: Permanent</p>
                    </td>
                    <td>
                    <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-2">
                          <img src={row.manager_image} alt="" className="avatar-xs rounded-circle" />
                        </div>
                        <div className="flex-grow-1">
                          {row.manager}
                          <br/>
                          <span className="text-muted" style={{fontSize:'11px'}}>{row.manager_office_id}</span>
                        </div>
                        
                      </div>
                    </td>
                    <td>{row.role_name}</td>
                    <td>
                    <span class="badge bg-light text-body fs-12 fw-medium">
                     <i class="fa-light fa-star me-1"></i>{row.avg_rating}
                     </span>
                    </td>
                    <td>
                      {row.status_id==1?<span className="badge bg-success">Active</span>:<span className="badge bg-danger">Inactive</span>}
                    </td>
                    <td>
                    
                        <div className="dropdown">
                          <button className="btn btn-light btn-sm dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="fa-light fa-ellipsis" />
                          </button>
                          <ul className="dropdown-menu dropdown-menu-end border-0 shadow-sm fs-14" style={{}}>
                            <li>
                              <Link to={`/employee/${row.user_id}/view`} className="dropdown-item">
                                <i className="fa-light fa-eye me-2 text-muted" /> View
                              </Link>
                            </li>
                            <li>
                              <Link to={`/employee/${row.user_id}/edit`} className="dropdown-item" >
                                <i className="fa-light fa-pencil me-2 text-muted" /> Edit
                              </Link>
                            </li>
                         
                            <li className="dropdown-divider" />
                            
                            <li>
                              <Link to={`/employee/${row.user_id}/add_performance`} className="dropdown-item remove-list" href="#">
                                <i className="fa-light fa-star me-2 text-muted" /> Add Performance
                              </Link>
                            </li>
                            <li>
                              <Link to={`/employee/${row.user_id}/promotion`} className="dropdown-item remove-list" href="#">
                                <i className="fa-light fa-plus-circle me-2 text-muted" /> Add Promotion
                              </Link>
                            </li>
                            
                            <li>
                              <a className="dropdown-item remove-list" href="#">
                                <i className="fa-light fa-plus-circle me-2 text-muted" /> Add Termination
                              </a>
                            </li>

                            <li>
                              <a className="dropdown-item remove-list" href="#">
                                <i className="fa-light fa-edit me-2 text-muted" /> Status
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item remove-list" href="#">
                                <i className="fa-light fa-cog me-2 text-muted" /> Attendance Settings
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item remove-list" href="#">
                                <i className="fa-light fa-cog me-2 text-muted" /> Provident Fund Settings
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item remove-list" href="#">
                                <i className="fa-light fa-cog me-2 text-muted" /> Payroll Settings
                              </a>
                            </li>
                          </ul>
                        </div>
                
                    </td>
                  </tr>
                 ))}
                </tbody>
              </table>
            </div>
            <Paginate nextPage={nextPage} previousPage={previousPage} previousPageUrl={selector?.data.prv_page_url} nextPageUrl={selector?.data.next_page_url} postsPerPage={selector?.data.per_page} paginate={paginate} totalPosts={selector?.data.total_page} hasMorePage={selector?.data.has_more_page} onFirstPage={selector?.data.first_page} hasPage={selector?.data.has_page} currentPage={selector?.data.current_page} />

          </div>
        </div>
      </div>

      </div>
    </>
  )
}
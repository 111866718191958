import { createSlice } from '@reduxjs/toolkit';

export const myPayslipSlice = createSlice({
    name:'myPayslip',
    initialState:{
        loading: false,
        data: {

        },
        main_data:[],
        error: false,
    },
    reducers:{
        MY_PAYSLIP_FETCH_START:(state)=>{
            state.loading=true
        },
        MY_PAYSLIP_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
            state.main_data=action.payload.data
        },
        ADD_MY_PAYSLIP_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.main_data = [action.payload,...state.main_data]
        },
        EDIT_MY_PAYSLIP_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            const newArray = state.main_data.filter(todo => todo.id !==action.payload.id)
             state.main_data = [...newArray,action.payload]
        },
        DELETE_MY_PAYSLIP:(state,action)=>{
            const filterPosts = state.main_data.filter(
                (post) => post.id !== action.payload
              );
            state.loading = false
            state.main_data=filterPosts
        },
        MY_PAYSLIP_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const {DELETE_MY_PAYSLIP,EDIT_MY_PAYSLIP_FETCH_SUCCESS, MY_PAYSLIP_FETCH_START,MY_PAYSLIP_FETCH_SUCCESS,MY_PAYSLIP_FETCH_ERROR,ADD_MY_PAYSLIP_FETCH_SUCCESS }=myPayslipSlice.actions;
export default myPayslipSlice.reducer;
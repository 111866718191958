import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import API from "../Network/API";
import { v4 as uuidv4 } from 'uuid';
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { MY_PROFILE_FETCH_SUCCESS } from "../Reducer/MyProfileSlice";
import { Link, useNavigate } from "react-router-dom";
import Agent from "../Network/Agent";
import { PERMISSION_FETCH_SUCCESS } from "../Reducer/PermissionSlice";
import { COMPANY_FETCH_SUCCESS } from "../Reducer/CompanySlice";
export default function Nav() {
    const com_selector = useSelector((state) => state.companyReducer)
    const { user, accessToken } = useSelector((state) => state.authReducer);
    const { i18n } = useTranslation();
    const [ipAddress, setIpAddress] = useState('');
    const [active_menu, setActiveMenu] = useState(false);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [accuracy, setAccuracy] = useState(null);
    const deviceId = uuidv4();
    const dispatch=useDispatch();
    useEffect(() => { 
        Agent.EmployeeAgent.all(dispatch);
         fetchCoordinates();
         fetchIpAddress();
         dashboard()
    }, []);

    const fetchIpAddress = async () => {
        try {
          const response = await fetch('https://api.ipify.org?format=json');
          const data = await response.json();
          setIpAddress(data.ip);
        } catch (error) {
        //   console.error('Error fetching IP address:', error);
        }
      }
    const fetchCoordinates = () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setLatitude(position.coords.latitude);
              setLongitude(position.coords.longitude);
              setAccuracy(position.coords.accuracy);
            },
            (error) => {
              console.error('Error retrieving coordinates:', error);
            }
          );
        } else {
          console.error('Geolocation is not supported by this browser.');
        }
      }

    const ToggleSwitch = () => (
        setActiveMenu(!active_menu)
    )

    if (i18n.language == 'ar') {
        document.body.classList.add(
            'rtl'
        );
    } else {
        if (document.body.classList.contains('rtl')) {
            document.body.classList.remove('rtl');
        }
    }

    if (active_menu) {
        document.body.classList.add(
            'sidebar-enable'
        );
        document.body.classList.add(
            'offcanvas-active'
        );

    } else {
        if (document.body.classList.contains('offcanvas-active')) {
            document.body.classList.remove('offcanvas-active');
        }
        if (document.body.classList.contains('sidebar-enable')) {
            document.body.classList.remove('sidebar-enable');
        }
    }

    const _checkIn = () => {
        const confirmed = window.confirm('Are you sure you want to check In?');
        if(!confirmed){
            return;
        }
        const fd = new FormData();
        fd.append('lat', latitude)
        fd.append('lang', longitude)
        fd.append('accuracy', accuracy)
        fd.append('ip_address', ipAddress)
        fd.append('device_mac_id', deviceId)
        fd.append('type', 1)
        fd.append('device_name', navigator.userAgent)
        API.post(`/v1/oauth/check_in`,fd, {
            onUploadProgress: progressEvent => {
                // console.log("Progress: " + progressEvent.loaded / progressEvent.total);
            }
        }).then(res => {
            dashboard()
                toast.success(res.data.message)
            }).catch((err) => {
                toast.error(err.response.data.message);
            });
    }

    const _checkOut = () => {
        const confirmed = window.confirm('Are you sure you want to check Out?');
        if(!confirmed){
            return;
        }
        const fd = new FormData();
        fd.append('lat', latitude)
        fd.append('lang', longitude)
        fd.append('accuracy', accuracy)
        fd.append('ip_address', ipAddress)
        fd.append('device_mac_id', deviceId)
        fd.append('type', 1)
        fd.append('device_name', navigator.userAgent)
        API.post(`/v1/oauth/check_out`,fd, {
            onUploadProgress: progressEvent => {
                // console.log("Progress: " + progressEvent.loaded / progressEvent.total);
            }
        }).then(res => {
            dashboard()
                toast.success(res.data.message)
            }).catch((err) => {
                toast.error(err.response.data.message);
            });
    }


    const _logout = () => {
        API.post(`/v1/oauth/logout`)
            .then(res => {
                if (res.data.success === true) {
                    localStorage.clear();
                }
            })
        localStorage.clear();
        window.location.href = '/';
    }

    const dashboard = () => {
        API.get(`/v1/oauth/dashboard`)
            .then(res => {
                dispatch(MY_PROFILE_FETCH_SUCCESS(res.data.data))
                dispatch(PERMISSION_FETCH_SUCCESS(res.data.data.permissions))
            })
    }

    useEffect(() => {
        Agent.Notification.latest(dispatch);
        API.get('/v1/oauth/company_profile').then((response) => {
            dispatch(COMPANY_FETCH_SUCCESS(response.data.data))
          }).catch((error) => {
          });
      }, []);
    
      const handleNotificationView = () => {
        Agent.Notification.readAll();
        Agent.Notification.latest(dispatch);
      }
      const navigate = useNavigate();
      const notificationData = useSelector((state) => state.notifyReducer?.data)
    
    const selector = useSelector((state) => state.myProfileSlice)

    return (
        <>
            <Helmet>
                {i18n.language == 'ar' ?
                    <html lang="ar" dir="rtl" />
                    :
                    <html lang="en" dir="ltr" />
                }
            </Helmet>
            <header id="page-topbar" className="shadow-sm">
                <div className="layout-width">
                    <div className="navbar-header">
                        <div className="d-flex">
                            <button onClick={() => ToggleSwitch()} type="button" className="btn btn-sm px-3 fs-22 header-item" id="topnav-hamburger-icon">
                                <span className="hamburger-icon">
                                    {active_menu ?
                                        <i className="fa-light fa-times close" style={{ display: 'block' }} />
                                        :
                                        <i className="fa-light fa-bars bar" />
                                    }
                                </span>
                            </button>
                            <div className="dropdown header-item topbar-user topbar-head-dropdown branch-dropdown me-2">
                                <button type="button" className="btn text-start" id="page-header-user-dropdown1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span className="d-block fs-12 text-muted">Your Organization</span>
                                    <span className="d-flex align-items-center">
                                        <img className="branch-logo" src="/assets/image/logo-img-5.png" alt="Header Avatar" />
                                        <span className="ms-1 fw-medium user-name-text">{com_selector?.data?.name}</span>
                                    </span>
                                </button>
                              
                            </div>
                            <button type="button" class="btn">
                                <i class="fa-light fa-search"></i>
                            </button>
                        </div>
                        <div className="d-flex align-items-center">
                            {selector?.data?.check_in==1?
                                  <button onClick={()=>_checkOut()} className="btn btn-sm btn-light"><i className="fa-light fa-clock"/> Check Out</button>
                       
                            :
                            <button onClick={()=>_checkIn()} className="btn btn-sm btn-light"><i className="fa-light fa-clock"/> Check In</button>
                        }
                            <div className="dropdown ms-1 topbar-head-dropdown header-item">
                                <button type="button" className="btn btn-icon btn-topbar rounded-circle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {i18n.language == 'ar' ?
                                        <img id="header-lang-img" src="/assets/image/flags/ae.svg" alt="Header Language" height={20} className="rounded" />
                                        :
                                        <img id="header-lang-img" src="/assets/image/flags/us.svg" alt="Header Language" height={20} className="rounded" />

                                    }
                                </button>
                                <div className="dropdown-menu dropdown-menu-end border-0 shadow-sm">
                                    {/* item*/}
                                    <a onClick={(e) =>
                                        i18n.changeLanguage('en')
                                    } href="#" className={`dropdown-item notify-item language py-2 ${i18n.language == 'en' ? 'active' : ''}`} data-lang="en" title="English">
                                        <img src="/assets/image/flags/us.svg" alt="user-image" className="me-2 rounded" height={18} />
                                        <span className="align-middle">English</span>
                                    </a>

                                    <a onClick={(e) =>
                                        i18n.changeLanguage('ar')
                                    } href="#" className={`dropdown-item notify-item language py-2 ${i18n.language == 'ar' ? 'active' : ''}`} data-lang="ar" title="Arabic">
                                        <img src="/assets/image/flags/ae.svg" alt="user-image" className="me-2 rounded" height={18} />
                                        <span className="align-middle">Arabic</span>
                                    </a>
                                </div>
                            </div>
                            <div className="dropdown topbar-head-dropdown ms-1 header-item " id="notificationDropdown">
                                <button type="button" className="btn btn-icon btn-topbar rounded-circle" id="page-header-notifications-dropdown" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa-light fa-bell fs-22" />
                                    <span className="position-absolute topbar-badge translate-middle badge rounded-pill bg-danger">{notificationData?.total_count}</span>
                                </button>
                                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 border-0 shadow-sm notification-dropdown-width" aria-labelledby="page-header-notifications-dropdown">
                                    <div className="dropdown-head bg-primary bg-pattern rounded-top">
                                        <div className="p-3">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <h6 className="m-0 fs-16 fw-semibold text-white"> Notifications </h6>
                                                </div>
                                                <div className="col-auto dropdown-tabs">
                                                    <span className="badge badge-soft-light fs-13"> {notificationData?.total_count} New</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-2 notification-height-scroll">
                                    {notificationData.notification?.map((value, index) => (
                                        
                                        <div className="text-reset notification-item d-block dropdown-item position-relative">
                                            <div className="d-flex">
                                                <div className="avatar-xs me-3">
                                                    <span className="avatar-title avatar-title-icon bg-soft-info text-info rounded-circle fs-16">
                                                        <i className="fa-light fa-bell" />
                                                    </span>
                                                </div>
                                                <div className="flex-1">
                                                    <a href="#" className="notification-content">
                                                        <h6 className="mt-0 mb-2 lh-base heading">{value.title}
                                                        </h6>
                                                    </a>
                                                    <p className="mb-0 fs-11 fw-medium text-uppercase text-muted notification-content-time">
                                                        <span><i className="fa-light fa-clock" /> {value.times}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                       
                                       ))}
                                        <div className="my-3 text-center view-all">
                                            <button type="button" className="btn bg-soft-info text-info fs-14">View
                                                All Notifications <i className="fa-regular fa-arrow-right align-middle" /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dropdown ms-sm-3 header-item topbar-user topbar-head-dropdown">
                                <button type="button" className="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span className="d-flex align-items-center">
                                        <img className="rounded-circle header-profile-user" src={user?.image} alt={user?.name} />
                                    </span>
                                </button>
                                <div className="dropdown-menu dropdown-menu-end border-0 shadow-sm p-0">
                                    {/* item*/}
                                    <h6 className="dropdown-header fs-12 fw-bold">Welcome {user?.name}!</h6>
                                    <Link className="dropdown-item fs-14" to={`/employee/${user.user_id}/view`}><i className="fa-light fa-user text-muted fs-16 align-middle me-1" /> <span className="align-middle">Profile</span></Link>
                                    <Link className="dropdown-item fs-14" to={`/change_password`}><i className="fa-light fa-key text-muted fs-16 align-middle me-1" /> <span className="align-middle">Password Change</span></Link>
                                    <div className="dropdown-divider" />
                                    <a className="dropdown-item fs-14" href="#" onClick={() => _logout()}><i className="fa-light fa-power-off text-muted fs-16 align-middle me-1" /> <span className="align-middle" data-key="t-logout">Logout</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import API from "../../../Network/API";
import BaseUrl from "../../../Network/BaseUrl";
import { Link } from "react-router-dom";
import Select from 'react-select'
export default function AddEmployee() {
    const selector = useSelector((state) => state.companyReducer)

    const [isLoading, setLoading] = useState(false);
    const [loading, setLoad] = useState(false);
    const dispatch = useDispatch();
    const modalRef1 = useRef()
    const [input, setInput] = useState({});
    const [department, setDepartment] = useState([]);
    const [designation, setDesignation] = useState([]);
    const [nationalities, setNationality] = useState([]);
    const [genders, setGender] = useState([]);
    const [religions, setReligion] = useState([]);
    const [id_types, setIdType] = useState([]);
    const [probationes, setProbation] = useState([]);
    const [relationships, setRelationship] = useState([]);
    const [digree, setDigree] = useState([]);
    const [status, setStatus] = useState([]);
    const [bank_info, setBankInfo] = useState([]);
    const [document_types, setDocumentType] = useState([]);
    const [providant_fund_rules, setProvidantFundRule] = useState([]);
    const [shift, setShift] = useState([]);
    const [roles, setRoles] = useState([]);
    const [branch, setBranch] = useState([]);
    const [teams, setTeamMember] = useState([]);
    const [taxInfo, setTaxList] = useState([]);
    const [allowances, setAllowance] = useState([]);
    const [progress, setProgress] = useState(false);
    const [file, setFile] = useState(null);
    const [document_file, setDocumentFile] = useState(null);
    const [step, setStep] = useState(0);
    const [step_allown, setStepAllown] = useState(0);
    const [gr_salary, setGrossSalary] = useState(0);
    const [basic_salary, setSalary] = useState(0);
    const [house_rent, setHouseRent] = useState(0);
    const [house_rent_percent, setHRentPercent] = useState(0);
    const [car_rent, setCarRent] = useState(0);
    const [car_rent_percent, setCarRentPer] = useState(0);
    const [medical_allowance, setMedicalAllowance] = useState(0);
    const [medical_allowance_percent, setMedicalAllowanceP] = useState(0);
    const [total_gosi, setTotalGosi] = useState(0);
    const [show, setShow] = useState(false);
    const [social_show, setSocialShow] = useState(false);
    const [tax_show, setTaxShow] = useState(false);
    const [options, setOptions] = useState([]);


    const handleShow = () => {
        setShow(!show)
    };

    const handleSocialShow = () => {
        setSocialShow(!social_show)
    };

    const handleTaxShow = () => {
        setTaxShow(!tax_show)
    };

    const setBasicSalary = (value) => {
        setSalary(value)
        totalGrossSalary()
    }

    const setHouseRentPercent = (value) => {
        const hr = ((Number(basic_salary) * Number(value)) / 100)
        setHouseRent(hr)
        setHRentPercent(value)
        totalGrossSalary()
    }

    const setMedicalAllowancePercent = (value) => {
        const hr = ((Number(basic_salary) * Number(value)) / 100)
        setMedicalAllowance(hr)
        setMedicalAllowanceP(value)
        totalGrossSalary()
    }

    const setCarRentPercent = (value) => {
        const hr = ((Number(basic_salary) * Number(value)) / 100)
        setCarRent(hr)
        setCarRentPer(value)
        totalGrossSalary()
    }


    const rows = [];

    const [rowsData, setRowsData] = useState([]);

    const addMore = () => {

        const rowsInput = {}
        setRowsData([...rowsData, rowsInput])

    }

    const deleteTableRows = (index) => {
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
    }



    const setSelectedFile = (e) => {
        setFile(e.target.files[0])
    };

    const onchangeDocument = (e) => {
        setDocumentFile(e.target.files[0])
        // console.log(e.target.files[0]);
    };

    const handleChange1 = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setInput({ ...input, [name]: value });
    };

    const onChangeGosi = (e) => {
        let selectedOption = e.target.options[e.target.selectedIndex];
        let value = selectedOption.value;
        let datavalue = selectedOption.dataset.datavalue;
        var basic_salary = document.getElementById('basic_salary').value;
        if (Number(basic_salary) != 0) {
            var rent = (Number(basic_salary * 1.25) * Number(datavalue) / 100);
            setTotalGosi(rent);
        }
        //   console.log(basic_salary)
    };

    useEffect(() => {
        API.get('/v1/oauth/users/create').then((response) => {
            setReligion(response.data.data.religion)
            setGender(response.data.data.genders)
            // setNationality(response.data.data.nationality)

            const newOptions = response.data.data.nationality.map(item => ({
                value: item.id.toString(), // Assuming you want the ID as a string
                label: item.name
            }));
            setNationality(newOptions);

            setIdType(response.data.data.idType)
            setProbation(response.data.data.probation)
            setRelationship(response.data.data.relationship)
            setDigree(response.data.data.digree)
            setStatus(response.data.data.status)
            setBankInfo(response.data.data.bankInfo)
            setDocumentType(response.data.data.documentType)
            setProvidantFundRule(response.data.data.providantFundRule)
            setShift(response.data.data.shift)
            setBranch(response.data.data.branch)
            setDepartment(response.data.data.departments)
            setDesignation(response.data.data.designation)
            setRoles(response.data.data.roles)
            setTaxList(response.data.data.tax)
            setAllowance(response.data.data.allowances)
            setLoading(false)
        }).catch((error) => {
            setLoading(true)
        });

        API.get('/v1/oauth/ajax_team').then((response) => {
            // setTeamMember(response.data.data)
            const newOptions = response.data.data.map(item => ({
                value: item.id.toString(), // Assuming you want the ID as a string
                label: item.name
            }));
            setOptions(newOptions);
        }).catch((error) => {

        });



    }, []);

    const emailValidation = (email) => {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!email || regex.test(email) === false) {
            return false;
        }
        return true;
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoad(true);
        setGrossSalary(calculateTotalPrice)
        if (event.target.name.value == "") {
            toast.error('Employee name was required')
            setStep(0)
            return false;
        }

        if (event.target.employee_id.value == "") {
            toast.error('Employee name was required')
            return false;
        }

        if (event.target.manager_id.value == "") {
            toast.error('Please select employee manager name')
            setStep(1)
            return false;
        }

        if (event.target.shift_id.value == "") {
            toast.error('Please select work shift')
            setStep(1)
            return false;
        }

        if (event.target.department_id.value == "") {
            toast.error('Please select department name')
            setStep(1)
            return false;
        }

        if (event.target.designation_id.value == "") {
            toast.error('Please select designation name')
            setStep(1)
            return false;
        }

        if (event.target.branch_id.value == "") {
            toast.error('Please select work location/office name')
            setStep(1)
            return false;
        }

        if (event.target.basic_salary.value == "") {
            toast.error('Please add employee salary')
            setStep(2)
            return false;
        }

        if (event.target.role_id.value == "") {
            toast.error('Please add employee role')
            return false;
        }

        if (event.target.password.value == "") {
            toast.error('Please add login password')
            return false;
        }

        if (event.target.confirm_password.value == "") {
            toast.error('Please add confirm password')
            return false;
        }

        if (event.target.confirm_password.value != event.target.password.value) {
            toast.error('Password and confirm password does not match ')
            return false;
        }

        if (event.target.email.value == "") {
            toast.error('Please add login email')
            return false;
        }

        if (event.target.email.value != "" && !emailValidation(event.target.email.value)) {
            toast.error('Email is not valid')
            return false;
        }

        if (event.target.work_email.value != "" && !emailValidation(event.target.work_email.value)) {
            toast.error('Email is not valid')
            return false;
        }

        if (event.target.private_email.value != "" && !emailValidation(event.target.private_email.value)) {
            toast.error('Email is not valid')
            return false;
        }

        const fd = new FormData();
        fd.append('name', event.target.name.value)
        fd.append('employee_id', event.target.employee_id.value)
        fd.append('date_of_birth', event.target.dob.value)
        fd.append('gender', event.target.gender.value)
        fd.append('religion', event.target.religion.value)
        fd.append('nationality', event.target.nationality.value)
        fd.append('private_email', event.target.private_email.value)
        fd.append('work_email', event.target.work_email.value)
        fd.append('mobile_no', event.target.mobile.value)
        fd.append('phone', event.target.phone.value)
        fd.append('marital_status', event.target.marital_status_id.value)
        fd.append('id_type', event.target.id_type.value)
        fd.append('id_number', event.target.id_number.value)
        fd.append('emergency_contact_name', event.target.emergency_contact_name.value)
        fd.append('emergency_contact_phone', event.target.emergency_contact_phone.value)
        fd.append('relationship', event.target.emergency_contact_relation.value)
        fd.append('emergency_contact_email', event.target.emergency_contact_email.value)
        fd.append('present_address', event.target.present_address.value)
        fd.append('parmanent_address', event.target.parmanent_address.value)
        fd.append('degree_id', event.target.degree_id.value)
        fd.append('passing_year', event.target.pass_year.value)
        fd.append('passing_point', event.target.passing_point.value)
        if (file) {
            fd.append('image', file, file?.name)
        }
        fd.append('manager_id', event.target.manager_id.value)
        fd.append('department_id', event.target.department_id.value)
        fd.append('designation_id', event.target.designation_id.value)
        fd.append('branch_id', event.target.branch_id.value)
        fd.append('probation', event.target.job_type.value)
        fd.append('join_date', event.target.join_date.value)
        fd.append('duration_type', event.target.duration_type.value)
        fd.append('payment_type', event.target.payment_type.value)
        fd.append('basic_salary', event.target.basic_salary.value)

        if (event.target.allow_providant_fund.checked) {
            fd.append('allow_providant_fund', 1)
            fd.append('providant_fund_rule_id', event.target.providant_fund_rule_id.value)
            fd.append('user_contribution_percent', event.target.user_contribution_percent.value)
            fd.append('company_contribution_percent', event.target.company_contribution_percent.value)
        } else {
            fd.append('allow_providant_fund', 0)
        }


      
        fd.append('bank_id', event.target.bank_id.value)
        fd.append('ac_name', event.target.ac_name.value)
        fd.append('account_number', event.target.account_number.value)
        fd.append('bftn_number', event.target.bftn_number.value)
        fd.append('document_type_id', event.target.document_type_id.value)
        if (document_file) {
            fd.append('document_attach', document_file, document_file?.name)
        }
        fd.append('role_id', event.target.role_id.value)
        fd.append('email', event.target.email.value)
        fd.append('password', event.target.password.value)
        fd.append('confirm_password', event.target.confirm_password.value)
        rowsData.map((rw, k) => {
            fd.append(`allownaces[${k}][allownace_id]`, rw.allownace_id)
            fd.append(`allownaces[${k}][allownace_amount]`, rw.allownace_amount)
        })

        fd.append('house_rent', event.target.house_rent.value)
        fd.append('house_rent_percent', event.target.house_rent_percent.value)
        fd.append('car_rent', event.target.car_rent.value)
        fd.append('car_rent_percent', event.target.car_rent_percent.value)
        fd.append('medical_allowance', event.target.medical_allowance.value)
        fd.append('medical_allowance_percent', event.target.medical_allowance_percent.value)
        fd.append('gross_salary', event.target.gross_salary.value)
        fd.append('shift_id', event.target.shift_id.value)

        if (event.target.allow_gosi.checked) {
            fd.append('allow_gosi', 1)
            fd.append('gosi_amount', event.target.gosi_amount.value)
            fd.append('social_security_type', event.target.social_security_type.value)
            fd.append('gosi_start_date', event.target.gosi_start_date.value)
            fd.append('gosi_id', event.target.gosi_rule.value)
        } else {
            fd.append('allow_gosi', 0)
        }

        if (event.target.allow_tax.checked) {
            fd.append('allow_tax', 1)
            fd.append('tax_amount', 0)
            fd.append('tax_type', event.target.tax_type.value)
            fd.append('tax_start_date', event.target.tax_start_date.value)
            fd.append('tax_id', event.target.tax_rule.value)
        } else {
            fd.append('allow_tax', 0)
        }


        // fd.append('allownace_amount[]', event.target.allownace_amount.value)

        BaseUrl.post("/v1/oauth/users/save", fd, {
            onUploadProgress: progressEvent => {
                // console.log("Progress: " + progressEvent.loaded / progressEvent.total);
                setProgress(progressEvent.loaded / progressEvent.total);
            }
        }).then((response) => {
            setLoad(false)
            document.getElementById("add_country_form").reset()
            setHouseRent(0)
            setMedicalAllowance(0)
            setBasicSalary(0)
            setCarRentPercent(0)
            setMedicalAllowancePercent(0)
            setHouseRentPercent(0)
            setGrossSalary(0)
            toast.success(response.data.message);
        })
            .catch((err) => {
                toast.error(err.response.data.message);
                setLoad(false)
            });

    }

    const handleChange = (index, evnt) => {

        const { name, value } = evnt.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        setRowsData(rowsInput);


    }


    const calculatePrice = (value) => {
        const parsedValue = parseFloat(value);
        if (isNaN(parsedValue)) {
            return 0;
        }
        return parsedValue * 10; // Assuming a simple calculation
    };

    const calculateTotalPrice = () => {

        var sum = 0;
        rowsData?.map((rr, i) => {
            sum += Number(rr.allownace_amount)
        })
        // const totalPrice = rowsData.reduce((sum, row) => sum + row.allownace_amount, 0);
        return sum; // Format the total price to 2 decimal places
    };

    const totalGrossSalary = () => {
        var gross = 0;
        var rent = 0;
        var house = 0;
        var medical = 0;
        var basic_salary = document.getElementById('basic_salary').value
        var car_rent_percent = document.getElementById('car_rent_percent').value
        var house_rent_percent = document.getElementById('house_rent_percent').value
        var medical_allowance_percent = document.getElementById('medical_allowance_percent').value
        if (Number(car_rent_percent) != 0) {
            rent = (Number(basic_salary) * Number(car_rent_percent) / 100)
            setCarRent(rent)
        }

        if (Number(house_rent_percent) != 0) {
            house = (Number(basic_salary) * Number(house_rent_percent) / 100)
            setHouseRent(house)
        }

        if (Number(medical_allowance_percent) != 0) {
            medical = (Number(basic_salary) * Number(medical_allowance_percent) / 100)
            setMedicalAllowance(medical)
        }

        setGrossSalary(Number(basic_salary) + Number(rent) + Number(house) + Number(medical))

    };

    return (
        <>
            <div className="page-title-box">
                <div className="page-title-breadcrumb">
                    <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                        <li className="breadcrumb-item"><a href="#">Employee</a></li>
                        <li className="breadcrumb-item active">Add</li>
                    </ol>
                </div>
            </div>
            <div className="row mb-3 ms-2">

                <div className="col-md-3 ">
                    <div className="card border-0 main-content-card custom-card ">
                        <div className="card-container">
                            <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <button onClick={() => setStep(0)} className={`nav-link ${step == 0 ? 'active' : ''}`} id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true"><span><i className="fa-regular fa-user" /> </span>Personal
                                </button>
                                <button onClick={() => setStep(1)} className={`nav-link ${step == 1 ? 'active' : ''}`} id="v-pills-job-tab" data-bs-toggle="pill" data-bs-target="#v-pills-job" type="button" role="tab" aria-controls="v-pills-job" aria-selected="false"><span><i class="fa-regular fa-briefcase"></i></span>Job
                                </button>

                                <button onClick={() => setStep(2)} className={`nav-link ${step == 2 ? 'active' : ''}`} id="v-pills-payroll-tab" data-bs-toggle="pill" data-bs-target="#v-pills-payroll" type="button" role="tab" aria-controls="v-pills-payroll" aria-selected="false"><span><i class="fa-regular fa-money-bill"></i></span>Payroll
                                </button>
                                <button onClick={() => setStep(3)} className={`nav-link ${step == 3 ? 'active' : ''}`} id="v-pills-social-tab" data-bs-toggle="pill" data-bs-target="#v-pills-social" type="button" role="tab" aria-controls="v-pills-social" aria-selected="false"><span><i class="fa-regular fa-money-bill"></i></span>Tax & Insurance
                                </button>
                                <button onClick={() => setStep(4)} className={`nav-link ${step == 4 ? 'active' : ''}`} id="v-pills-provident-tab" data-bs-toggle="pill" data-bs-target="#v-pills-provident" type="button" role="tab" aria-controls="v-pills-provident" aria-selected="false"><span><i class="fa-regular fa-money-bill"></i></span>Provident Fund
                                </button>
                                <button onClick={() => setStep(5)} className={`nav-link ${step == 5 ? 'active' : ''}`} id="v-pills-bank-tab" data-bs-toggle="pill" data-bs-target="#v-pills-bank" type="button" role="tab" aria-controls="v-pills-bank" aria-selected="false"><span><i class="fa-regular fa-bank"></i></span>Bank Information
                                </button>
                                <button onClick={() => setStep(6)} className={`nav-link ${step == 6 ? 'active' : ''}`} id="v-pills-document-tab" data-bs-toggle="pill" data-bs-target="#v-pills-document" type="button" role="tab" aria-controls="v-pills-document" aria-selected="false"><span><i class="fa-regular fa-file"></i></span>Documents
                                </button>
                                <button onClick={() => setStep(7)} className={`nav-link ${step == 7 ? 'active' : ''}`} id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false"><span><i class="fa-regular fa-right-to-bracket"></i></span>Login Access
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-9 ">
                    <form ref={modalRef1} onSubmit={handleSubmit} id="add_country_form">
                        <div class="tab-content" id="v-pills-tabContent">
                            <div className={`tab-pane fade ${step == 0 ? 'active show' : ''}`} id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                <div className="card border-0 main-content-card">
                                    <div className="card-body">
                                        <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3 main-content-card-header">
                                            <h4 className="main-card-title mb-0 flex-grow-1"><a href="#" class="text-dark">
                                                <i class="fa-duotone fa-arrow-left me-1"></i></a>
                                                Personal Information</h4>
                                        </div>
                                        <div className="live-preview">

                                            <div className="c-form-area pb-3">

                                                <div className="row align-items-end mb-3">
                                                    <h6>About</h6>
                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i className="fa-duotone fa-user text-primary" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="name" className="form-label fs-14 text-secondary mb-1 text-uppercase">Full Name*</label>
                                                                <input type="text" onChange={(e) => handleChange1(e)} className="form-control rounded-0" placeholder="Enter employee name" id="name" name="name" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i className="fa-light fa-id-card-clip text-primary" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="employee_id" className="form-label fs-14 text-secondary mb-1 text-uppercase">Office ID*</label>
                                                                <input type="text" onChange={(e) => handleChange1(e)} className="form-control rounded-0" placeholder="Enter id card number" id="employee_id" name="employee_id" />
                                                            </div>
                                                        </div>
                                                    </div>



                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i className="fa-duotone fa-calendar-days text-primary" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="dob" className="form-label fs-14 text-secondary mb-1 text-uppercase">Date Of Birth</label>
                                                                <input type="date" onChange={(e) => handleChange1(e)} className="form-control rounded-0" placeholder="yyyy-mm-dd" id="dob" name="dob" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">

                                                                    <i class="fa-solid fa-venus-mars text-warning"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="gender" className="form-label fs-14 text-secondary mb-1 text-uppercase">Gender</label>
                                                                <select onChange={(e) => handleChange1(e)} className="form-select rounded-0" id="gender" name="gender">
                                                                    <option selected value={``}>Choose...</option>
                                                                    {!isLoading && genders?.map((gender, index) => (
                                                                        <option value={gender.id}>{gender.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i class="fa-regular fa-ballot-check text-info"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="marital_status_id" className="form-label fs-14 text-secondary mb-1 text-uppercase">Marital Status</label>
                                                                <select onChange={(e) => handleChange1(e)} className="form-select rounded-0" id="marital_status_id" name="marital_status_id">
                                                                    <option selected value={``}>Choose...</option>
                                                                    {!isLoading && status?.map((row, index) => (
                                                                        <option value={row.id}>{row.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i class="fa-regular fa-compass text-info"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="religion" className="form-label fs-14 text-secondary mb-1 text-uppercase">Religion</label>
                                                                <select onChange={(e) => handleChange1(e)} className="form-select rounded-0" id="religion" name="religion">
                                                                    <option selected value={``}>Choose...</option>

                                                                    {!isLoading && religions?.map((religion, index) => (
                                                                        <option value={religion.id}>{religion.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">

                                                                    <i class="fa-thin fa-globe text-success"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="nationality" className="form-label fs-14 text-secondary mb-1 text-uppercase">Nationality</label>
                                                                <Select options={nationalities} id="nationality" name="nationality" isClearable={true} />


                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">

                                                                    <i class="fa-thin fa-passport text-pink"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="id_type" className="form-label fs-14 text-secondary mb-1 text-uppercase">ID Type</label>
                                                                <select onChange={(e) => handleChange1(e)} className="form-select rounded-0" id="id_type" name="id_type">
                                                                    <option selected value={``}>Choose...</option>
                                                                    {!isLoading && id_types?.map((row, index) => (
                                                                        <option value={row.id}>{row.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i class="fa-solid fa-id-card text-primary"></i>

                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="id_number" className="form-label fs-14 text-secondary mb-1 text-uppercase">ID Number</label>
                                                                <input onChange={(e) => handleChange1(e)} type="text" className="form-control rounded-0" placeholder="" id="id_number" name="id_number" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h6>Contact</h6>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i className="fa-solid fa-at text-primary" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="work_email" className="form-label fs-14 text-secondary mb-1 text-uppercase">Work Email</label>
                                                                <input onChange={(e) => handleChange1(e)} type="text" className="form-control rounded-0" placeholder="example@gmail.com" id="work_email" name="work_email" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i className="fa-light fa-mobile text-primary" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="mobile" className="form-label fs-14 text-secondary mb-1 text-uppercase">Mobile No</label>
                                                                <input onChange={(e) => handleChange1(e)} type="text" className="form-control rounded-0" placeholder="+880" id="mobile" name="mobile" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i className="fa-duotone fa-phone text-primary" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="phone" className="form-label fs-14 text-secondary mb-1 text-uppercase">Phone No</label>
                                                                <input onChange={(e) => handleChange1(e)} type="text" className="form-control rounded-0" placeholder="+880" id="phone" name="phone" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i className="fa-solid fa-at text-primary" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="private_email" className="form-label fs-14 text-secondary mb-1 text-uppercase">Private Email</label>
                                                                <input onChange={(e) => handleChange1(e)} type="text" className="form-control rounded-0" placeholder="example@gmail.com" id="private_email" name="private_email" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <h6>Emergency Contact</h6>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i className="fa-solid fa-user text-primary" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="emergency_contact_name" className="form-label fs-14 text-secondary mb-1 text-uppercase">Name</label>
                                                                <input onChange={(e) => handleChange1(e)} type="text" className="form-control rounded-0" placeholder="" id="emergency_contact_name" name="emergency_contact_name" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i className="fa-light fa-mobile text-primary" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="emergency_contact_phone" className="form-label fs-14 text-secondary mb-1 text-uppercase">Mobile No</label>
                                                                <input onChange={(e) => handleChange1(e)} type="text" className="form-control rounded-0" placeholder="+880" id="emergency_contact_phone" name="emergency_contact_phone" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i className="fa-light fa-paperclip text-primary" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="emergency_contact_relation" className="form-label fs-14 text-secondary mb-1 text-uppercase">Relationship</label>
                                                                <select onChange={(e) => handleChange1(e)} className="form-select rounded-0" id="emergency_contact_relation" name="emergency_contact_relation">
                                                                    <option selected value={``}>Choose...</option>
                                                                    {!isLoading && relationships?.map((row, index) => (
                                                                        <option value={row.id}>{row.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i className="fa-solid fa-at text-primary" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="emergency_contact_email" className="form-label fs-14 text-secondary mb-1 text-uppercase">Email</label>
                                                                <input onChange={(e) => handleChange1(e)} type="text" className="form-control rounded-0" placeholder="example@gmail.com" id="emergency_contact_email" name="emergency_contact_email" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <hr />
                                                <h6>Address</h6>
                                                <div className="row align-items-end mb-3">
                                                    <div className="col-sm-12 mb-3">
                                                        <div className="d-flex align-items-start">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">

                                                                    <i class="fa-light fa-address-book text-secondary"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="present_address" className="form-label fs-14 text-secondary mb-1 text-uppercase">Present Address</label>
                                                                <textarea onChange={(e) => handleChange1(e)} className="form-control rounded-0" id="present_address" rows={3} placeholder="Write a present address" defaultValue={""} name="present_address" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 mb-3">
                                                        <div className="d-flex align-items-start">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">

                                                                    <i class="fa-light fa-address-book text-secondary"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="parmanent_address" className="form-label fs-14 text-secondary mb-1 text-uppercase">Present Address</label>
                                                                <textarea onChange={(e) => handleChange1(e)} className="form-control rounded-0" id="parmanent_address" rows={3} placeholder="Write a parmanent address" defaultValue={""} name="parmanent_address" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <h6>Educational Information</h6>
                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i className="fa-solid fa-diploma text-primary" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="degree_id" className="form-label fs-14 text-secondary mb-1 text-uppercase">Degree</label>
                                                                <select onChange={(e) => handleChange1(e)} className="form-select rounded-0" id="degree_id" name="degree_id">
                                                                    <option selected value={``}>Choose...</option>
                                                                    {!isLoading && digree?.map((row, index) => (
                                                                        <option value={row.id}>{row.name}</option>
                                                                    ))}

                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i className="fa-duotone fa-calendar text-warning text-primary" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="pass_year" className="form-label fs-14 text-secondary mb-1 text-uppercase">Passing Year</label>
                                                                <input onChange={(e) => handleChange1(e)} type="text" className="form-control rounded-0" placeholder="yyyy" id="pass_year" name="pass_year" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">

                                                                    <i className="fa-light fa-graduation-cap text-primary" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="passing_point" className="form-label fs-14 text-secondary mb-1 text-uppercase">Grad/Ponit</label>
                                                                <input onChange={(e) => handleChange1(e)} type="text" className="form-control rounded-0" placeholder="ex:A+ or 5." id="passing_point" name="passing_point" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <h6>Picture</h6>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">

                                                                    <i class="fa-duotone fa-image text-primary"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="image" className="form-label fs-14 text-secondary mb-1 text-uppercase">Employee Photo</label>
                                                                <input onChange={(e) => setSelectedFile(e)} type="file" className="form-control rounded-0" placeholder="" id="image" name="image" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`tab-pane fade ${step == 1 ? 'active show' : ''}`} id="v-pills-job" role="tabpanel" aria-labelledby="v-pills-job-tab">
                                <div className="card border-0 main-content-card">
                                    <div className="card-body">
                                        <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3 main-content-card-header">
                                            <h4 className="main-card-title mb-0 flex-grow-1"><a href="#" onClick={() => setStep(step - 1)} class="text-dark">
                                                <i class="fa-duotone fa-arrow-left me-1"></i></a>
                                                Job Information</h4>
                                        </div>
                                        <div className="live-preview">
                                            <div className="c-form-area pb-3">
                                                <div className="row align-items-end mb-3">
                                                    <h6>Job</h6>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i className="fa-duotone fa-user text-primary" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="manager_id" className="form-label fs-14 text-secondary mb-1 text-uppercase">Manager Name<em className="text-danger">*</em></label>
                                                                <Select options={options} id="manager_id" name="manager_id" isClearable={true} />

                                                                {/* <select onChange={(e) => handleChange1(e)} className="form-select rounded-0" id="manager_id" name="manager_id">
                                                                    <option selected value={``}>Choose...</option>
                                                                    {!isLoading && teams?.map((row, index) => (
                                                                        <option value={row.id}>{row.name}</option>
                                                                    ))}
                                                                </select> */}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">

                                                                    <i class="fa-duotone fa-list-tree text-primary"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="department_id" className="form-label fs-14 text-secondary mb-1 text-uppercase">Department*</label>
                                                                <select onChange={(e) => handleChange1(e)} className="form-select rounded-0" id="department_id" name="department_id">
                                                                    <option selected value={``}>Choose...</option>
                                                                    {!isLoading && department?.map((row, index) => (
                                                                        <option value={row.id}>{row.name}</option>
                                                                    ))}

                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">

                                                                    <i class="fa-solid fa-list-check text-primary"></i>

                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="designation_id" className="form-label fs-14 text-secondary mb-1 text-uppercase">Designation*</label>
                                                                <select onChange={(e) => handleChange1(e)} className="form-select rounded-0" id="designation_id" name="designation_id" >
                                                                    <option selected value={``}>Choose...</option>
                                                                    {!isLoading && designation?.map((row, index) => (
                                                                        <option value={row.id}>{row.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i class="fa-solid fa-list-check text-primary"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="branch_id" className="form-label fs-14 text-secondary mb-1 text-uppercase">Branch*</label>
                                                                <select onChange={(e) => handleChange1(e)} className="form-select rounded-0" id="branch_id" name="branch_id" >
                                                                    <option selected value={``}>Choose...</option>
                                                                    {!isLoading && branch?.map((row, index) => (
                                                                        <option value={row.id}>{row.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i class="fa-solid fa-list-check text-primary"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="job_type" className="form-label fs-14 text-secondary mb-1 text-uppercase">Probation</label>
                                                                <select onChange={(e) => handleChange1(e)} className="form-select rounded-0" id="job_type" name="job_type" >
                                                                    <option selected value={``}>Choose...</option>
                                                                    {!isLoading && probationes?.map((row, index) => (
                                                                        <option value={row.id}>{row.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i className="fa-duotone fa-calendar text-primary" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="shift_id" className="form-label fs-14 text-secondary mb-1 text-uppercase">Work Shift*</label>
                                                                <select required onChange={(e) => handleChange1(e)} className="form-select rounded-0" id="shift_id" name="shift_id" >
                                                                    <option selected value={``}>Choose...</option>
                                                                    {!isLoading && shift?.map((row, index) => (
                                                                        <option value={row.id}>{row.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i className="fa-duotone fa-calendar text-primary" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="join_date" className="form-label fs-14 text-secondary mb-1 text-uppercase">Join Date</label>
                                                                <input type="date" onChange={(e) => handleChange1(e)} className="form-control rounded-0" placeholder="yyyy-mm-dd" id="join_date" name="join_date" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`tab-pane fade ${step == 2 ? 'active show' : ''}`} id="v-pills-payroll" role="tabpanel" aria-labelledby="v-pills-payroll-tab">
                                <div className="card border-0 main-content-card">
                                    <div className="card-body">
                                        <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3 main-content-card-header">
                                            <h4 className="main-card-title mb-0 flex-grow-1"><a href="#" onClick={() => setStep(step - 1)} class="text-dark">
                                                <i class="fa-duotone fa-arrow-left me-1"></i></a>
                                                Payroll</h4>
                                        </div>
                                        <div className="live-preview">
                                            <div className="c-form-area pb-3">
                                                <div className="row align-items-end mb-3">


                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i class="fa-light fa-timer"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="duration_type" className="form-label fs-14 text-secondary mb-1 text-uppercase">Salary Type</label>
                                                                <select onChange={(e) => handleChange1(e)} className="form-select rounded-0" id="duration_type" name="duration_type" >
                                                                    <option value={``}>Choose...</option>
                                                                    <option value={1} selected>Monthly</option>
                                                                    <option value={2}>Hourly</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i class="fa-light fa-money-bill"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="payment_type" className="form-label fs-14 text-secondary mb-1 text-uppercase">Payslip Type</label>
                                                                <select onChange={(e) => handleChange1(e)} className="form-select rounded-0" id="payment_type" name="payment_type" >
                                                                    <option value={``}>Choose...</option>
                                                                    <option value={1} selected>Cash</option>
                                                                    <option value={2} >Bank</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i class="fa-light fa-dollar-sign"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="basic_salary" className="form-label fs-14 text-secondary mb-1 text-uppercase">Basic Salary<em className="text-danger">*</em></label>
                                                                <input defaultValue={basic_salary} type="number" step="0.01" onChange={(e) => setBasicSalary(e.target.value)} className="form-control rounded-0" placeholder="0.0" id="basic_salary" name="basic_salary" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i class="fa-light fa-dollar-sign"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="house_rent_percent" className="form-label fs-14 text-secondary mb-1 text-uppercase">House Rent(%)<em className="text-danger">*</em></label>
                                                                <div class="input-group">
                                                                    <input defaultValue={house_rent_percent} type="number" step="0.01" onChange={(e) => setHouseRentPercent(e.target.value)} className="form-control rounded-0" placeholder="0" id="house_rent_percent" name="house_rent_percent" />
                                                                    <span class="input-group-text">%</span>
                                                                    <input value={house_rent} readOnly onChange={(e) => setHouseRent(e.target.value)} type="text" class="form-control rounded-0 bg-light" placeholder="0.0" name="house_rent" id="house_rent" />
                                                                    <span class="input-group-text">{selector?.data?.currency_code}</span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i class="fa-light fa-dollar-sign"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="medical_allowance_percent" className="form-label fs-14 text-secondary mb-1 text-uppercase">Medical Allowance(%)<em className="text-danger">*</em></label>
                                                                <div class="input-group">
                                                                    <input defaultValue={medical_allowance_percent} type="number" step="0.01" onChange={(e) => setMedicalAllowancePercent(e.target.value)} className="form-control rounded-0" placeholder="0" id="medical_allowance_percent" name="medical_allowance_percent" />
                                                                    <span class="input-group-text">%</span>
                                                                    <input value={medical_allowance} readOnly onChange={(e) => setMedicalAllowance(e.target.value)} type="text" class="form-control rounded-0 bg-light" placeholder="0.0" name="medical_allowance" id="medical_allowance" />
                                                                    <span class="input-group-text">{selector?.data?.currency_code}</span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i class="fa-light fa-dollar-sign"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="car_rent_percent" className="form-label fs-14 text-secondary mb-1 text-uppercase">Transportation Rent(%)<em className="text-danger">*</em></label>
                                                                <div class="input-group">
                                                                    <input defaultValue={car_rent_percent} type="number" step="0.01" onChange={(e) => setCarRentPercent(e.target.value)} className="form-control rounded-0" placeholder="0" id="car_rent_percent" name="car_rent_percent" />
                                                                    <span class="input-group-text">%</span>
                                                                    <input value={car_rent} readOnly onChange={(e) => setCarRent(e.target.value)} type="text" class="form-control rounded-0 bg-light" placeholder="0.0" name="car_rent" id="car_rent" />
                                                                    <span class="input-group-text">{selector?.data?.currency_code}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i class="fa-light fa-dollar-sign"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="gross_salary" className="form-label fs-14 text-secondary mb-1 text-uppercase">Gross Salary<em className="text-danger">*</em></label>
                                                                <input value={gr_salary} type="text" className="form-control rounded-0 bg-light" placeholder="0.0" id="gross_salary" name="gross_salary" />
                                                            </div>
                                                        </div>
                                                    </div>





                                                    <div className="col-sm-12 col-md-12 mb-3">
                                                        <table className="table table-bordered text-center p-0 m-0">
                                                            <thead>
                                                                <tr>
                                                                    <td>Others Allowance(<Link to={`/settings/allowances`}>Add Allowances</Link>)</td>
                                                                    <td>Amount</td>
                                                                    <td><button onClick={addMore} type="button" className="btn btn-info btn-sm"><i className="fa-light fa-plus-circle me-2"></i>Add</button></td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {rowsData?.map((data, index) => (
                                                                    <tr>
                                                                        <td><select defaultValue={data?.allowances_id} onChange={(e) => handleChange(index, e)} className="form-select rounded-0" id="allownace_id" name="allownace_id">
                                                                            <option selected value={``}>Choose...</option>
                                                                            {!isLoading && allowances?.map((row, sl) => (
                                                                                <option value={row.id}>{row.name}</option>
                                                                            ))}
                                                                        </select></td>
                                                                        <td>
                                                                            <input defaultValue={data?.allowances_amount} onChange={(e) => handleChange(index, e)} type="text" className="form-control rounded-0" name="allownace_amount" />
                                                                        </td>
                                                                        <td><button type="button" className="btn btn-sm btn-danger" onClick={() => (deleteTableRows(index))}>x</button></td>
                                                                    </tr>

                                                                ))}
                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`tab-pane fade ${step == 3 ? 'active show' : ''}`} id="v-pills-social" role="tabpanel" aria-labelledby="v-pills-social-tab">
                                <div className="card border-0 main-content-card">
                                    <div className="card-body">
                                        <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3 main-content-card-header">
                                            <h4 className="main-card-title mb-0 flex-grow-1"><a href="#" onClick={() => setStep(step - 1)} class="text-dark">
                                                <i class="fa-duotone fa-arrow-left me-1"></i></a>
                                                Tax & Social Insurance</h4>
                                        </div>
                                        <div className="live-preview">
                                            <div className="c-form-area pb-3">
                                                <div className="row align-items-end mb-3">

                                                    <div className="col-sm-12 col-md-12 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i class="fa-light fa-check"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <div class="form-check form-switch">
                                                                    <input class="form-check-input" type="checkbox" id="allow_social" name="allow_gosi" onChange={() => handleSocialShow()} />
                                                                    <label class="form-check-label" for="allow_social">Enable Social security</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={`col-sm-12 col-md-6 mb-3 ${social_show ? 'd-block' : 'd-none'}`}>
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i className="fa-regular fa-bank text-primary" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="social_security_type" className="form-label fs-14 text-secondary mb-1 text-uppercase">Social Security Type</label>
                                                                <select className="form-select rounded-0" id="social_security_type" name="social_security_type">
                                                                    <option value={`1`}>GOSI-For Non Saudi</option>
                                                                    <option selected value={`2`}>GOSI-For Saudi</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={`col-sm-12 col-md-6 mb-3 ${social_show ? 'd-block' : 'd-none'}`}>
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i class="fa-light fa-input-text text-green"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="gosi_start_date" className="form-label fs-14 text-secondary mb-1 text-uppercase"> Start Date</label>
                                                                <input defaultValue={``} type="date" className="form-control rounded-0" placeholder="" id="gosi_start_date" name="gosi_start_date" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={`col-sm-12 col-md-6 mb-3 ${social_show ? 'd-block' : 'd-none'}`}>
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i class="fa-light fa-list"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="gosi_rule" className="form-label fs-14 text-secondary mb-1 text-uppercase">Social Security Amount (<Link to={`/settings/tax_setup`}>Add New</Link>)</label>
                                                                <div class="input-group">
                                                                    <select onChange={(e) => onChangeGosi(e)} className="form-select rounded-0" id="gosi_rule" name="gosi_rule">
                                                                        <option selected value={``}>Choose...</option>
                                                                        {!isLoading && taxInfo?.map((row, index) => (
                                                                            <option value={row.id} data-datavalue={row.percent}>{row.title} ({row.percent}%)</option>
                                                                        ))}
                                                                    </select>
                                                                    <input value={total_gosi} name="gosi_amount" readOnly type="text" class="form-control rounded-0 bg-light" placeholder="0.0" />
                                                                    <span class="input-group-text">{selector?.data?.currency_code}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="row align-items-end mb-3">
                                                    <div className="col-sm-12 col-md-12 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i class="fa-light fa-check"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <div class="form-check form-switch">
                                                                    <input class="form-check-input" type="checkbox" id="allow_tax" name="allow_tax" onChange={() => handleTaxShow()} />
                                                                    <label class="form-check-label" for="allow_tax">Enable income tax</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className={`col-sm-12 col-md-6 mb-3 ${tax_show ? 'd-block' : 'd-none'}`}>
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i className="fa-regular fa-bank text-primary" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="tax_type" className="form-label fs-14 text-secondary mb-1 text-uppercase">Tax Type</label>
                                                                <select className="form-select rounded-0" id="tax_type" name="tax_type">
                                                                    <option value={`1`}>Tax-For Foreigner</option>
                                                                    <option selected value={`2`}>Tax-For Nationality</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={`col-sm-12 col-md-6 mb-3 ${tax_show ? 'd-block' : 'd-none'}`}>
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i class="fa-light fa-input-text text-green"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="tax_start_date" className="form-label fs-14 text-secondary mb-1 text-uppercase"> Start Date</label>
                                                                <input defaultValue={``} type="date" className="form-control rounded-0" placeholder="" id="tax_start_date" name="tax_start_date" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={`col-sm-12 col-md-6 mb-3 ${tax_show ? 'd-block' : 'd-none'}`}>
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i class="fa-light fa-list"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="tax_rule" className="form-label fs-14 text-secondary mb-1 text-uppercase">Tax Percent (<Link to={`/settings/tax_setup`}>Add New</Link>)</label>
                                                                <div class="input-group">
                                                                    <select className="form-select rounded-0" id="tax_rule" name="tax_rule">
                                                                        <option selected value={``}>Choose...</option>
                                                                        {!isLoading && taxInfo?.map((row, index) => (
                                                                            <option value={row.id} data-datavalue={row.percent}>{row.title} ({row.percent}%)</option>
                                                                        ))}
                                                                    </select>
                                                                   
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>





                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`tab-pane fade ${step == 4 ? 'active show' : ''}`} id="v-pills-provident" role="tabpanel" aria-labelledby="v-pills-provident-tab">
                                <div className="card border-0 main-content-card">
                                    <div className="card-body">
                                        <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3 main-content-card-header">
                                            <h4 className="main-card-title mb-0 flex-grow-1"><a href="#" onClick={() => setStep(step - 1)} class="text-dark">
                                                <i class="fa-duotone fa-arrow-left me-1"></i></a>
                                                Provident Fund</h4>
                                        </div>
                                        <div className="live-preview">
                                            <div className="c-form-area pb-3">
                                                <div className="row align-items-end mb-3">



                                                    <div className="col-sm-12 col-md-12 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i class="fa-light fa-check"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <div class="form-check form-switch">
                                                                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" name="allow_providant_fund" onChange={() => handleShow()} />
                                                                    <label class="form-check-label" for="flexSwitchCheckDefault">Enable Provident Fund</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={`col-sm-12 col-md-6 mb-3 ${show ? 'd-block' : 'd-none'}`}>
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i class="fa-light fa-percent"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="providant_fund_rule_id" className="form-label fs-14 text-secondary mb-1 text-uppercase">Employee contribution(%)</label>
                                                                <input type="number" step={0.02} defaultValue={0} className="form-control" name="user_contribution_percent" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`col-sm-12 col-md-6 mb-3 ${show ? 'd-block' : 'd-none'}`}>
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i class="fa-light fa-percent"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="company_contribution_percent" className="form-label fs-14 text-secondary mb-1 text-uppercase">Company contribution(%)</label>
                                                                <input type="number" step={0.02} defaultValue={0} className="form-control" name="company_contribution_percent" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`col-sm-12 col-md-6 mb-3 ${show ? 'd-block' : 'd-none'}`}>
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i class="fa-light fa-list"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="providant_fund_rule_id" className="form-label fs-14 text-secondary mb-1 text-uppercase">Provident Fund Interest Rate(<Link to={`/settings/fund_rules`}>Add New</Link>)</label>
                                                                <select onChange={(e) => handleChange1(e)} className="form-select rounded-0" id="providant_fund_rule_id" name="providant_fund_rule_id">
                                                                    <option selected value={``}>Choose...</option>
                                                                    {!isLoading && providant_fund_rules?.map((row, index) => (
                                                                        <option value={row.id} data-value={row.percent}>{row.percent}%</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`tab-pane fade ${step == 5 ? 'active show' : ''}`} id="v-pills-bank" role="tabpanel" aria-labelledby="v-pills-bank-tab">
                                <div className="card border-0 main-content-card">
                                    <div className="card-body">
                                        <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3 main-content-card-header">
                                            <h4 className="main-card-title mb-0 flex-grow-1"><a href="#" onClick={() => setStep(step - 1)} class="text-dark">
                                                <i class="fa-duotone fa-arrow-left me-1"></i></a>
                                                Bank Information</h4>
                                        </div>
                                        <div className="live-preview">
                                            <div className="c-form-area pb-3">
                                                <div className="row align-items-end mb-3">


                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i className="fa-regular fa-bank text-primary" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="bank_id" className="form-label fs-14 text-secondary mb-1 text-uppercase">Bank Name</label>
                                                                <select onChange={(e) => handleChange1(e)} className="form-select rounded-0" id="bank_id" name="bank_id">
                                                                    <option selected value={``}>Choose...</option>
                                                                    {!isLoading && bank_info?.map((row, index) => (
                                                                        <option value={row.id}>{row.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i class="fa-light fa-input-text text-green"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="ac_name" className="form-label fs-14 text-secondary mb-1 text-uppercase">Account Name</label>
                                                                <input type="text" onChange={(e) => handleChange1(e)} className="form-control rounded-0" placeholder="" id="ac_name" name="ac_name" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">

                                                                    <i class="fa-light fa-input-numeric text-primary"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="account_number" className="form-label fs-14 text-secondary mb-1 text-uppercase"> Account Number</label>
                                                                <input type="text" onChange={(e) => handleChange1(e)} className="form-control rounded-0" placeholder="" id="account_number" name="account_number" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 mb-3 ">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">

                                                                    <i class="fa-light fa-input-numeric text-primary"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="bftn_number" className="form-label fs-14 text-secondary mb-1 text-uppercase">IBAN Number</label>
                                                                <input type="text" onChange={(e) => handleChange1(e)} className="form-control rounded-0" placeholder="" id="bftn_number" name="bftn_number" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`tab-pane fade ${step == 6 ? 'active show' : ''}`} id="v-pills-document" role="tabpanel" aria-labelledby="v-pills-document-tab">
                                <div className="card border-0 main-content-card">
                                    <div className="card-body">
                                        <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3 main-content-card-header">
                                            <h4 className="main-card-title mb-0 flex-grow-1"><a href="#" onClick={() => setStep(step - 1)} class="text-dark">
                                                <i class="fa-duotone fa-arrow-left me-1"></i></a>
                                                Documents</h4>
                                        </div>
                                        <div className="live-preview">
                                            <div className="c-form-area pb-3">
                                                <div className="row align-items-end mb-3">


                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">

                                                                    <i class="fa-light fa-file text-primary"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="document_type_id" className="form-label fs-14 text-secondary mb-1 text-uppercase">Document Type</label>
                                                                <select onChange={(e) => handleChange1(e)} className="form-select rounded-0" id="document_type_id" name="document_type_id">
                                                                    <option selected value={``}>Choose...</option>
                                                                    {!isLoading && document_types?.map((row, index) => (
                                                                        <option value={row.id}>{row.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">

                                                                    <i class="fa-light fa-paperclip text-primary"></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="attachment" className="form-label fs-14 text-secondary mb-1 text-uppercase">Attachment</label>
                                                                <input type="file" onChange={(e) => onchangeDocument(e)} className="form-control rounded-0" placeholder="" id="attachment" name="attachment" />
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`tab-pane fade ${step == 7 ? 'active show' : ''}`} id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                <div className="card border-0 main-content-card">
                                    <div className="card-body">
                                        <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3 main-content-card-header">
                                            <h4 className="main-card-title mb-0 flex-grow-1"><a href="#" onClick={() => setStep(step - 1)} class="text-dark">
                                                <i class="fa-duotone fa-arrow-left me-1"></i></a>
                                                Login Access</h4>
                                        </div>
                                        <div className="live-preview">
                                            <div className="c-form-area pb-3">
                                                <div className="row align-items-end mb-3">


                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i className="fa-regular fa-camera-security text-primary" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="role_id" className="form-label fs-14 text-secondary mb-1 text-uppercase">Role*</label>
                                                                <select onChange={(e) => handleChange1(e)} className="form-select rounded-0" id="role_id" name="role_id">
                                                                    <option selected value={``}>Choose...</option>
                                                                    {!isLoading && roles?.map((row, index) => (
                                                                        <option value={row.id}>{row.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i className="fa-duotone fa-at text-primary" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="email" className="form-label fs-14 text-secondary mb-1 text-uppercase">Login Email*</label>
                                                                <input type="email" onChange={(e) => handleChange1(e)} className="form-control rounded-0" placeholder="" id="email" name="email" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i className="fa-duotone fa-key text-primary" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="password" className="form-label fs-14 text-secondary mb-1 text-uppercase">Password*</label>
                                                                <input type="password" onChange={(e) => handleChange1(e)} className="form-control rounded-0" placeholder="" id="password" name="password" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 mb-3">
                                                        <div className="d-flex align-items-end">
                                                            <div className="flex-shrink-0 form-icon-avatar">
                                                                <span className="form-icon-title rounded-circle">
                                                                    <i className="fa-duotone fa-key text-primary" />
                                                                </span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <label htmlFor="confirm_password" className="form-label fs-14 text-secondary mb-1 text-uppercase">Confirm Password*</label>
                                                                <input type="password" onChange={(e) => handleChange1(e)} className="form-control rounded-0" placeholder="" id="confirm_password" name="confirm_password" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="text-end mb-4 mt-5">
                            {
                                step == 7 ?
                                    <>
                                        <button onClick={() => setStep(step - 1)} type="button" class="btn btn-warning rounded-pill me-2">
                                            <i class="fa-light fa-arrow-left me-1"></i> Back
                                        </button>

                                        <button type="submit" class="btn btn-success rounded-pill">
                                            <i class="fa-light fa-save me-1"></i> Save {loading ? (
                                                <span
                                                    className="spinner-border spinner-border-sm ml-5"
                                                    role="status"
                                                    aria-hidden="true"
                                                >{progress}</span>
                                            ) : (
                                                <span></span>
                                            )}
                                        </button>
                                    </>

                                    :
                                    <>
                                        {step != 0 ?

                                            <>
                                                <button onClick={() => setStep(step - 1)} type="button" class="btn btn-warning rounded-pill me-2">
                                                    <i class="fa-light fa-arrow-left me-1"></i> Back
                                                </button>
                                                <button onClick={() => setStep(step + 1)} type="button" class="btn btn-success rounded-pill">
                                                    <i class="fa-light fa-arrow-right me-1"></i> Next
                                                </button>
                                            </>
                                            :
                                            <button onClick={() => setStep(step + 1)} type="button" class="btn btn-success rounded-pill">
                                                <i class="fa-light fa-arrow-right me-1"></i> Next
                                            </button>
                                        }

                                    </>

                            }


                        </div>
                    </form>
                </div>


            </div>

        </>
    )
}
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import API from "../Network/API";
import { useDispatch } from "react-redux";
import { USER_MENU_FETCH_SUCCESS } from "../Reducer/UserMenuSlice";

export default function LeftNav() {
  const location = useLocation();
  const { t } = useTranslation();
  const [menus, setMenu] = useState([])
  const dispatch = useDispatch();
  useEffect(() => {
    API.get(`/v1/oauth/getMenus`)
      .then(res => {
        setMenu(res.data.data.menus)
        dispatch(USER_MENU_FETCH_SUCCESS(res.data.data.menus))
      })
  }, []);


  return (
    <>
      <div className="app-menu navbar-menu">
        <div className="navbar-brand-box  bg-white">
          {/* Light Logo*/}
          <a href="#" className="logo logo-light">
            <span className="logo-sm">
              <img src="/assets/image/icon.png" alt="" height={22} />
            </span>
            <span className="logo-lg">
              <img src="/assets/image/main-logo.png" alt="" height={35} />
            </span>
          </a>
        </div>
        <div className="sidebar_scrollbar">
          <div className="container-fluid">
            <ul className="navbar-nav" id="navbar-nav">
              <li className="menu-title"><span>Menu</span></li>
              {menus.length > 0 && menus.map((menu, index) => (



                <>

                  {menu.type == 0 ?
                    <li class="menu-title"><span>{t(`${menu.trans_name}`)}</span></li>
                    :
                    <>
                      {menu?.submenus?.length > 0 ?

                        <li class="nav-item">
                          <a class="nav-link menu-link collapsed" href={`#myrequest${index}`} data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="myrequest">
                            <i className={`${menu.icon}`} /> <span>{t(`${menu.trans_name}`)}</span>
                          </a>
                          <div class="menu-dropdown collapse" id={`myrequest${index}`}>
                            <ul class="nav nav-sm flex-column">
                              {menu?.submenus?.length > 0 && menu?.submenus?.map((smenu, k) => (
                                <li class="nav-item">
                                  
                                  <Link to={`${smenu.path}`} class="nav-link"> {t(`${smenu.trans_name}`)} </Link>
                                </li>
                              ))}

                            </ul>
                          </div>
                        </li>

                        :
                        <li className="nav-item" key={index}>
                          <Link to={`${menu.path}`} className={`nav-link menu-link ${location.pathname == `/${menu.path}` ? 'active' : ''}`} href="#" role="button" aria-expanded="false" aria-controls="sidebarDashboards">
                            <i className={`${menu.icon}`} /> <span>{t(`${menu.trans_name}`)}</span>
                          </Link>
                        </li>

                      }
                    </>

                  }
                </>


              ))}



            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
import { useRef, useState } from "react";
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from "react-redux";
import API from "../../../../Network/API";
import { ADD_SHIFT_FETCH_SUCCESS } from "../../../../Reducer/ShiftSlice";

export default function AddShift() {
    const [branch, setBranch] = useState("");
    const [name, setName] = useState("");
    const [start_time, setStartTime] = useState("");
    const [end_time, setEndTime] = useState("");
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const inpRef = useRef()
    const modalRef1 = useRef()
    const selector = useSelector((state) => state.branchReducer)

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        const data = new FormData();
        data.append('name', event.target.name.value)
        data.append('start_time', event.target.start_time.value)
        data.append('end_time', event.target.end_time.value)
        data.append('last_in_time', event.target.last_in_time.value)

       const responsea= API.post('/v1/oauth/office_shift/save', data);
       toast.promise(responsea, {
        loading: 'Saving ...',
        success: (response) => {
            setLoading(false);
            modalRef1.current.click();
            document.getElementById("add_country_form").reset()
           dispatch(ADD_SHIFT_FETCH_SUCCESS(response.data.data))
          if (response.status === 500) throw new Error('server error');
          return `${response.data.message}`;
        },
        error: (e) => `${e.response.data.errors.name??e.response.data.message}`,
        duration: 6000,
      })
      setLoading(false)
    }

    return (
        <>
            <div class="modal" id="myModal">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Add Shift</h4>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" ref={modalRef1}></button>
                       
                        </div>
                        <div class="modal-body">
                            <form onSubmit={handleSubmit} id="add_country_form">
                                <div className="row">

                                    {/* <div className="col-sm-12 mb-3">
                                        <label htmlFor="name" className="form-label c-label">Branch</label>
                                        <select className="form-select rounded-pill" required onChange={(e) => setBranch(e.target.value)}>
                                       <option></option>
                                        {!selector?.loading && selector?.main_data?.map((row, index) => (
                                        <option value={row.id}>{row.name}</option>
                                        ))}
                                        </select>
                                   </div> */}

                                    <div className="col-sm-12 mb-3">
                                        <label htmlFor="name" className="form-label c-label">Name<em className="text-danger">*</em></label>
                                        <input type="text" className="form-control rounded-pill" id="name" name="name" placeholder required />
                                    </div>

                                    <div className="col-sm-12 mb-3">
                                        <label htmlFor="start_time" className="form-label c-label">Start Time<em className="text-danger">*</em></label>
                                        <input type="time" className="form-control rounded-pill" id="start_time" name="start_time" placeholder required />
                                    </div>

                                    <div className="col-sm-12 mb-3">
                                        <label htmlFor="end_time" className="form-label c-label">End Time<em className="text-danger">*</em></label>
                                        <input type="time" className="form-control rounded-pill" id="end_time" name="end_time" placeholder required />
                                    </div>

                                    <div className="col-sm-12 mb-3">
                                        <label htmlFor="last_in_time" className="form-label c-label">Last CheckIn Time<em className="text-danger">*</em></label>
                                        <input  type="time" className="form-control rounded-pill" id="last_in_time" name="last_in_time" placeholder required />
                                    </div>

                                </div>

                                <div class="modal-footer">
                                    <button type="submit" class="btn btn-success">Submit {isLoading ? (
                                        <span
                                            className="spinner-border spinner-border-sm ml-5"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    ) : (
                                        <span></span>
                                    )}</button>
                                </div>
                            </form>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
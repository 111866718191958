import { Outlet } from "react-router-dom";
import ReportNav from "../../../_partials/ReportNav";

export default function Report(){
    return (
        <>
        <div className="row">
        <div className="col-md-4 col-sm-4 col-lg-3">
          <div className="card border-0 rounded-0">
            <ReportNav/>
          </div>
        </div>
        <div className="col-md-8 col-sm-8 col-lg-9">
        <Outlet />
        </div>
      </div>
        </>
    )
}
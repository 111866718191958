import { useEffect, useState } from "react";
import API from "../../../Network/API";
import { useNavigate, useParams } from "react-router-dom";

export default function ViewBonus(){
    const [isLoading, setLoading] = useState(false);
    const [nxtPage, setNextPage] = useState(null);
    const [search_value, setSearchValue] = useState(null);
    const [data_value, setDataValue] = useState(null);
    const [show, setShow] = useState(false);
    const prams = useParams();
    const navigator=useNavigate()
    useEffect(() => {
        API.get(`/v1/oauth/manage_bonus/${prams.id}/view`).then((response) => {
            setDataValue(response.data.data)
            setLoading(false)
        }).catch((error) => {
            setLoading(true)

        });
    }, []);

 
    return (
        <>
        <div className="container-fluid">
                <div className="page-title-box">
                    <div className="page-title-breadcrumb">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item"><a href="#">Payroll</a></li>
                            <li className="breadcrumb-item">Manage Bonus</li>
                            <li className="breadcrumb-item active">{data_value?.data?.type_name}</li>
                        </ol>
                    </div>
                </div>

                <div className="card border-0 main-content-card">
        <div className="card-body">
          <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3 main-content-card-header">
            <h4 className="main-card-title mb-0 flex-grow-1"><a href="#" onClick={() => navigator(- 1)} class="text-dark">
                                                <i class="fa-duotone fa-arrow-left me-1"></i></a>Employee Bonus</h4>
            
          </div>
          <div className="live-preview">

            <table className="table">
              <thead>
              <tr>
                <th>Name</th>
                <th>employee_id</th>
                <th>Department</th>
                <th>Designation</th>
                <th>Salary</th>
                <th>Percent(%)</th>
                <th>Bonus Amount</th>
                </tr>
              </thead>
              <tbody>
              {!isLoading && data_value?.users?.map((row, index) => (
                <tr>
                <td>{row.user_name}</td>
                <td>{row.employee_id}</td>
                <td>{row.department_name}</td>
                <td>{row.designation_name}</td>
                <td>{row.salary}</td>
                <td>{row.percent}</td>
                <td>{row.amount}</td>
                
                </tr>
                 ))}
              </tbody>
            </table>
           
            <hr />
                                    <div className="row align-items-end mb-3">
                                        <div className="col-sm-12">
                                            <div className="d-flex align-items-start">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i className="fa-duotone fa-message-captions text-secondary" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                {data_value?.data?.remark}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
   
          </div>
        </div>{/* end card-body */}
      </div>

                </div>
        </>
    )
}
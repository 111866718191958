import React, { useEffect, useState } from 'react';
import EventForm from './EventForm';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import API from '../../../Network/API';
import { DELETE_PUBLIC_HOLIDAY, PUBLIC_HOLIDAY_FETCH_ERROR, PUBLIC_HOLIDAY_FETCH_SUCCESS } from '../../../Reducer/PubliceHolidaySlice';
import { useDispatch, useSelector } from 'react-redux';

const PublicHoliday = () => {
    const [events, setEvents] = useState([]);
    const localizer = momentLocalizer(moment);
    const [types, setType] = useState([]);
    const dispatch = useDispatch();
    const showWeekView = true;
    const showDayView = false;

    const handleSelectEvent = (event) => {
        // Handle event selection, e.g., display event details, enable editing, etc.
        console.log('Selected event:', event);
    };

    const handleSelectSlot = (slotInfo) => {
        console.log('Selected slotInfo:', slotInfo);

    };

    const handleSaveEvent = (newEvent) => {
        // Handle saving a new event or updating an existing event
        setEvents([...events, newEvent]);
    };

    useEffect(() => {
        API.get('/v1/oauth/public_holidays/create').then((response) => {
            setType(response.data.data)
        }).catch((error) => {
        });

        all_data()

    }, []);

    const all_data = () => {
        API.get('/v1/oauth/public_holidays').then((response) => {
            dispatch(PUBLIC_HOLIDAY_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
            PUBLIC_HOLIDAY_FETCH_ERROR();
        });
    }

    const selector = useSelector((state) => state.publiceHolidayReducer)
    
    const CustomEvent = ({ event }) => {
        const handleDeleteEvent = () => {
            console.log('Deleting event:', event);
            if (window.confirm('Are you sure you want to delete this event?')) {
                // Delete event logic
                const updatedEvents = events.filter((ev) => ev.id !== event.id);
                setEvents(updatedEvents);
                API.delete('/v1/oauth/public_holidays/' + event.id + '').then((response) => {
                    dispatch(DELETE_PUBLIC_HOLIDAY(event.id))
                }).catch((error) => {

                });
            }
        };

        return (
            <div>
                <span>{event.title}</span>
                <i className='fa-solid fa-trash text-white ms-2' onClick={handleDeleteEvent}></i>
            </div>
        );
    };

    return (
        <div>

            <div className="page-title-box">
                <div className="page-title-breadcrumb">
                    <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                        <li className="breadcrumb-item active">Holidays Calendar</li>
                    </ol>
                </div>
            </div>
            <div className="card border-0 main-content-card">
                <div className="card-body">
                    <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3 main-content-card-header">
                        <h4 className="main-card-title mb-0 flex-grow-1"><a href="#" onClick={() => navigator(- 1)} class="text-dark">
                            <i class="fa-duotone fa-arrow-left me-1"></i></a> Holiday Calendar</h4>
                        <div className="flex-shrink-0">
                            <div className="app-search">
                                <div className="input-group">

                                    <button className="btn btn-success" type="button" id="button-addon2" data-bs-toggle="modal" data-bs-target="#myModal"><i className="fa-regular fa-plus" /> Add Holiday</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Calendar
                        localizer={localizer}
                        views={['month']}
                        defaultView="month"
                        events={selector?.main_data}
                        startAccessor="start"
                        endAccessor="end"
                        defaultDate={moment().toDate()}
                        onSelectEvent={handleSelectEvent}
                        onSelectSlot={handleSelectSlot}
                        components={{
                            event: CustomEvent, // Custom event component
                        }}
                        style={{ height: 500 }}
                    />
                    <EventForm holidayTypes={types} event={{}} onSave={handleSaveEvent} />
                </div>
            </div>
        </div>
    );
};

export default PublicHoliday;

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import API from '../../../Network/API';
import { ADD_PUBLIC_HOLIDAY_FETCH_SUCCESS } from '../../../Reducer/PubliceHolidaySlice';
import { toast } from 'react-hot-toast';

const EventForm = ({ event, onSave, holidayTypes }) => {
    const [title, setTitle] = useState(event.title || '');
    const [start, setStart] = useState(event.start || '');
    const [end, setEnd] = useState(event.end || '');
    const [name, setName] = useState("");
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const modalRef1 = useRef()

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        if(event.target.holiday_type.value==''){
            toast.error('Please select title')
            return;
        }
        

        var data = {
            'holiday_type': event.target.holiday_type.value,
            'start_date': event.target.start_date.value,
            'end_date': event.target.end_date.value,
            'is_recurring': event.target.is_recurring.value,
        }
        const responsea = API.post('/v1/oauth/public_holidays/save', data);
        toast.promise(responsea, {
            loading: 'Saving ...',
            success: (response) => {
                setLoading(false);
                modalRef1.current.click();
                document.getElementById("add_country_form").reset()
                setTitle(response.data.data.title)
                setStart(response.data.data.start_date)
                setEnd(response.data.data.end_date)
                const newEvent = {
                    title,
                    start,
                    end,
                };
                onSave(newEvent);
                dispatch(ADD_PUBLIC_HOLIDAY_FETCH_SUCCESS(response.data.data))
                if (response.status === 500) throw new Error('server error');
                return `${response.data.message}`;
            },
            error: (e) => `${e.response.data.errors.name ?? e.response.data.message}`,
            duration: 6000,
        })
        setLoading(false)
    };



    return (
        <>
            <div class="modal" id="myModal">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Add weekly holiday</h4>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" ref={modalRef1}></button>
                        </div>
                        <div class="modal-body">
                            <form onSubmit={handleSubmit} id="add_country_form">
                                <div className="row">
                                    <div className="col-sm-12 mb-3">
                                        <label htmlFor="name" className="form-label c-label">Event Name*</label>
                                        <select name='holiday_type' required className='form-select' >
                                            <option>Choose...</option>
                                            {holidayTypes?.length > 0 && holidayTypes?.map((row, index) => (
                                                <option key={index} value={row.id} title={row.name}>{row.name}</option>
                                            ))}
                                        </select>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 mb-3">
                                        <label htmlFor="name" className="form-label c-label">Start Date*</label>
                                        <input required name='start_date' className='form-control'
                                            type="date"
                                            placeholder="Start"
                                            
                                          
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 mb-3">
                                        <label htmlFor="name" className="form-label c-label">End Date*</label>

                                        <input required name='end_date' className='form-control'
                                            type="date"
                                            placeholder="End"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 mb-3">
                                        <label htmlFor="name" className="form-label c-label">Recurring for next year</label>

                                        <div class="form-check">
                                            <input value={1} class="form-check-input" type="radio" name="is_recurring" id="flexRadioDefault1" />
                                            <label class="form-check-label" for="flexRadioDefault1">
                                                Yes
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input value={2} class="form-check-input" type="radio" name="is_recurring" id="flexRadioDefault2" checked />
                                            <label class="form-check-label" for="flexRadioDefault2">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="submit" class="btn btn-success">Submit {isLoading ? (
                                        <span
                                            className="spinner-border spinner-border-sm ml-5"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    ) : (
                                        <span></span>
                                    )}</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default EventForm;



import { createSlice } from '@reduxjs/toolkit';

export const myProfileSlice = createSlice({
    name: 'myProfileSlice',
    initialState: {
        loading: false,
        data: {

        },
        main_data: [],
        error: false,
    },
    reducers: {
        MY_PROFILE_FETCH_START: (state) => {
            state.loading = true
        },
        MY_PROFILE_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.main_data = action.payload.data
        },
        ADD_MY_PROFILE_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.main_data = [action.payload, ...state.main_data]
        },
        MY_PROFILE_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { MY_PROFILE_FETCH_START, MY_PROFILE_FETCH_SUCCESS, MY_PROFILE_FETCH_ERROR, ADD_MY_PROFILE_FETCH_SUCCESS } = myProfileSlice.actions;
export default myProfileSlice.reducer;


import { createSlice } from '@reduxjs/toolkit';

export const performanceTypeSlice = createSlice({
    name: 'performanceType',
    initialState: {
        loading: false,
        data: {

        },
        main_data: [],
        error: false,
    },
    reducers: {
        PERFORMANCE_TYPE_FETCH_START: (state) => {
            state.loading = true
        },
        PERFORMANCE_TYPE_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.main_data = action.payload.data
        },
        ADD_PERFORMANCE_TYPE_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.main_data = [action.payload, ...state.main_data]
        },
        EDIT_PERFORMANCE_TYPE_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            const newArray = state.main_data.filter(todo => todo.id !== action.payload.id)
            state.main_data = [...newArray, action.payload]
        },
        DELETE_PERFORMANCE_TYPE: (state, action) => {
            const filterPosts = state.main_data.filter(
                (post) => post.id !== action.payload
            );
            state.loading = false
            state.main_data = filterPosts
        },
        PERFORMANCE_TYPE_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { DELETE_PERFORMANCE_TYPE, EDIT_PERFORMANCE_TYPE_FETCH_SUCCESS, PERFORMANCE_TYPE_FETCH_START, PERFORMANCE_TYPE_FETCH_SUCCESS, PERFORMANCE_TYPE_FETCH_ERROR, ADD_PERFORMANCE_TYPE_FETCH_SUCCESS } = performanceTypeSlice.actions;
export default performanceTypeSlice.reducer;
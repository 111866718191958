

import { createSlice } from '@reduxjs/toolkit';

export const userMenuSlice = createSlice({
    name:'userMenu',
    initialState:{
        loading: false,
        data: {

        },
        main_data:[],
        menu_data:[],
        error: false,
    },
    reducers:{
        USER_MENU_FETCH_START:(state)=>{
            state.loading=true
        },
        USER_MENU_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
            state.main_data=action.payload
            const newMenus = state.main_data.map((row) => row.path);
            state.menu_data=newMenus
        },
        ADD_USER_MENU_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.main_data = [action.payload,...state.main_data]
        },
        EDIT_USER_MENU_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            const newArray = state.main_data.filter(todo => todo.id !==action.payload.id)
             state.main_data = [...newArray,action.payload]
        },
        DELETE_USER_MENU:(state,action)=>{
            const filterPosts = state.main_data.filter(
                (post) => post.id !== action.payload
              );
            state.loading = false
            state.main_data=filterPosts
        },
        USER_MENU_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const {EDIT_USER_MENU_FETCH_SUCCESS,DELETE_USER_MENU, USER_MENU_FETCH_START,USER_MENU_FETCH_SUCCESS,USER_MENU_FETCH_ERROR,ADD_USER_MENU_FETCH_SUCCESS }=userMenuSlice.actions;
export default userMenuSlice.reducer;
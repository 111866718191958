export default function LeaveCard(props) {
    return (
        <>

            <div className="d-flex justify-content-between align-items-center border-bottom">
                <div className="holiday-img">
                    <img className="rounded-circle header-profile-user" src={props.img} alt={props.name} />
                </div>
                <div className="holiday-text text-left">
                    <h6>{props.name}</h6>
                    <p>{props.date}</p>
                </div>
                <div className="holiday-status text-center me-1">
                    {/* <span><i className={props.icon}></i></span> */}
                    <span className={props.badgeColor}>{props.status}</span>
                </div>
            </div>


        </>
    )
}


import { createSlice } from '@reduxjs/toolkit';

export const salarySheetSlice = createSlice({
    name:'SALARY_SHEET',
    initialState:{
        loading: false,
        data: {

        },
        main_data:[],
        error: false,
    },
    reducers:{
        SALARY_SHEET_FETCH_START:(state)=>{
            state.loading=true
        },
        SALARY_SHEET_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
            state.main_data=action.payload.data
        },
        ADD_SALARY_SHEET_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.main_data = [action.payload,...state.main_data]
        },
        EDIT_SALARY_SHEET_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            const newArray = state.main_data.filter(todo => todo.id !==action.payload.id)
             state.main_data = [...newArray,action.payload]
        },
        DELETE_SALARY_SHEET:(state,action)=>{
            const filterPosts = state.main_data.filter(
                (post) => post.id !== action.payload
              );
            state.loading = false
            state.main_data=filterPosts
        },
        SALARY_SHEET_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const {EDIT_SALARY_SHEET_FETCH_SUCCESS,DELETE_SALARY_SHEET, SALARY_SHEET_FETCH_START,SALARY_SHEET_FETCH_SUCCESS,SALARY_SHEET_FETCH_ERROR,ADD_SALARY_SHEET_FETCH_SUCCESS }=salarySheetSlice.actions;
export default salarySheetSlice.reducer;
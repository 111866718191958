import { useEffect, useState } from "react";
import API from "../../Network/API";
import { useSelector } from "react-redux";
import HomeGrid from "../../Props/HomeGrid";
import LeaveCard from "../../Props/LeaveCard";

export default function UserHome(){
    const [currentTimeZone, setCurrentTimeZone] = useState('UTC+3');
    const { user, accessToken } = useSelector((state) => state.authReducer);
    const [data, setData] = useState()
    const [isLoading, setLoading] = useState(false);
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const formattedDateTime = currentDateTime.toLocaleString('en-US', {
        timeZoneString: currentTimeZone, // Replace 'UTC' with the desired timezone, e.g., 'UTC+3'
        // Add more options here to customize the format further if needed
      });

      const getTimeZone = () => {
        const options = { timeZoneName: 'short' };
        const currentDateTime = new Date();
        const timeZoneString = new Intl.DateTimeFormat(undefined, options).formatToParts(currentDateTime).find(part => part.type === 'timeZoneName');
    
        return timeZoneString?.value || 'Unknown';
      };

    useEffect(() => {
        setCurrentTimeZone(getTimeZone());
        setLoading(true)
        // API.get('/v1/oauth/dashboard').then((response) => {
        //     setData(response.data.data)
        //     setLoading(false)
        // }).catch((error) => {
        //     setLoading(false)
        // });

         // Update the date and time every second
    const interval = setInterval(() => {
        setCurrentDateTime(new Date());
      }, 1000);
  
      return () => {
        // Clean up the interval when the component is unmounted
        clearInterval(interval);
      };
    }, []);

    const selector = useSelector((state) => state.myProfileSlice)
    return (
        <>
         <div className="container-fluid">
    
        <div className="row g-3 mt-2 mb-1">
                <div className="col-md-12 text-header mb-0 ms-1">
                    <h6 className="mb-0">Welcome,{selector?.data?.user?.name}</h6>
                    <p className="mb-0">{formattedDateTime} {currentTimeZone}</p>
                </div>
                <div className="col-md-8">
                    <div className="row g-3 ">
                        <HomeGrid title={`Total Days`} value={selector?.data?.totalDays} route={'#'} color={`bg-dark text-white`} icon={`fa-solid fa-calendar`} class={`shadow-dark`} valueColor={'text-dark'} />
                        <HomeGrid title={`Total Present`} value={selector?.data?.attendance_count} route={'#'} color={`bg-success`} icon={`fa-sharp fa-regular fa-calendar-clock text-white`} class={`shadow-green`} valueColor={'text-success'} />
                        <HomeGrid title={`Total Absent`} value={selector?.data?.totalAbsent} route={'#'} color={`bg-danger text-white`} icon={`fa-solid fa-alarm-exclamation`} class={`shadow-red`} />
                        <HomeGrid title={`Total Late`} value={selector?.data?.late_count} route={'#'} color={`bg-warning text-white`} icon={`fa-regular fa-alarm-clock`} class={`shadow-orange`} valueColor={'text-warning'} />
                        <HomeGrid title={`Total Leave`} value={selector?.data?.leave_count} route={'#'} color={`bg-info text-white`} icon={`fa-light fa-head-side-virus`} class={`shadow-info`} valueColor={'text-info'} />
                        <HomeGrid title={`Total Overtime`} value={selector?.data?.total_overtime} route={'#'} color={`bg-dark text-white`} icon={`fa-regular fa-clock`} class={`shadow-dark`} valueColor={'text-dark'} />
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <div className="card ms-1 shadow-sm border-0 bg-cc">
                                <div class="card-header border-bottom card-title bg-white"><i class="fa-light fa-circle-info me-1" /> Events
                                </div>
                                <div className="card-bod" style={{ minHeight: '200px' }}>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">

                   

                    <div className="card ms-1 mt-2 shadow-sm border-0 bg-cc">
                        <div class="card-header  border-bottom card-title bg-white"><i class="fa-thin fa-cake-candles me-1" /> Upcomming Birthday
                        </div>
                        <div className="card-bod" style={{ minHeight: '200px' }}>
                             {selector?.data?.birthday_users?.length > 0 && selector?.data?.birthday_users?.map((row, index) => (
                                <LeaveCard img={row.image} name={row.name} date={`${row.dob_txt}`} status={`Today`} icon={`fa-thin fa-plane d-block`} badgeColor={`badge bg-info`} />
                           
                           ))}
                       </div>
                    </div>
                </div>

            </div>
            </div>
        </>
    );
}
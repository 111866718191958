import { useEffect, useState } from "react";
import API from "../../Network/API";
import HomeGrid from "../../Props/HomeGrid";
import LeaveCard from "../../Props/LeaveCard";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PostLoader from '../../assets/loader.gif';
import Agent from "../../Network/Agent";
export default function AdminHome() {
    const { user, accessToken } = useSelector((state) => state.authReducer);
    const [data, setData] = useState()
    const [setting_data, setSettData] = useState()
    const [isLoading, setLoading] = useState(false);
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const formattedDateTime = currentDateTime.toLocaleString('en-US', {
        timeZone: 'Asia/Dhaka', // Replace 'UTC' with the desired timezone, e.g., 'UTC+3'
        // Add more options here to customize the format further if needed
    });
    const dispatch = useDispatch();
    useEffect(() => {
        
        setLoading(true)
        API.get('/v1/oauth/admin_home').then((response) => {
            setData(response.data.data)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
        });

        API.get('/v1/oauth/check_settings').then((response) => {
            setSettData(response.data.data)
        }).catch((error) => {

        });

        // Update the date and time every second
        const interval = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);

        return () => {
            // Clean up the interval when the component is unmounted
            clearInterval(interval);
        };
    }, []);

    return (
        <>
        {isLoading? 
           <div className='content' style={{ margin: 'auto', padding: 'auto', textAlign: 'center' }}>
           <img src={PostLoader} alt='Post loader' height={80} width={80} />
       </div>
       :
            <div className="container-fluid">
                {setting_data?.branch?.lat==0 || setting_data?.branch?.long==0 || setting_data?.branch?.area==0 ?
                <div class="alert alert-warning mt-2" role="alert">
                Update branch or office location (latitude and longitude) for GPS Attendance. <Link to={`/settings/branches`}>Click Here</Link>
                </div>
                :''}

{setting_data?.company?.timezone=="" || setting_data?.company?.fiscal_year=="" || setting_data?.company?.currency_code=="" ?
                <div class="alert alert-info mt-2" role="alert">
                Please update Currency,fiscal year and Timezone information.<Link to={`/settings/web`}>Click Here</Link>
                </div>
                :''}
                <div className="row g-3 mt-2 mb-1">
                    <div className="col-md-12 text-header mb-0 ms-1">
                        <h6 className="mb-0">Welcome,{user.name}</h6>
                        <p className="mb-0">{formattedDateTime}</p>
                    </div>
                    <div className="col-md-8">
                        <div className="row g-3 ">
                            <HomeGrid title={`Total Team`} value={data?.total_users} route={'#'} color={`bg-dark text-white`} icon={`fa-solid fa-users`} class={`shadow-dark`} valueColor={'text-dark'} />
                            <HomeGrid title={`Total Present`} value={data?.total_present} route={'#'} color={`bg-success`} icon={`fa-sharp fa-regular fa-calendar-clock text-white`} class={`shadow-green`} valueColor={'text-success'} />
                            <HomeGrid title={`Total Absent`} value={data?.total_absent} route={'#'} color={`bg-danger text-white`} icon={`fa-solid fa-alarm-exclamation`} class={`shadow-red`} />
                            <HomeGrid title={`Total Late`} value={data?.total_late} route={'#'} color={`bg-warning text-white`} icon={`fa-regular fa-alarm-clock`} class={`shadow-orange`} valueColor={'text-warning'} />
                            <HomeGrid title={`Total Leave`} value={data?.total_leave} route={'#'} color={`bg-info text-white`} icon={`fa-light fa-head-side-virus`} class={`shadow-info`} valueColor={'text-info'} />
                            <HomeGrid title={`Total Overtime`} value={data?.total_overtime} route={'#'} color={`bg-dark text-white`} icon={`fa-regular fa-clock`} class={`shadow-dark`} valueColor={'text-dark'} />
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-12">
                                <div className="card ms-1 shadow-sm border-0 bg-cc">
                                    <div class="card-header border-bottom card-title bg-white"><i class="fa-light fa-circle-info me-1" /> Events
                                    </div>
                                    <div className="card-bod" style={{ minHeight: '200px' }}>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">

                        <div className="card ms-1 shadow-sm border-0 bg-cc">
                            <div class="card-header  border-bottom card-title bg-white"><i class="fa-thin fa-plane me-1" /> Employee on Holiday (Today)
                            </div>
                            <div className="card-bod" style={{ minHeight: '200px' }}>
                                {data?.leaves?.length > 0 && data?.leaves?.map((row, index) => (
                                    <LeaveCard img={row.user_image} name={row.user_name} date={`${row.start_date_text}`} status={`${row.leave_category}`} icon={`fa-thin fa-plane d-block`} badgeColor={`badge bg-info`} />
                                ))}
                            </div>
                        </div>

                        <div className="card ms-1 mt-2 shadow-sm border-0 bg-cc">
                            <div class="card-header  border-bottom card-title bg-white"><i class="fa-thin fa-cake-candles me-1" /> Upcomming Birthday
                            </div>
                            <div className="card-bod" style={{ minHeight: '200px' }}>
                                {data?.birthday_users?.length > 0 && data?.birthday_users?.map((row, index) => (
                                    <LeaveCard img={row.image} name={row.name} date={`${row.dob_txt}`} status={`Today`} icon={`fa-thin fa-plane d-block`} badgeColor={`badge bg-info`} />

                                ))}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
}
        </>
    );
}
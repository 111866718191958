import { useEffect, useState } from "react";
import API from "../../../Network/API";
import Paginate from "../../../services/Paginate";
import { Link } from "react-router-dom";
import AddPayment from "./AddPayment";
import { useDispatch, useSelector } from "react-redux";
import { SALARY_SHEET_FETCH_START, SALARY_SHEET_FETCH_SUCCESS } from "../../../Reducer/SalarySheetSlice";
import Payslip from "./Payslip";

export default function SalarySheet() {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [nxtPage, setNextPage] = useState(null);
    const [search_value, setSearchValue] = useState(null);
    const [data_value, setDataValue] = useState(null);
    const [show, setShow] = useState(false);
    const [show_payslip, setShowPayslip] = useState(false);
    const year = (new Date()).getFullYear();
    const yearLast = 2022;
    const years = Array.from(new Array(20), (val, index) => index + yearLast);
    const months = [
        {
            'id': '01',
            'name': 'January',
        },
        {
            'id': '02',
            'name': 'February',
        },
        {
            'id': '03',
            'name': 'March',
        },
        {
            'id': '04',
            'name': 'April',
        },
        {
            'id': '04',
            'name': 'May',
        },
        {
            'id': '06',
            'name': 'June',
        },
        {
            'id': '07',
            'name': 'July',
        },
        {
            'id': '08',
            'name': 'August',
        },
        {
            'id': '09',
            'name': 'September',
        },
        {
            'id': '10',
            'name': 'October',
        },
        {
            'id': '11',
            'name': 'November',
        },
        {
            'id': '12',
            'name': 'December',
        }

    ];
    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
    const [data, setData] = useState(null);

    const handleShow = (row) => {
        setShow(!show)
        setData(row)
    };

    const handleShowPayslip = (row) => {
        setShowPayslip(!show_payslip)
        setData(row)
    };

    useEffect(() => {
        all_data()
    }, []);

    const all_data = () => {
        dispatch(SALARY_SHEET_FETCH_START())
        API.get('/v1/oauth/manage_salary').then((response) => {
            setDataValue(response.data.data)
            dispatch(SALARY_SHEET_FETCH_SUCCESS(response.data.data))
            setLoading(false)
        }).catch((error) => {
            setLoading(true)

        });
    }
    const paginate = (pageNumber) => {
        API.get('/v1/oauth/manage_salary?page=' + pageNumber).then((response) => {
            setDataValue(response.data.data)
            dispatch(SALARY_SHEET_FETCH_SUCCESS(response.data.data))
            setLoading(false)
        }).catch((error) => {
            setLoading(true)

        });
    };
    const nextPage = (nxtPage) => {
        pageNext(nxtPage)
    }
    const previousPage = (nxtPage) => {
        pageNext(nxtPage)
    }

    const pageNext = (nxtPage) => {
        API.get(nxtPage).then((response) => {
            setDataValue(response.data.data)
            dispatch(SALARY_SHEET_FETCH_SUCCESS(response.data.data))
            setLoading(false)
        }).catch((error) => {
            setLoading(true)

        });
    }

    const selector = useSelector((state) => state.salarySheetReducer)

    return (
        <>
            <div className="container-fluid">
                <div className="page-title-box">
                    <div className="page-title-breadcrumb">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item"><a href="#">Payroll</a></li>
                            <li className="breadcrumb-item active">Payment Info</li>
                        </ol>
                    </div>
                </div>

                <div className="row align-items-center py-2">
                    <div className="col-sm">
                        <div className="d-flex align-items-center">
                            <h5 className="card-title mb-0 me-3">Payment Info</h5>

                        </div>
                    </div>
                    <div className="col-sm-auto">
                        <div class="input-group mb-2">
                            <select name="year" required className="form-select">
                                {years?.map((value, index) => (
                                    <option key={`year${index}`} value={value} selected={year == value ? true : false}>{value}</option>
                                ))}
                            </select>
                            <select required name="month_id" className="form-select">
                                {months.map((month, index) => (
                                    <option key={index} value={month.id} selected={currentMonth == month.name ? true : false}>{month.name}</option>
                                ))}
                            </select>
                            <select required name="is_paid" className="form-select">
                                <option value={``}> select status</option>
                                <option value={1}>Paid</option>
                                <option value={0}>Unpaid</option>
                            </select>
                            <button type="button" className="btn btn-outline-primary">
                                <i className="fa-light fa-search" />
                            </button>
                            <Link to={`/salary_sheet/bulk`} className="btn add-top-btn btn-info text-white">
                                <i className="fa-light fa-plus me-1" />Add Bulk Salary Sheet</Link>
                        </div>
                        <div className="d-flex gap-1 flex-wrap">
                            {/* <button type="button" className="btn bg-white shadow-sm">
                                <i className="fa-light fa-download" />
                            </button> */}


                            {/* <Link to={`/salary_sheet/create`} className="btn add-top-btn btn-success text-white">
                                <i className="fa-light fa-plus me-1" />     Add Salary Sheet</Link> */}

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12">
                        <div className="card border-0 shadow-sm">
                            <div className="table-responsive table-card">
                                <table className="table  table-hover table-centered align-middle table-nowrap mb-0">
                                    <thead className="text-muted table-main-bg">
                                        <tr>
                                            <th scope="col">Month</th>
                                            <th scope="col">Employee</th>
                                            <th scope="col">Basic Salary</th>
                                            <th scope="col">Housing</th>
                                            <th scope="col">Transportation</th>
                                            <th scope="col">Net Salary</th>
                                            <th scope="col">Addtion</th>
                                            <th scope="col">Deduction</th>
                                            <th scope="col">Net Payable</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {!selector?.loading && selector?.main_data?.length > 0 && selector?.main_data?.map((row, index) => (
                                            <tr key={index}>
                                                <td>{row.month_name}-{row.year}</td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <div className="flex-shrink-0 me-2">
                                                            <img src={row.image} alt="" className="avatar-xs rounded-circle" />
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            {row.name}
                                                            <br />
                                                            <span className="text-muted" style={{ fontSize: '11px' }}>{row.employee_id}</span>
                                                        </div>

                                                    </div>
                                                </td>
                                                <td>{row.basic_salary}</td>
                                                <td>{row.transportation}</td>
                                                <td>{row.house}</td>
                                                <td>{row.gross_salary}</td>
                                                <td>{row.total_addition}</td>
                                                <td>{row.total_deduction}</td>
                                                <td>{row.net_payable}</td>
                                                <td>{row.is_paid == 1 ? <span className="badge bg-success">Paid</span> : <span className="badge bg-warning">Unpaid</span>}</td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn btn-light btn-sm dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i className="fa-light fa-ellipsis" />
                                                        </button>
                                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow-sm fs-14" style={{}}>
                                                            {row.is_paid == 1 ?

                                                                <li>
                                                                    <a className="dropdown-item remove-list" href="#" onClick={()=>handleShowPayslip(row)}>
                                                                        <i className="fa-light fa-grid me-2 text-muted" /> Generate Payslip
                                                                    </a>
                                                                </li>

                                                                :
                                                                <li>
                                                                    <a className="dropdown-item remove-list" href="#" onClick={() => handleShow(row)}>
                                                                        <i className="fa-light fa-plus-circle me-2 text-muted" /> Make Payment
                                                                    </a>
                                                                </li>
                                                            }


                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>

                                <Paginate nextPage={nextPage} previousPage={previousPage} previousPageUrl={data_value?.prv_page_url} nextPageUrl={data_value?.next_page_url} postsPerPage={data_value?.per_page} paginate={paginate} totalPosts={data_value?.total_page} hasMorePage={data_value?.has_more_page} onFirstPage={data_value?.first_page} hasPage={data_value?.has_page} currentPage={data_value?.current_page} />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AddPayment show={show} onHide={handleShow} row_data={data} />
            <Payslip show={show_payslip} onHide={handleShowPayslip} row_data={data} />
        </>
    )
}
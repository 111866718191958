import { EMPLOYEE_FETCH_ERROR, EMPLOYEE_FETCH_SUCCESS } from "../Reducer/EmployeeSlice";
import { Notify_FETCH_ERROR, Notify_FETCH_SUCCESS } from "../Reducer/NotifySlice";
import API from "./API";
const limit = (count) => `page=${count}`;

const Notification = {
    all: (page, dispatch) =>
        API.get(`/v1/oauth/notifications?${limit(20, page)}`).then(response => {
            dispatch(Notify_FETCH_SUCCESS(response.data.data));
        }).catch((error) => {
            dispatch(Notify_FETCH_ERROR());
        }),

    latest: (dispatch) =>
        API.get(`/v1/oauth/notifications`).then(response => {
            dispatch(Notify_FETCH_SUCCESS(response.data.data));
        }).catch((error) => {
            dispatch(Notify_FETCH_ERROR());
        }),

    readAll: () =>
        API.post('/v1/oauth/notification-read-all'),
}

const EmployeeAgent={
    all:(dispatch)=>   
    API.get('/v1/oauth/users').then((response) => {
        dispatch(EMPLOYEE_FETCH_SUCCESS(response.data.data))
      }).catch((error) => {
        EMPLOYEE_FETCH_ERROR();
      })
}

const AdminDashboard={
    checkSetting:()=>{
        
    }
}


export default {
    Notification,EmployeeAgent
};
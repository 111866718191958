import Modal from 'react-bootstrap/Modal';
import { useEffect, useRef, useState } from "react";
import API from '../../../../Network/API';

export default function BranchSettingModel({ show, onHide, row_data }) {
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState(false);
    const modalRef1 = useRef()
    useEffect(() => {
        API.get('/v1/oauth/branch/' + row_data + '/edit').then((response) => {
            setLoading(false)
            setData(response.data.data)
        }).catch((error) => {
            setLoading(true)
        });
    }, [row_data]);
    return (
        <>
            <Modal show={show} onHide={onHide} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
                <Modal.Header>
                    <h4 class="modal-title">Settings</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" ref={modalRef1} onClick={onHide}></button>
                </Modal.Header>

                <Modal.Body>
                    <form id="add_country_form">
                        <div className="row">
                            <div class="form-group">
                                <b>Allowed time tracking</b>
                                <p>We offer many different ways to time track.Enable or disable for your business here.</p>
                                <div class="checkbox">
                                    <label for="click" class="text">Weekday</label>
                                    
                                </div>
                                <p>(Please setup your office weekday)</p>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-success">Save {isLoading ? (
                                <span
                                    className="spinner-border spinner-border-sm ml-5"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            ) : (
                                <span></span>
                            )}</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}
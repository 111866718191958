
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import API from "../../../Network/API";

export default function LoanView() {
    const com_selector = useSelector((state) => state.companyReducer)
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState();
    const dispatch = useDispatch();
    const prams = useParams();
    useEffect(() => {
        API.get(`/v1/oauth/loan_requests/view/${prams.id}`).then((response) => {
            setData(response.data.data)
        }).catch((error) => {
            setLoading(true)
        });
    }, [prams.id]);
    return (
        <>
            <div className="page-title-box">
                <div className="page-title-breadcrumb">
                    <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                        <li className="breadcrumb-item"><a href="#">My Requests</a></li>
                        <li className="breadcrumb-item active">Loan Request View</li>
                    </ol>
                </div>
            </div>
            <div className="col-md-10 offset-md-1">
                <div className="card border-0 main-content-card mt-2">
                    <div className="card-header">
                        <div className="align-items-center d-flex main-content-card-header">
                            <h4 className="main-card-title mb-0 flex-grow-1">
                                <div className="d-flex align-items-center">
                                    <Link to={`/loan_request`} className="text-dark">
                                        <i className="fa-duotone fa-arrow-left me-1" />
                                        Loan Request
                                    </Link>
                                </div>
                            </h4>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3 main-content-card-header">
                            <h5 className="main-card-title mb-0 flex-grow-1"><i className="fa-light fa-stethoscope me-1 text-success" style={{ fontSize: '20px' }} />{data?.loan_type_name}</h5>
                            <div className="flex-shrink-0">
                            <div className="d-flex justify-align-center">
                                    <div className="text-month">{com_selector?.data?.currency_code} {data?.amount_txt}</div>
                            </div>
                        </div>
                        </div>
                        <div className="row">
                            <div className="col-md-7">{data?.message}</div>
                            <div className="col-md-5">
                                <div className="row g-1 mt-1">
                                    <div className="col-6">
                                        <div className="card border-0 bg-custom scard">
                                            <p className="p-0 m-0 s-top">{data?.duration} Months </p>
                                            <p className="p-0 m-0 s-down">Loan Duration</p>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="card border-0 bg-custom scard">
                                            <p className="p-0 m-0 s-top">{com_selector?.data?.currency_code} {data?.monthly_installment_txt} </p>
                                            <p className="p-0 m-0 s-down">Monthly Instalment</p>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                
                                    <>
                                        <p className="p-0 m-0">Payment Month: {data?.month}</p> 
                                    
                                        <p className="p-0 m-0">Total Instalment Paid : {data?.total_installment_paid}</p> 
                                        <p className="p-0 m-0">Status: {data?.status==1?'Approved':data?.status==2?'Rejected':'Pending'}</p> 
                                    </>
                                

                                
                            </div>
                        </div>
                        <div className="border-top mt-2">
                            <div className="row align-items-end mb-3">
                                <div className="col-sm-12 mt-2">
                                    <div className="d-flex align-items-start">
                                        <div className="flex-shrink-0 form-icon-avatar"><span className="form-icon-title rounded-circle"><i className="fa-duotone fa-users text-secondary" /></span></div>
                                        <div className="flex-grow-1 ms-3">
                                        <table className="table">
                                            {data?.total_approval.length>0 && data?.total_approval?.map((row, index) => (
                                                <tr>
                                                    <td className="txt-12">
                                                        {row.status == 1 && row.allow_next == 1 ?
                                                            <>
                                                                <i className="fa-light fa-check text-sussess"></i><span className="txt-light">Approved by</span> <b>{row?.boss_name}</b>

                                                            </>
                                                            : row.status == 0 && row.allow_next == 1 ?
                                                                <>
                                                                    <i className="fa-light fa-clock text-warning"></i><span className="txt-light">Wating for approval of</span> <b>{row?.boss_name}</b>

                                                                </>
                                                                : row.status == 0 && row.allow_next == 0 ?
                                                                    <>
                                                                        <i className="fa-light fa-circle txt-light"></i><span className="txt-light">Wating for approval of</span> <b>{row?.boss_name}</b>

                                                                    </>
                                                                    : ''}</td>
                                                    <td className="txt-light txt-12">{data?.created_time}</td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td className="txt-12"><i className="fa-light fa-flag"></i> <b>{data?.user_name}</b> <span className="txt-light">created the request</span></td>
                                                <td className="txt-light txt-12">{data?.created_time}</td>
                                            </tr>
                                        </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
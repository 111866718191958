import { useEffect, useRef, useState } from "react";
import toast from 'react-hot-toast';
import { useDispatch } from "react-redux";
import API from "../../../Network/API";
import { useNavigate } from "react-router-dom";

export default function AddManageBonus() {
    const [name, setName] = useState("");
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const inpRef = useRef()
    const modalRef1 = useRef()
    const year = (new Date()).getFullYear();
    const yearLast = 2022;
    const years = Array.from(new Array(20), (val, index) => index + yearLast);
    const navigator=useNavigate()
    const months = [
        {
            'id': '01',
            'name': 'January',
        },
        {
            'id': '02',
            'name': 'February',
        },
        {
            'id': '03',
            'name': 'March',
        },
        {
            'id': '04',
            'name': 'April',
        },
        {
            'id': '04',
            'name': 'May',
        },
        {
            'id': '06',
            'name': 'June',
        },
        {
            'id': '07',
            'name': 'July',
        },
        {
            'id': '08',
            'name': 'August',
        },
        {
            'id': '09',
            'name': 'September',
        },
        {
            'id': '10',
            'name': 'October',
        },
        {
            'id': '11',
            'name': 'November',
        },
        {
            'id': '12',
            'name': 'December',
        }

    ];
    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
    const [month_id, setMonthId] = useState(currentMonth)
    const [year_id, setYear] = useState(year)
    const [users, setUser] = useState([]);
    const [types, setType] = useState([]);
    const [category, setCategory] = useState();
    const [type_id, setTypeId] = useState([]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if(event.target.type_id.value==''){
            toast.error('Please select bonus type')
            return;
        }
        if(event.target.month_id.value==''){
            toast.error('Please select month')
            return;
        }
        if(event.target.year.value==''){
            toast.error('Please select year')
            return;
        }
        var data = {
          
            'bonus_type_id': event.target.type_id.value,
            'year': event.target.year.value,
            'month_id': event.target.month_id.value,
            'remark': event.target.remark.value,
        }
        const responsea = API.post('/v1/oauth/manage_bonus/save', data);
        toast.promise(responsea, {
            loading: 'Saving ...',
            success: (response) => {
                console.log(response.status)
                if (response.status === 500){
                    return `Server Error`;
                }else if (response.status === 200){
                    navigator('/manage_bonus')
                    return `${response.data.message}`;
                };
                
            },
            error: (e) => `${e.response.data.errors.name ?? e.response.data.message}`,
            duration: 6000,
        })

    }

    useEffect(() => {
        API.get(`/v1/oauth/manage_bonus/create`).then((response) => {
            setType(response.data.data.types)
        }).catch((error) => {

        });
    }, []);

    const filterManageBonus = () => {
        setLoading(true);
        if (type_id == undefined) {
            toast.error('Please select bonus type')
            return;
        }
        API.get(`/v1/oauth/manage_bonus/filter?type_id=` + type_id).then((response) => {
            setUser(response.data.data.users)
            setCategory(response.data.data.type)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
        });
    }

    return (
        <>
            <div className="container-fluid">
                <div className="page-title-box">
                    <div className="page-title-breadcrumb">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item"><a href="#">Payroll</a></li>
                            <li className="breadcrumb-item">Manage Bonus</li>
                            <li className="breadcrumb-item active">Add Bonus</li>
                        </ol>
                    </div>
                </div>
                <form onSubmit={handleSubmit} id="add_country_form">
                    <div className="row">
                        <div className="col-xl-12 mb-2">
                            <div className="card border-0 shadow-sm">
                                <div className="card-body">

                                    <div className="row">

                                        <div className="col-sm-4 mb-3">
                                            <label htmlFor="type_id" className="form-label c-label">Bonus Category<em className="text-danger">*</em></label>
                                            <select id="type_id" onChange={(e) => setTypeId(e.target.value)} name="type_id" className="form-select">
                                                <option value={``}>Choose.........</option>
                                                {types.map((type, index) => (
                                                    <option key={index} value={type.id}>{type.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-sm-4 mb-3">
                                            <label htmlFor="value" className="form-label c-label">Year<em className="text-danger">*</em></label>
                                            <select name="year" required className="form-select">
                                                {years?.map((value, index) => (
                                                    <option key={`year${index}`} value={value} selected={year == value ? true : false}>{value}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-sm-4 mb-3">
                                            <label htmlFor="value" className="form-label c-label">Month<em className="text-danger">*</em></label>
                                            <select required name="month_id" className="form-select">
                                                {months.map((month, index) => (
                                                    <option key={index} value={month.id} selected={currentMonth == month.name ? true : false}>{month.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" onClick={() => filterManageBonus()} class="btn btn-info me-1"><i className="fa-light fa-search me-2"></i>Filter {isLoading ? (
                                            <span
                                                className="spinner-border spinner-border-sm ml-5"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        ) : (
                                            <span></span>
                                        )}</button>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className="card border-0 shadow-sm">
                                <div className="table-responsive card-body">
                                    <table className="table  table-hover table-centered align-middle table-nowrap mb-0">
                                        <thead className="text-muted table-main-bg">
                                            <tr>
                                                <th scope="col">SL</th>
                                                <th scope="col">Employee ID</th>
                                                <th scope="col">Employee Name</th>
                                                <th scope="col">Designation</th>
                                                
                                                <th scope="col">Basic Salary(SAR)</th>
                                                <th scope="col">Percent(%)</th>
                                                <th scope="col">Total Bonus</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users.length > 0 && users.map((row, index) => (


                                                <tr key={index}>
                                                    <td>{index}</td>
                                                    <td>{row.employee_id}</td>
                                                    <td>{row.name}</td>
                                                    <td>{row.designation_name}</td>
                                                    <td>{(row.basic_salary).toFixed(2)}</td>
                                                    <td>{category.percent}</td>
                                                    <td>{((Number(row.basic_salary)*Number(category.percent))/100).toFixed(2)}</td>
                                                </tr>

                                            ))}
                                        </tbody>
                                    </table>
                                    <hr />
                                    <div className="row align-items-end mb-3">
                                        <div className="col-sm-12">
                                            <div className="d-flex align-items-start">
                                                <div className="flex-shrink-0 form-icon-avatar">
                                                    <span className="form-icon-title rounded-circle">
                                                        <i className="fa-duotone fa-message-captions text-secondary" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <textarea name="remark" className="form-control rounded-0" id="exampleFormControlTextarea1" rows={3} placeholder="Write a message" defaultValue={""} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2 ms-2 mb-2">
                                        <button type="submit" class="btn btn-success">Save Bonus {isLoading ? (
                                            <span
                                                className="spinner-border spinner-border-sm ml-5"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        ) : (
                                            <span></span>
                                        )}</button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            </div>


        </>
    )
}
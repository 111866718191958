import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { toast } from "react-hot-toast";
import API from "../../../Network/API";

export default function RequestView() {
    const [isLoading, setLoading] = useState(false);
    const [loading, setLoad] = useState(false);
    const [data, setData] = useState();
    const [message, setMessage] = useState();
    const dispatch = useDispatch();
    const prams = useParams();
    const selector = useSelector((state) => state.companyReducer)
    useEffect(() => {
        API.get(`/v1/oauth/events/${prams.id}/view`).then((response) => {
            setData(response.data.data)
        }).catch((error) => {
            setLoading(true)
        });
    }, [prams.id]);

  
    return (
        <>
            <div className="card border-0 main-content-card mt-2">
                <div className="card-header">
                    <div className="align-items-center d-flex main-content-card-header">
                        <h4 className="main-card-title mb-0 flex-grow-1">
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 me-2">
                                    <img src={data?.image} alt="" className="avatar-xs rounded-circle" />
                                </div>
                                <div className="flex-grow-1">
                                    {data?.user_name}
                                </div>

                            </div>
                        </h4>
                        
                    </div>
                </div>
                <div className="card-body">
                    <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3 main-content-card-header">
                        <h5 className="main-card-title mb-0 flex-grow-1">
                            <i style={{ fontSize: '20px' }} class={`fa-light ${data?.event_type == 1 ? 'fa-stethoscope' : data?.event_type == 2 ? 'fa-dollar' : data?.event_type == 8 ? 'fa-clock' : ''} me-1 text-success`}></i>
                            {data?.event_type == 1 ? data?.leaves?.leave_category : data?.title}
                        </h5>
                        <div className="flex-shrink-0">
                            <div className="d-flex justify-align-center">
                                <div className="d-flex">
                                    <div className="text-month">{data?.event_type == 1 || data?.event_type == 8 ? data?.leaves?.s_day :data?.event_type == 2 ?data?.leaves?.amount+` ${selector?.data?.currency_code}`: ''}</div>
                                    <div className="sub-month-text">
                                        <p className="m-0 p-0">{data?.event_type == 1 || data?.event_type == 8 ? data?.leaves?.s_month : ''}</p>
                                        <p className="m-0 p-0">{data?.event_type == 1 || data?.event_type == 8 ? data?.leaves?.s_year : ''}</p>
                                    </div>
                                </div>
                                {data?.event_type == 1 || data?.event_type == 8 ?
                                <div><i className="fa-solid fa-arrow-right me-2 ms-2"></i></div> :''}
                                <div className="d-flex">
                                    <div className="text-month">{data?.event_type == 1 || data?.event_type == 8 ? data?.leaves?.e_day : ''}</div>
                                    <div className="sub-month-text">
                                        <p className="m-0 p-0">{data?.event_type == 1 || data?.event_type == 8 ? data?.leaves?.e_month : ''}</p>
                                        <p className="m-0 p-0">{data?.event_type == 1 || data?.event_type == 8 ? data?.leaves?.e_year : ''}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7">
                            {data?.leaves?.message}
                        </div>
                        <div className="col-md-5">

                            <div className="row g-1 mt-1">
                                <div className="col-6">
                                    <div className="card border-0 bg-custom scard">
                                        <p className="p-0 m-0 s-top">{data?.event_type == 1 || data?.event_type == 8 ? data?.leaves?.selected_day+' Days' :data?.event_type == 2?data?.leaves?.duration+' Months':''} </p>
                                        <p className="p-0 m-0 s-down">{data?.event_type == 1 || data?.event_type == 8 ?'Selected' :data?.event_type == 2?'Loan Duration': ''}</p>
                                    </div>
                                </div>
                                <div className="col-6"><div className="card border-0 bg-custom scard">
                                    <p className="p-0 m-0 s-top">{data?.event_type == 1 || data?.event_type == 8 ? data?.leaves?.total_day+' Days' :data?.event_type == 2?data?.leaves?.monthly_installment+` ${selector?.data?.currency_code}`: ''} </p>
                                    <p className="p-0 m-0 s-down">{data?.event_type == 1 || data?.event_type == 8 ?'Total' :data?.event_type == 2?'Monthly Instalment': ''}</p>
                                </div>
                                </div>
                            </div>
                            <br />
                            {data?.event_type == 1 && data?.leaves?.duration_type == 1 || data?.leaves?.duration_type == 2 ?
                                <>
                                    <p className="p-0 m-0">Duration: {data?.leaves?.duration_type == 1 ? 'Ony Day' : 'Half Day'}</p>
                                    <p className="p-0 m-0">Shift: {data?.leaves?.shift_type == 1 ? 'First Shift' : 'Second Shift'}</p>
                                </>

                                : ''}
                        </div>


                    </div>
                    <div className="border-top mt-2">

                        <div className="row align-items-end mb-3">
                            <div className="col-sm-12 mt-2">
                                <div className="d-flex align-items-start">
                                    <div className="flex-shrink-0 form-icon-avatar">
                                        <span className="form-icon-title rounded-circle">
                                            <i className="fa-duotone fa-users text-secondary" />
                                        </span>
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <table className="table">
                                            {data?.leaves?.total_approval.length>0 && data?.leaves?.total_approval?.map((row, index) => (
                                                <tr>
                                                    <td className="txt-12">
                                                        {row.status == 1 && row.allow_next == 1 ?
                                                            <>
                                                                <i className="fa-light fa-check text-sussess"></i><span className="txt-light">Approved by</span> <b>{row?.boss_name}</b>

                                                            </>
                                                            : row.status == 0 && row.allow_next == 1 ?
                                                                <>
                                                                    <i className="fa-light fa-clock text-warning"></i><span className="txt-light">Wating for approval of</span> <b>{row?.boss_name}</b>

                                                                </>
                                                                : row.status == 0 && row.allow_next == 0 ?
                                                                    <>
                                                                        <i className="fa-light fa-circle txt-light"></i><span className="txt-light">Wating for approval of</span> <b>{row?.boss_name}</b>

                                                                    </>
                                                                    : ''}</td>
                                                    <td className="txt-light txt-12">{data?.created_time}</td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td className="txt-12"><i className="fa-light fa-flag"></i> <b>{data?.user_name}</b> <span className="txt-light">created the request</span></td>
                                                <td className="txt-light txt-12">{data?.created_time}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
import { Link } from "react-router-dom";

export default function ProfileNav(props){
    return (
        <>
        <ul className="list-unstyled profile-manager-menu">
                    <li>
                      <Link to={`/employee/${props.user_id}/view`} className={`${props.sl==1?'active':''}`}>
                        <i className="fa-light fa-user align-bottom me-2" />
                        <span className="file-list-link">Personal</span>
                      </Link>
                    </li>
                    <li>
                      <Link to={`/employee/${props.user_id}/job`} className={`${props.sl==2?'active':''}`}>
                        <i className="fa-light fa-briefcase align-bottom me-2" />
                        <span className="file-list-link">Job</span>
                      </Link>
                    </li>
                 
                    <li>
                      <Link to={`/employee/${props.user_id}/leave`} className={`${props.sl==3?'active':''}`}>
                        <i className="fa-light fa-clock align-bottom me-2" />
                        <span className="file-list-link">Leave</span>
                      </Link>
                    </li>
                    <li>
                      <Link to={`/employee/${props.user_id}/timesheet`} className={`${props.sl==4?'active':''}`}>
                        <i className="fa-light fa-calendar align-bottom me-2" />
                        <span className="file-list-link">Timesheet</span>
                      </Link>
                    </li>
                  </ul>
        </>
    )
}
import { useEffect, useRef, useState } from "react";
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from "react-redux";

import Modal from 'react-bootstrap/Modal';
import { EDIT_WEEKLY_HOLIDAY_FETCH_SUCCESS } from "../../../Reducer/WeeklyHolidaySlice";
import API from "../../../Network/API";

export default function EditWeeklyHoliday({show,onHide,row_data}) {
    const [name, setName] = useState("");
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const inpRef = useRef()
    const modalRef1 = useRef()
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        var data = {
            'name': event.target.name.value,
            '_method':'PUT'
        }
        const responsea = API.post('/v1/oauth/asset_types/'+row_data.id+'/update', data);
        toast.promise(responsea, {
            loading: 'Saving ...',
            success: (response) => {
                setLoading(false);
                modalRef1.current.click();
                document.getElementById("add_country_form").reset()
                dispatch(EDIT_WEEKLY_HOLIDAY_FETCH_SUCCESS(response.data.data))
                if (response.status === 500) throw new Error('server error');
                return `${response.data.message}`;
            },
            error: (e) => `${e.response.data.errors.name ?? e.response.data.message}`,
            duration: 6000,
        })
        setLoading(false)
    }

    return (
        <>
            <Modal show={show} onHide={onHide}>
                <Modal.Header>
                    <h4 class="modal-title">Edit Asset Types</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" ref={modalRef1} onClick={onHide}></button>

                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={handleSubmit} id="add_country_form">
                        <div className="row">
                            <div className="col-sm-12 mb-3">
                                <label htmlFor="name" className="form-label c-label">Weekday Name*</label>
                                <select className="form-select" name="name" required>
                                            <option  value={``}>Choose...</option>
                                            <option selected={row_data?.day_name=='Sun'?true:false} value={`Sun`}>Sunday</option>
                                            <option selected={row_data?.day_name=='Mon'?true:false} value={`Mon`}>Monday</option>
                                            <option selected={row_data?.day_name=='Tue'?true:false} value={`Tue`}>Tuesday</option>
                                            <option selected={row_data?.day_name=='Wed'?true:false} value={`Wed`}>Wednesday</option>
                                            <option selected={row_data?.day_name=='Thu'?true:false} value={`Thu`}>Thursday</option>
                                            <option selected={row_data?.day_name=='Fri'?true:false} value={`Fri`}>Friday</option>
                                            <option selected={row_data?.day_name=='Sat'?true:false} value={`Sat`}>Saturday</option>
                                        </select>
                                        
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="submit" class="btn btn-success">Submit {isLoading ? (
                                <span
                                    className="spinner-border spinner-border-sm ml-5"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            ) : (
                                <span></span>
                            )}</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}
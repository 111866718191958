import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { BRANCH_FETCH_ERROR, BRANCH_FETCH_SUCCESS } from "../../../Reducer/BranchSlice";
import API from "../../../Network/API";
import BranchSettingModel from "./model/BranchSettingModel";


export default function AppSetting() {
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [nxtPage, setNextPage] = useState(null);
    const [data_value, setDataValue] = useState(null);
    const [branch, setBranch] = useState("");
    const [show, setShow] = useState(false);

    const handleShow = () => {
        setShow(!show)
        setDataValue(branch)
    };

    useEffect(() => {
        all_data('')
    }, []);

    const all_data = (name) => {
        API.get('/v1/oauth/branch?name=' + name).then((response) => {
            dispatch(BRANCH_FETCH_SUCCESS(response.data.data))
            setLoading(false)
        }).catch((error) => {
            setLoading(true)
            BRANCH_FETCH_ERROR();
        });
    }

    const paginate = (pageNumber) => {
        API.get('/v1/oauth/branch?page=' + pageNumber).then((response) => {
            dispatch(BRANCH_FETCH_SUCCESS(response.data.data))
            setLoading(false)
        }).catch((error) => {
            setLoading(true)
            BRANCH_FETCH_ERROR();
        });
    };


    const selector = useSelector((state) => state.branchReducer)

    return (
        <>
            <div className="page-title-box">
                <div className="page-title-breadcrumb">
                    <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                        <li className="breadcrumb-item"><a href="#">Settings</a></li>
                        <li className="breadcrumb-item active">Attendance Settings</li>
                    </ol>
                </div>
            </div>

            <div className="card border-0 main-content-card">
                <div className="card-body">
                    <div className="align-items-center d-flex bg-white border-bottom pb-2 mb-3 main-content-card-header">
                        <h4 className="main-card-title mb-0 flex-grow-1">Attendance Settings</h4>
                    </div>

                    <form  >
                        <div className="c-form-area pb-3">
                            <div className="row align-items-end mb-3">
                                <div className="col-sm-12 col-md-12">
                                    <div className="d-flex align-items-end">
                                        <div className="flex-shrink-0 form-icon-avatar">
                                            <span className="form-icon-title rounded-circle">
                                                <i className="fa-solid fa-building text-primary" />
                                            </span>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <label htmlFor="c_name" className="form-label fs-14 text-secondary mb-1 text-uppercase c-label">Branch (Select a branch for setting)</label>
                                            <select className="form-select rounded-pill" required onChange={(e) => setBranch(e.target.value)}>
                                                <option></option>
                                                {!selector?.loading && selector?.main_data?.map((row, index) => (
                                                    <option value={row.id}>{row.name}</option>
                                                ))}
                                            </select>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="text-end mb-4">
                                <button type="button" className="btn btn-success rounded-pill" onClick={()=>handleShow()}>
                                    Next  <i className="fa-light fa-paper-plane-top ms-1" />
                                </button>
                            </div>
                        </div>
                    </form>

                </div>{/* end card-body */}
            </div>

            <BranchSettingModel show={show} onHide={handleShow} row_data={data_value} />

        </>
    )
}